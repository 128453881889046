import { Intent } from '@blueprintjs/core';
import { ButtonSave, Button } from 'components/buttons';
import { FormDateInput, FormLocationSelector, FormHelperText } from 'components/form-fields';
import { AlertUnsavedData, Modal, NotificationInline, NotificationToaster } from 'components/notifications';
import { useDirtyData } from 'hooks/useDirtyData';
import React, { useEffect, useState, Fragment } from 'react';
import { JobService } from 'services';
import { UserAccess } from 'components/user-access/UserAccess';
import { PageRestricted } from 'pages/errors/page-restricted';
import PropTypes from 'prop-types';
import moment from "moment";

export function BulkEditJob(props) {
    const defaultBulkEdit = {
        dueDate: null,
        locationId: null,
        changeDueDate: false,
        changeLocation: false,
        jobIds: props.jobs.map(job => parseInt(job))
    };

    const [bulkEdit, setBulkEdit] = useState(defaultBulkEdit);
    const [originalBulkEdit, setOriginalBulkEdit] = useState(defaultBulkEdit);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [activating, setActivating] = useState(false);
    const isDataDirty = useDirtyData(originalBulkEdit, bulkEdit);
    const requiredAction = ["Internal"];
    const monthsInPastDefault = 240;
    const minDateDefault = moment().subtract(monthsInPastDefault, 'months').toDate();

    const [locationList, setLocationList] = useState({ location: [], hierarchy: [] });
    const [selectedBELocations, setSelectedBELocations] = useState([]);
    const [showLocationsAsHierarachy, setShowLocationsAsHierarachy] = useState(true);

    const [canActivateWarning, setCanActivateWarning] = useState ([]);
    const [response, setResponse] = useState(getResponse( false, false));
    useEffect(initialLoad, [props.locations]);
    useEffect(checkCanActivate, [props.jobs, props.showBulkEditModal]);

    useEffect(onShowBulkEditModal, [props.showBulkEditModal]);

    function onShowBulkEditModal() {
        if (props.showBulkEditModal) {

            //clear any previous results
            setResponse(getResponse(false, false));
        }
    }

    function initialLoad() {
        setLocationList(props.locations);
        setLoading(false);
    }

    function checkCanActivate() {
        setLoading(true);
        setCanActivateWarning([]);

        if (!props.showBulkEditModal || props.jobs.length === 0) {
            setLoading(false);
            return;
        }

        JobService.canActivate(props.jobs.map(job => parseInt(job))).then(r => {
            let data = [];

            if (r != null) {
                data = r.filter(function (obj) {
                    return !obj.canActivate;
                }).map(function (obj) { return { jobId: obj.jobId, reason: obj.cannotActivateReason } });

                if (data.length > 0) {
                    let message = [{ message: "The jobs cannot be activated because: " }];
                    data.forEach(function (obj) {
                        message.push({ message: `Job ${obj.jobId} - ${obj.reason}` });

                    });
                    setCanActivateWarning(message);
                }
                setLoading(false);
            }

        }, function () {
            NotificationToaster.show(Intent.DANGER, "Bulk edit could not be loaded. Please try loading the page again.");
            setLoading(false);
        });

    }
    function onSave() {
        setSaving(true);
        var bulkEditDetails = {
            jobIds: props.jobs.map(job => parseInt(job)),
            dueDate: bulkEdit.dueDate,
            locationId: bulkEdit.locationId
        };

        JobService.saveBulkEdit(bulkEditDetails)
            .then(
                () => {
                    NotificationToaster.show(Intent.SUCCESS, "Jobs updated successfully.");
                    resetForm();
                    setSaving(false);
                    setResponse(getResponse(true, response.isActivated));
                    checkCanActivate();
                },
                () => {
                    NotificationToaster.show(Intent.DANGER, "Could not update jobs. Please try again.");
                    setSaving(false);
                },
        );
    }

    function onActivate() {
        setActivating(true);
        JobService.activateJobs(props.jobs)
            .then(
                () => {
                    NotificationToaster.show(Intent.SUCCESS, "Jobs activated successfully.");
                    resetForm();
                    setActivating(false);
                    setResponse(getResponse(response.isUpdated, true));
                },
                () => {
                    NotificationToaster.show(Intent.DANGER, "Could not activate jobs. Please try again.");
                    setActivating(false);
                },
            );
    }

    function onDueDateChange(item) {
        setBulkEdit((previousValue) => {
            return {
                ...previousValue,
                dueDate: item ? moment(item).format("YYYY-MM-DD") + ' 00:00:00' : null
            }
        });
    }

    function onLocationSelect(locations) {

        var lastLocation = locations.length > 0 ? locations.pop() : null;

        setBulkEdit((previousValue) => {
            return {
                ...previousValue,
                locationId: lastLocation
            }
        });

        setSelectedBELocations(lastLocation == null ? [] : [lastLocation]);
    }

    function getResponse(isUpdated, isActivated) {
        return {
            isUpdated: isUpdated,
            isActivated: isActivated
        }
    }

    function setUseHierarchy() {
        setShowLocationsAsHierarachy(previous => !previous);
    }

    function closeBulkEditModal() {
        resetForm();
        props.closeBulkEditModal(response);
    }

    function resetForm() {
        setBulkEdit(defaultBulkEdit);
        setOriginalBulkEdit(defaultBulkEdit);
        setSelectedBELocations([]);
    }
    return (

        <UserAccess perform={requiredAction}
            yes={() => (
                <Fragment>
                    <Modal
                        isOpen={props.showBulkEditModal}
                        title="Bulk Edit"
                        showCloseButton={false}
                        clickOutsideClose={false}
                        wide={true}
                    >
                        <Fragment>
                            <NotificationInline
                                allowClose={false}
                                show
                                text={"Please note, this will update " + props.jobs.length + " job" + (props.jobs.length === 1 ? "" : "s" + ".")}
                                intent={Intent.WARNING}
                            />
                            <NotificationInline
                                allowClose={false}
                                show={canActivateWarning.length !== 0}
                                messages={canActivateWarning}
                                intent={Intent.WARNING}
                            />
                            <FormHelperText loading={loading}>{`If a due date is set then all the jobs will have the due date changed to this due date when saved.`}</FormHelperText>
                            <FormDateInput
                                id="field-job-due-date"
                                disabled={saving || response.isActivated}
                                headingText="Due date:"
                                onChange={onDueDateChange}
                                value={bulkEdit.dueDate}
                                loading={loading}
                                minDate={minDateDefault}
                            ></FormDateInput>
                            <FormHelperText loading={loading}>{`If a location is set then all the jobs will have the location changed to this location when saved.`}</FormHelperText>
                            <FormLocationSelector
                                headingText={`Visit Location:`}
                                businessArea={"Tacho"}
                                loading={loading}
                                selectedLocations={selectedBELocations}
                                setSelectedLocations={onLocationSelect}
                                useHierarchy={showLocationsAsHierarachy}
                                setUseHierarchy={setUseHierarchy}
                                useLocationDefaults={false}
                                useCustomLocations
                                customLocations={locationList}
                                locationDefaultsToTrue
                                singleSelection
                                disabled={saving || response.isActivated}
                            />
                            <AlertUnsavedData
                                isDirty={isDataDirty}>
                            </AlertUnsavedData>

                        </Fragment>

                        <div className="bp3-dialog-footer">
                            <div className="bp3-dialog-footer-actions">
                                <ButtonSave text={"Save"} icon={null} intent={Intent.DANGER} onClick={onSave} simpleDisabled={!isDataDirty || activating || loading || response.isActivated } disabled={saving}></ButtonSave>
                                <ButtonSave text={"Activate"} icon={null} intent={Intent.DANGER} onClick={onActivate} simpleDisabled={canActivateWarning.length !== 0 || isDataDirty || saving || loading || response.isActivated } disabled={activating}></ButtonSave>
                                <Button text="Close" onClick={() => { closeBulkEditModal(); }} disabled={saving}></Button>
                            </div>
                        </div>
                    </Modal>
                </Fragment>

            )}
            no={() => (
                <PageRestricted />
            )}
        />

    );
}
BulkEditJob.defaultProps = {
    loading: false,
    closeBulkEditModal: null,
    showBulkEditModal: false,
}

BulkEditJob.propTypes = {
    jobs: PropTypes.array,
    locations: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    closeBulkEditModal: PropTypes.func,
    showBulkEditModal: PropTypes.bool.isRequired
};