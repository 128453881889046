import { TransportManagersService } from "services";
import { TransportManagerUpsertModal } from "pages/operating-licences/modals";
import React, { useEffect, useState } from "react";
import { DateToLocal } from "components/dates";
import { Button, Card, Intent } from "@blueprintjs/core";
import { AlertConfirm, NotificationToaster } from "components/notifications";
import { UserHasAccess } from "components/user-access";
import classNames from "classnames";
import { ListingTable } from "components/listing";
import PropTypes from "prop-types";
import 'pages/operating-licences/OperatingLicences.css';
import { ShowHide } from "components/layout";

export function OperatingLicenceTransportManagers(props) {

    const { id } = props;

    const buttonWidth = 50;
    const transportManagerTableHeaders = [
        "EMPLOYEE NAME",
        "DATE CPC OBTAINED",
        "DATE OF LAST CPC REFRESHER"
    ];
    const canManageLicence = UserHasAccess("OperatingLicence:Manage");
    const [loadingTransportManagers, setLoadingTransportManagers] = useState(false);
    const [selectedTransportManagerId, setSelectedTransportManagerId] = useState(0);
    const [transportManagers, setTransportManagers] = useState([]);
    const [totalTransportManagersCount, setTotalTransportManagersCount] = useState(0);
    const [showDeleteTransportManager, setShowDeleteTransportManager] = useState(false);
    const [showAddTransportManager, setShowAddTransportManager] = useState(false);
    const [transportManagerForename, setTransportManagerForename] = useState("");
    const [transportManagerSurname, setTransportManagerSurname] = useState("");
    const [cpcObtainedDate, setCpcObtainedDate] = useState("");
    const [cpcRefresherDate, setCpcRefresherDate] = useState("");
    const [isTransportManagerEdit, setIsTransportManagerEdit] = useState(false);

    useEffect(() => {
        loadTransportManagers();
    }, [id]);

    function loadTransportManagers() {
        let transportManagersSearch = {
            "requestCount": 0,
            "pageNumber": 0,
            "filters": [
                {
                    "key": "OPERATINGLICENCE",
                    "value": id
                }
            ]
        };
        setLoadingTransportManagers(true);
        TransportManagersService.searchTransportManagers(transportManagersSearch).then(function (transportManagersResult) {
            setTransportManagers(transportManagersResult.data);
            setTotalTransportManagersCount(transportManagersResult.totalCount);
        }).finally(() => {
            setLoadingTransportManagers(false);
        });;
    }

    const transportManagerRows = transportManagers.map((x) => {
        var transportManagerRow = [
            <div key={`${x.id}-name`}>{x.firstName} {x.lastName}</div>,
            <DateToLocal key={`${x.id}-cpcObtainedDate`} format="DD/MM/YYYY">{x.cpcObtainedDate}</DateToLocal>,
            <DateToLocal key={`${x.id}-cpcRefresherDate`} format="DD/MM/YYYY">{x.cpcRefresherDate}</DateToLocal>
        ];

        if (canManageLicence) {
            transportManagerRow.push(
                <div key={`${x.id}-edit`} >
                    <Button
                        onClick={() => {
                            setTransportManagerForename(x.firstName);
                            setTransportManagerSurname(x.lastName);
                            setCpcObtainedDate(x.cpcObtainedDate);
                            setCpcRefresherDate(x.cpcRefresherDate);
                            setIsTransportManagerEdit(true);
                            setSelectedTransportManagerId(x.id);
                            setShowAddTransportManager(true);
                        }}
                        icon={"edit"}
                        iconOnly={true}
                        minimal={true}
                    />
                </div>,
                <div key={`${x.id}-delete`}>
                    <Button
                        onClick={() => onDeleteTransportManager(x)}
                        icon="trash"
                        iconOnly={true}
                        minimal={true}
                        className="note-button-delete"
                    />
                </div>
            )
        }
        return transportManagerRow;
    });

    function onDeleteTransportManager(manager) {
        setSelectedTransportManagerId(manager.id);
        setShowDeleteTransportManager(true);
    }

    function onCancelDeleteTransportManager() {
        setShowDeleteTransportManager(false);
        setSelectedTransportManagerId(0);
    }

    function onConfirmDeleteTransportManager() {
        setShowDeleteTransportManager(false);
        TransportManagersService.deleteTransportManager(selectedTransportManagerId).then(function () {
            loadTransportManagers();
            NotificationToaster.show(Intent.SUCCESS, "Transport Manager deleted successfully.");
        }, function () {
            NotificationToaster.show(Intent.DANGER, "Unable to delete the Transport Manager, please try again.");
        }
        )
            .finally(() => {
                setSelectedTransportManagerId(0);
            });
    }

    function handleSaveTransportManager() {
        setShowAddTransportManager(false);
    }


    function getTransportManagerColumnHeaders() {
        if (canManageLicence) {
            transportManagerTableHeaders.push("EDIT", "DELETE")
        }
        return transportManagerTableHeaders;
    }

    function getTransportManagerColumnWidths() {
        let dataColumnwidth = 250;
        let lastColumnWidth = 100;
        return canManageLicence ? [null, null, null, buttonWidth, buttonWidth] : [dataColumnwidth, dataColumnwidth, lastColumnWidth];
    }
    
    
    return (
        <>
            <div className="transport-manager-top-row">
                <h1 className="transport-manager-header">Transport Managers</h1>
                {canManageLicence ?
                    (
                        <div className="add-transport-manager">
                            <ShowHide
                                evaluator={!loadingTransportManagers && transportManagerRows.length === 0}
                                show={
                                    <Card className="add-operating-licence-object-card">
                                        Please select the plus button to add a new Transport Manager
                                    </Card>}
                            />
                            <Button
                                onClick={() => { setShowAddTransportManager(true); setIsTransportManagerEdit(false); }}
                                icon={"plus"}
                                iconOnly={true}
                                minimal={true}
                            ></Button>
                        </div>
                    ) : <div />
                }
            </div>
            <div className={classNames({ 'bp3-skeleton': loadingTransportManagers })}>
                <ListingTable
                    headers={getTransportManagerColumnHeaders()}
                    pageable={false}
                    data={transportManagerRows}
                    loadingData={loadingTransportManagers}
                    totalRecordCount={totalTransportManagersCount}
                    columnWidths={getTransportManagerColumnWidths()}
                />
            </div>
            <TransportManagerUpsertModal
                operatingLicenceId={Number(id)}
                showAddTransportManager={showAddTransportManager}
                setShowAddTransportManager={setShowAddTransportManager}
                transportManagerForename={transportManagerForename}
                transportManagerSurname={transportManagerSurname}
                cpcObtainedDate={cpcObtainedDate}
                cpcRefresherDate={cpcRefresherDate}
                handleSaveTransportManager={handleSaveTransportManager}
                reloadTransportManagers={loadTransportManagers}
                isEdit={isTransportManagerEdit}
                transportManagerId={selectedTransportManagerId}
            />
            <AlertConfirm
                title="Delete Transport Manager"
                isOpen={showDeleteTransportManager}
                onCancel={onCancelDeleteTransportManager}
                onConfirm={onConfirmDeleteTransportManager}
                confirmButtonText="Delete"
            >
                <p>Are you sure you want to delete this transport manager?</p>
            </AlertConfirm>
        </>
        
    );
}

OperatingLicenceTransportManagers.propTypes = {
    id: PropTypes.number.isRequired,
}