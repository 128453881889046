import React, { useEffect, useState } from 'react';
import { Modal, NotificationToaster } from 'components/notifications';
import { ButtonSave, Button } from 'components/buttons';
import { FormSelect } from 'components/form-fields';
import { LocationService } from 'services';
import { Intent } from '@blueprintjs/core';
import PropTypes from 'prop-types';

export function MoveAsset(props) {
    const { profile, isVisible, closeModal, saveAssetLocation, isSaving, assetType } = props;
    const [locations, setLocations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [moveAssetLocationId, setMoveAssetLocationId] = useState({});

    useEffect(() => {
        if (profile && isVisible) {
            setIsLoading(true);
            var businessAreaFilter = {};
            if (assetType.visOnly) {
                businessAreaFilter = { filters: [{ key: "BusinessAreas", value: "VIS" }] };
            }

            LocationService.getLocationList(0, 1, "", "", "", businessAreaFilter)
                .then((response) => {
                    if (response && response.data) {
                        setLocations(response.data.map((location) => {
                            return {
                                id: location.id,
                                name: location.name
                            }
                        }));
                    }
                })
                .catch(() => {
                    NotificationToaster.show(Intent.DANGER, `Failed to load profile data. Please refresh the page.`);
                })
                .finally(() => {
                    setMoveAssetLocationId(profile.assetLocation?.location)
                    setIsLoading(false);
                });
        }

    }, [profile, isVisible])

    function onLocationSelect(value) {
        setMoveAssetLocationId(value)
    }

    function moveLocation() {
        saveAssetLocation(moveAssetLocationId);
        closeModal();
    }

    return (
        <Modal title="Move Asset" isOpen={isVisible} onClose={closeModal}>
            <p>Which location would you like to move the asset to?</p>
            <FormSelect
                id='move asset location'
                items={locations}
                onItemSelect={onLocationSelect}
                selectedValue={moveAssetLocationId?.id}
                loading={isLoading}
                placeholder="Select location"
                disabled={isLoading || isSaving}

            />
            <div className="bp3-dialog-footer">
                <div className="bp3-dialog-footer-actions">
                    <ButtonSave text="Save and close" intent="primary" onClick={moveLocation} disabled={isLoading || isSaving} />
                    <Button text="Cancel" intent="secondary" onClick={closeModal} disabled={isLoading || isSaving} />
                </div>
            </div>
        </Modal>
    )
}

MoveAsset.defaultProps = {
    profile: {},
    isVisible: false,
    closeModal: () => { },
    saveAssetLocation: () => { },
    isSaving: false
};

MoveAsset.propTypes = {
    profile: PropTypes.object.isRequired,
    isVisible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    saveAssetLocation: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired,
    assetType: PropTypes.object.isRequired
};