import { useState } from 'react';

var _ = require('underscore');

export function useValidation() {
	const [isValid, setIsValid] = useState(true);
	const [errors, setErrors] = useState({});

	function validate(rules, model, externalRules) {
		setIsValid(true);
		let tempErrors = {};
		// loops through each of the rules specified
		_.each(rules, function (rule) {
			const value = getValueFromField(rule.fieldName, model);
			// works out if the value of the field is a string and if it is empty
			const isString = typeof (value) === "string";
			const emptyArray = Array.isArray(value) && value.length === 0;
			const emptyObj = typeof value === 'object' && value !== null && _.isEmpty(value);
			const isEmpty = value == null || (isString && value.trim().length === 0) || emptyArray || emptyObj;

			// if the field is required but empty, log an error
			if (rule.required && isEmpty) {
				setIsValid(false);
				tempErrors[rule.fieldName] = "This field is required.";
				return; // this stops processing on the current rule, but continues processing subsequent rules
			}

			// if the field is a string, then process string-specific rules
			if (isString) {			
				if (rule.maxLength && value.length > rule.maxLength) {
					setIsValid(false);
					tempErrors[rule.fieldName] = "Can not exceed " + rule.maxLength + " characters.";
					return; // this stops processing on the current rule, but continues processing subsequent rules
				}

				if (rule.minLength && value.length < rule.minLength) {
					setIsValid(false);
					tempErrors[rule.fieldName] = "Can not be less than " + rule.minLength + " characters.";
					return; // this stops processing on the current rule, but continues processing subsequent rules
				}
			}

			// if the field is a number, then process number-specific rules. 
			// if its not a number then call the safe parseFloat function for scenarios where a number is passed as a string
			if (typeof (value) === "number" || !Number.isNaN(parseFloat(value))) {
				// the next condition requires that both minValue and maxValue are specified in order for it to work
				if (rule.minValue !== null && rule.minValue !== undefined && rule.maxValue !== null && rule.maxValue !== undefined && (value < rule.minValue || value > rule.maxValue)) {
					setIsValid(false);
					tempErrors[rule.fieldName] = "Must be between " + rule.minValue + " and " + rule.maxValue + ".";
					return; // this stops processing on the current rule, but continues processing subsequent rules
				}

				// if we have a minValue constraint we need to check the value is a number and if its less than the minValue if true then log an error 
				if (rule.minValue !== null && rule.minValue !== undefined) {
					const parsedValue = parseFloat(value)

					if (!Number.isNaN(parsedValue) && parsedValue < rule.minValue) {
						setIsValid(false);
						tempErrors[rule.fieldName] = "Must be more than " + rule.minValue;
						return; 
					}
				} 
				
				// if we have a maxValue constraint we need to check the value is a number and if its more than the maxValue if true then log an error 
				if (rule.maxValue !== null && rule.maxValue !== undefined) {
					const parsedValue = parseFloat(value)
					
					if (!Number.isNaN(parsedValue) && parsedValue > rule.maxValue) {
						setIsValid(false);
						tempErrors[rule.fieldName] = "Must be less than " + rule.maxValue;
						return;
					}
				}
			}

			var emailParts = String(value).toLowerCase().split('@');

			if (rule.type && rule.type === "noneLogisticsEmail") {
				const nonWorkEmailExpression = /@logistics\.org\.uk|@fta\.co\.uk/;
				if (nonWorkEmailExpression.test(String(value).toLowerCase())) {
					setIsValid(false);
					tempErrors[rule.fieldName] = "Cannot use internal email address.";
					return;
				}

				
				const emailUserName = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))$/;
				const emailDomain = /^((d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				if (emailParts.length !== 2 || !emailUserName.test(emailParts[0]) || !emailDomain.test(emailParts[1])) {
					setIsValid(false);
					tempErrors[rule.fieldName] = "Value must be a valid email address.";
					return;
				}
			}

			if (rule.type && rule.type === "email") {
				const emailUserName1 = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))$/;
				const emailDomain1 = /^((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				if (emailParts.length !== 2 || !emailUserName1.test(emailParts[0]) || !emailDomain1.test(emailParts[1])) {
					setIsValid(false);
					tempErrors[rule.fieldName] = "Value must be a valid email address.";
					return;
				}
			}

			if (rule.type && rule.type === "phone") {
				const re = /^\+?(?:\d\s?){10,12}$/;
				if (value && value.length > 0 && !re.test(String(value).toLowerCase())) {
					setIsValid(false);
					tempErrors[rule.fieldName] = "Value must be a valid phone number.";
					return;
				}
			}
			if (rule.type && rule.type === "postcode") {
				const reRoi = /^([AC-FHKNPRTV-Y]\d{2}|D6W) ?[0-9AC-FHKNPRTV-Y]{4}$/;
				const reUk = /^([A-Z][A-HJ-Y]?\d[A-Z0-9]? ?\d[A-Z]{2})$/;
				if (value && value.length > 0 && !reRoi.test(String(value).toUpperCase()) && !reUk.test(String(value).toUpperCase()) && String(value).toUpperCase() !== "GIR0AA") {
					setIsValid(false);
					tempErrors[rule.fieldName] = "Value must be a valid post code.";
					return;
				}
			}
			if (rule.type && rule.type === "alphanumeric") {
				const re = /^[a-zA-Z0-9]*$/;
				if (!re.test(String(value).toLowerCase())) {
					setIsValid(false);
					tempErrors[rule.fieldName] = "Value must contain letters and numbers only.";
					return;
				}
			}
			if (rule.type && rule.type === "url") {
				const re = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&/=]*)$/;

				if (!re.test(String(value).toLowerCase())) {
					setIsValid(false);
					tempErrors[rule.fieldName] = "Value must be a valid url.";
					return;
				}
			}
		});

		//We have some external rules that have been validated elsewhere
		if (externalRules != null) {
			_.each(externalRules, function (rule) {
				//Check the rule has a field name and that that field doesnt already have an error
				if (rule.fieldName != null && tempErrors[rule.fieldName] == null && !rule.valid) {
					tempErrors[rule.fieldName] = rule.errorMessage;
					setIsValid(false);
				}
			});
		}

		setErrors(tempErrors);
	}

	function getValueFromField(fieldName, model) {

		if (typeof model !== 'object') {
			return model;
		}

		// finds the value of the field that the rule applies to
		const nestedObjectNames = fieldName.split(".");
		let value = model;
		_.each(nestedObjectNames, function (name) {
			value = value[name];
		});
		return value;
	}

	return [isValid, errors, validate];
}