import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import './Layout.css';

import classNames from 'classnames';


export function Expandable(props) {

    return (
        <Fragment>

            <div className={classNames("item-expandable", { "stretched": props.stretched, "expanded": props.expanded, "bp3-skeleton": props.loading })}>
                {props.children}
            </div>
            
        </Fragment>

    );
}

Expandable.defaultProps = {
    loading: false,
    stretched: false,
    expanded: false
};

Expandable.propTypes = {
    loading: PropTypes.bool,
    stretched: PropTypes.bool,
    expanded: PropTypes.bool.isRequired
};