import { useEffect, useState } from "react";
import { Button, Intent } from "@blueprintjs/core";
import { FormDateInput, FormTextInput } from "components/form-fields";
import { AlertUnsavedData, Modal, NotificationToaster } from "components/notifications";
import { ButtonSave } from "components/buttons";
import { TransportManagersService } from "services";
import { useValidation } from "hooks/useValidation";
import { useDirtyData } from "hooks/useDirtyData";
import moment from "moment";
import PropTypes from "prop-types";

export function TransportManagerUpsertModal(props) {
    const {
        operatingLicenceId,
        showAddTransportManager,
        setShowAddTransportManager,
        transportManagerForename,
        transportManagerSurname,
        cpcObtainedDate,
        cpcRefresherDate,
        handleSaveTransportManager,
        reloadTransportManagers,
        isEdit,
        transportManagerId
    } = props;

    const transportManagerModel = {
        transportManagerForename: props.transportManagerForename,
        transportManagerSurname: transportManagerSurname,
        cpcObtainedDate: cpcObtainedDate,
        cpcRefresherDate: cpcRefresherDate
    }

    const [saving, setSaving] = useState(false);
    const [originalTransportManager, setOriginalTransportManager] = useState(transportManagerModel);
    const [transportManager, setTransportManager] = useState(originalTransportManager);
    const [modalValid, setModalValid] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isTransportManagerValid, transportManagerErrors, validateTransportManager] = useValidation();
    const isTransportManagerDirty = useDirtyData(originalTransportManager, transportManager);
    const modalTitle = isEdit ? "Edit Transport Manager" : "New Transport Manager";
    const characterLimit = 255;

    useEffect(validate, [transportManager, isTransportManagerDirty, isTransportManagerValid])

    useEffect(setIfModalValid, [isTransportManagerValid, isTransportManagerDirty]);

    useEffect(() => {
        resetData();
    }, [isEdit])

    useEffect(() => {
        setOriginalTransportManager({
            transportManagerForename,
            transportManagerSurname,
            cpcObtainedDate,
            cpcRefresherDate
        });
        setTransportManager({
            transportManagerForename,
            transportManagerSurname,
            cpcObtainedDate,
            cpcRefresherDate
        });
    }, [
        transportManagerForename,
        transportManagerSurname,
        cpcObtainedDate,
        cpcRefresherDate
    ]);

    function resetData() {
        if (!isEdit) {
            let transportManagerPOJO = {
                transportManagerForename: "",
                transportManagerSurname: "",
                cpcObtainedDate: "",
                cpcRefresherDate: ""
            };
            setOriginalTransportManager(transportManagerPOJO);
            setTransportManager(transportManagerPOJO);
        } else {
            setOriginalTransportManager({
                transportManagerForename,
                transportManagerSurname,
                cpcObtainedDate,
                cpcRefresherDate
            });
            setTransportManager({
                transportManagerForename,
                transportManagerSurname,
                cpcObtainedDate,
                cpcRefresherDate
            });
        }
    }


    function handleSave() {
        if (!isTransportManagerValid || !isTransportManagerDirty) {
            return;
        }

        setSaving(true);
        upsertTransportManager();
    }

    function upsertTransportManager() {
        if (isEdit) {
            updateTransportManager();
        }
        else {
            createTransportManager();
        }
    }

    function createTransportManager() {
        let request = {
            "operatingLicenceId": operatingLicenceId,
            "firstName": transportManager.transportManagerForename,
            "lastName": transportManager.transportManagerSurname,
            "cpcObtainedDate": transportManager.cpcObtainedDate,
            "cpcRefresherDate": transportManager.cpcRefresherDate
        };
        TransportManagersService.createTransportManager(request)
            .then(function () {
                NotificationToaster.show(Intent.SUCCESS, "Transport Manager created successfully.");
                reloadTransportManagers();
                resetModalData();
                handleSaveTransportManager();
            })
            .catch(() => {
                NotificationToaster.show(Intent.DANGER, "Unable to create the Transport Manager, please try again.");
            })
            .finally(() => {
                setSaving(false);
            });
    }

    function resetModalData() {
        setShowAlert(false);
        resetData();
    }

    function updateTransportManager() {
        let request = {
            "firstName": transportManager.transportManagerForename,
            "lastName": transportManager.transportManagerSurname,
            "cpcObtainedDate": transportManager.cpcObtainedDate,
            "cpcRefresherDate": transportManager.cpcRefresherDate
        };
        TransportManagersService.updateTransportManager(transportManagerId, request)
            .then(function () {
                NotificationToaster.show(Intent.SUCCESS, "Transport Manager updated successfully.");
                reloadTransportManagers();
                resetModalData();
                handleSaveTransportManager();
            })
            .catch(() => {
                NotificationToaster.show(Intent.DANGER, "Unable to update the Transport Manager, please try again.");
            })
            .finally(() => {
                setSaving(false);
            });
    }

    function handleCancel() {
        if (isTransportManagerDirty) {
            setShowAlert(true);
            return;
        }

        setShowAddTransportManager(false);
        setSaving(false);
        resetModalData();
    }

    function onValueChange(newValue, field, controlType = "default") {
        let passedValue = newValue;

        if (controlType === "date") {
            passedValue = newValue ? moment(newValue).format("YYYY-MM-DD") : null
        }

        if (controlType === "radio") {
            passedValue = newValue === "true";
        }

        passedValue = passedValue === "" ? null : passedValue;

        setTransportManager((prevValue) => {
            let clonedScores = { ...prevValue }
            clonedScores[field] = passedValue;

            return clonedScores;
        })
    }

    function validate() {
        let rules = [
            { fieldName: 'transportManagerForename', required: true },
            { fieldName: 'transportManagerSurname', required: true },
            { fieldName: 'cpcObtainedDate', required: true },
            { fieldName: 'cpcRefresherDate', required: false }
        ];

        validateTransportManager(rules, transportManager);
    }

    function setIfModalValid() {
        var valid = isTransportManagerValid;

        setModalValid(valid);
    }

    function handleConfirmLeave() {
        setShowAddTransportManager(false);
        setSaving(false);
        resetModalData();
    }

    function handleCancelLeave() {
        setShowAlert(false);
    }

    return (<>
        <Modal
            title={modalTitle}
            isOpen={showAddTransportManager}
            onClose={handleCancel}
            isCloseButtonShown={false}
            className="transport-manager-modal"
            clickOutsideClose={false}
            updateStateOnClose={false}
            enforceFocus={false}
        >
            <div className="transport-manager-modal-body">
                <div >
                    <div className="new-transport-manager-grid">
                        <div className="transport-manager-grid-kvp">
                            <FormTextInput
                                headingText="Forename"
                                placeholder="Forename"
                                value={transportManager.transportManagerForename}
                                onChange={(event) => onValueChange(event.target.value, "transportManagerForename")}
                                dangerHelperText={transportManagerErrors.transportManagerForename}
                                disabled={saving}
                                maxLength={characterLimit}
                            />
                        </div>
                        <div className="transport-manager-grid-kvp">
                            <FormTextInput
                                headingText="Surname"
                                placeholder="Surname"
                                value={transportManager.transportManagerSurname}
                                onChange={(event) => onValueChange(event.target.value, "transportManagerSurname")}
                                dangerHelperText={transportManagerErrors.transportManagerSurname}
                                disabled={saving}
                                maxLength={characterLimit}
                            />
                        </div>
                        <div className="transport-manager-grid-kvp">
                            <FormDateInput
                                headingText="Date CPC Obtained"
                                value={transportManager.cpcObtainedDate}
                                onChange={(event) => onValueChange(event, "cpcObtainedDate", "date")}
                                minDate={moment("1900-01-01").toDate()}
                                maxDate={moment().toDate()}
                                dangerHelperText={transportManagerErrors.cpcObtainedDate}
                                disabled={saving}
                            />
                        </div>
                        <div className="transport-manager-grid-kvp">
                            <FormDateInput
                                headingText="Date of last CPC refresher"
                                value={transportManager.cpcRefresherDate}
                                onChange={(event) => onValueChange(event, "cpcRefresherDate", "date")}
                                minDate={moment("1900-01-01").toDate()}
                                maxDate={moment().toDate()}
                                dangerHelperText={transportManagerErrors.cpcRefresherDate}
                                disabled={saving}
                            />
                        </div>
                    </div>
                    <div className="button-row">
                        <Button
                            text="CANCEL"
                            intent={Intent.NONE}
                            onClick={handleCancel}
                            disabled={saving}
                            className="cancel-button"
                        />
                        <ButtonSave
                            text="SAVE AND CLOSE"
                            intent={Intent.PRIMARY}
                            onClick={handleSave}
                            loading={false}
                            disabled={saving}
                            simpleDisabled={!modalValid}
                            icon="floppy-disk"
                        />
                        {showAlert && (
                            <AlertUnsavedData
                                isDirty={isTransportManagerDirty}
                                isDirtySamePage={isTransportManagerDirty}
                                onConfirmLeave={handleConfirmLeave}
                                onCancelLeave={handleCancelLeave}
                            />
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    </>);
}

TransportManagerUpsertModal.defaultProps = {
    operatingLicenceId: null,
    showAddTransportManager: false,
    setShowAddTransportManager: () => { },
    transportManagerForename: "",
    transportManagerSurname: "",
    cpcObtainedDate: "",
    cpcRefresherDate: "",
    handleSaveTransportManager: () => { },
    reloadTransportManagers: () => { },
    isEdit: false,
    transportManagerId: null
};

TransportManagerUpsertModal.propTypes = {
    operatingLicenceId: PropTypes.number.isRequired,
    showAddTransportManager: PropTypes.bool.isRequired,
    setShowAddTransportManager: PropTypes.func.isRequired,
    transportManagerForename: PropTypes.string.isRequired,
    transportManagerSurname: PropTypes.string.isRequired,
    cpcObtainedDate: PropTypes.string.isRequired,
    cpcRefresherDate: PropTypes.string,
    handleSaveTransportManager: PropTypes.func.isRequired,
    reloadTransportManagers: PropTypes.func.isRequired,
    isEdit: PropTypes.bool.isRequired,
    transportManagerId: PropTypes.number
}