import React, { useState, useEffect } from "react";
import { FormHeading, FormNumericInput } from "components/form-fields";
import { Button } from "components/buttons";
import { OperatingCentreService, OperatingLicenceService } from "services";
import { Card, Icon, Intent } from "@blueprintjs/core";
import { ListingPagination, ListingTable, Table } from "components/listing";
import OperatingLicenceMap from "pages/operating-licences/view/maps/OperatingLicenceMap";
import OperatingLicenceMapModal from "pages/operating-licences/view/maps/OperatingLicenceMapModal";
import { Collapsable, ShowHide } from "components/layout";
import "pages/operating-licences/view/OperatingLicenceView.css"
import 'pages/operating-licences/OperatingLicences.css';
import { DateToLocal } from "components/dates";
import { AlertConfirm, AlertUnsavedData, Modal, NotificationToaster } from "components/notifications";
import { Numeric } from "components/formatting";
import { GridLayout } from "components/grid";
import classNames from "classnames";
import { useDirtyData } from "hooks/useDirtyData";
import axios from "axios";
import { UserHasAccess } from "components/user-access";
import PropTypes from "prop-types";
import { CreateOperatingCentreModal } from "pages/operating-licences/modals/CreateOperatingCentreModal";
import { UpdateOperatingCentreModal } from "pages/operating-licences/modals/UpdateOperatingCentreModal";

export function OperatingLicenceOverview(props) {

    const {
        id,
        operatingLicenceOptions,
        currentListing
    } = props;

    const defaultItemsPerPage = 25;
    const buttonWidth = 50;
    const columnWidth180 = 180;
    const columnWidth155 = 155;
    const columnWidth100Percent = "100%";
    const columnWidths = [columnWidth100Percent, columnWidth155, columnWidth180, columnWidth180, columnWidth180, buttonWidth, buttonWidth, buttonWidth];
    const columnHeaders = [
        "OPERATING CENTRES",
        "NO. OF LOCATIONS",
        "AUTHORISED VEHICLES",
        "AUTHORISED TRAILERS",
        "POSTCODE",
    ];

    function getColumnHeaders() {
        if (canManageLicence) {
            columnHeaders.push("EDIT", "DELETE");
        }

        columnHeaders.push("");

        return columnHeaders;
    }

    const nestedColumnHeaders = [
        "LOCATION",
        "VEHICLES",
        "TRAILERS"
    ]

    const [loadingData, setLoadingData] = useState(true);
    const [loadingOperatingCentres, setLoadingOperatingCentres] = useState(false);

    const canManageLicence = UserHasAccess("OperatingLicence:Manage");
    const [operatingCentres, setOperatingCentres] = useState([]);
    const [mapMarkers, setMapMarkers] = useState(null);
    const [isInitialising, setIsInitialising] = useState(true);
    const [operatingCentreAuthorisedVehicles, setOperatingCentreAuthorisedVehicles] = useState(0);
    const [operatingCentreTotalVehicles, setOperatingCentreTotalVehicles] = useState(0);
    const [operatingCentreAuthorisedTrailers, setOperatingCentreAuthorisedTrailers] = useState(0);
    const [operatingCentreTotalTrailers, setOperatingCentreTotalTrailers] = useState(0);
    const [operatingCentreNumberOfLocations, setOperatingCentreNumberOfLocations] = useState(0);
    const [operatingCentreAddress1, setOperatingCentreAddress1] = useState("");
    const [operatingCentreAddress2, setOperatingCentreAddress2] = useState("");
    const [operatingCentreAddress3, setOperatingCentreAddress3] = useState("");
    const [operatingCentreOverviewText, setOperatingCentreOverviewText] = useState("Please select to view data");
    const [operatingCentreRowClasses, setOperatingCentreRowClasses] = useState([]);
    const [expandedRows, setExpandedRows] = useState({});
    const [selectedOperatingCentreId, setSelectedOperatingCentreId] = useState(0);
    const [currentTrafficCommisioner, setCurrentTrafficCommisioner] = useState(null);
    const [showExpandedMap, setShowExpandedMap] = useState(false);
    const [totalOperatingCentresCount, setTotalOperatingCentresCount] = useState(0);

    const [currentLicence, setCurrentLicence] = useState({});
    const [editLicenceModel, setEditLicenceModel] = useState({});
    const [originalEditLicenceModel, setOriginalEditLicenceModel] = useState({});

    const [showLicenceInfo, setShowLicenceInfo] = useState(true);
    const [showOcrsKey, setShowOcrsKey] = useState(false);

    const [showOcrsEdit, setShowOcrsEdit] = useState(false);
    const [savingOcrsScores, setSavingOcrsScores] = useState(false);

    const isLicenceDataDirty = useDirtyData(originalEditLicenceModel, editLicenceModel);
    const [blockModalClose, setBlockModalClose] = useState(false);
    const [ocrsScoresInvalid, setOcrsScoresInvalid] = useState(false);

    const [showDeleteOperatingCentre, setShowDeleteOperatingCentre] = useState(false);
    const [showCreateOperatingCentreModal, setShowCreateOperatingCentreModal] = useState(false);
    const [showUpdateOperatingCentreModal, setShowUpdateOperatingCentreModal] = useState(false);
    const [operatingCentreIdToUpdate, setOperatingCentreIdToUpdate] = useState(null);
    const [operatingCentrePage, setOperatingCentrePage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const perPage = defaultItemsPerPage;

    useEffect(() => {

        if(id == null || operatingLicenceOptions == null || currentListing == null) {
            return;
        }

        fetchLicence();
        initialiseOperatingCentres();

    }, [props.id, props.operatingLicenceOptions, props.currentListing]);

    function initialiseOperatingCentres() {
        setIsInitialising(true);
        setLoadingOperatingCentres(true);

        axios.all([
            OperatingCentreService.searchOperatingCentres(getOperatingCentresSearchPayload()),
            OperatingLicenceService.getMapMarkers(id)
        ]).then(axios.spread(function (operatingCentresResponse, markers) {
            handleOperatingCentresResponse(operatingCentresResponse);
            setMapMarkers(markers);
        }))
            .finally(() => {
                setIsInitialising(false);
                setLoadingOperatingCentres(false);
            });
    }

    function handleOperatingCentresResponse(operatingCentresResponse) {
        setOperatingCentres(operatingCentresResponse.data);
        setOperatingCentreRowClasses(Array(operatingCentresResponse.data.length * 2).fill(null));
        setTotalOperatingCentresCount(operatingCentresResponse.totalCount);
    }

    function getOperatingCentresSearchPayload(page) {
        return {
            "requestCount": 25,
            "pageNumber": page !== null && page !== undefined ? page : operatingCentrePage,
            "filters": [
                {
                    "key": "OperatingLicence",
                    "value": id
                }
            ]
        };
    }

    //Fetch operating licence details for individual licence
    function fetchLicence() {
        setLoadingData(true);

        OperatingLicenceService.getLicence(id)
            .then(
                function (licenceResponse) {
                    setCurrentLicence(licenceResponse);

                    var editModel = {
                        ...licenceResponse,
                        licenceArea: licenceResponse.licenceArea.id,
                        licenceType: licenceResponse.licenceType.id,
                        licenceRenewalDate: licenceResponse.licenceRenewal,
                        ocrsRoadScore: licenceResponse.ocrs?.roadScore,
                        ocrsTrafficScore: licenceResponse.ocrs?.trafficScore,
                        ocrsCombinedScore: licenceResponse.ocrs?.combinedScore,
                        earnedRecognitionNumber: licenceResponse.ocrs?.earnedRecognitionNumber
                    };

                    delete editModel.ocrs;

                    setEditLicenceModel(editModel);
                    setOriginalEditLicenceModel(editModel);
                    setCurrentTrafficCommisioner(operatingLicenceOptions?.trafficCommissioners?.find(x => x.licenceAreaId === licenceResponse.licenceArea.id));
                }, 
                function () {
                    NotificationToaster.show("danger", "Could not load operating licence information, please try again", true);
                })
            .finally(() => {
                setLoadingData(false);
            });
    }

    function handleRowClick(index) {
        let operatingCentreId = operatingCentres[index / 2].centreId; //Normalise to handle inner tables
        setSelectedOperatingCentreId(operatingCentreId);
        clickMarkerHandler(operatingCentreId);

        const updatedClasses = [...operatingCentreRowClasses];

        var existingIndex = updatedClasses.indexOf("operating-centres-clicked-row-style");

        if (existingIndex !== -1) {
            updatedClasses[existingIndex] = null;
            updatedClasses[existingIndex + 1] = null;//this should never overflow
        }

        updatedClasses[index] = "operating-centres-clicked-row-style";
        updatedClasses[index + 1] = "operating-centres-clicked-inner-table-row-style";
        setOperatingCentreRowClasses(updatedClasses);
    }

    function handleRowSelectionFromMarkerClick(operatingCentreId) {
        let rowIndex = operatingCentres.findIndex(x => x.centreId === operatingCentreId) * 2;
        setSelectedOperatingCentreId(operatingCentreId);

        const updatedClasses = [...operatingCentreRowClasses];

        var existingIndex = updatedClasses.indexOf("operating-centres-clicked-row-style");

        if (existingIndex !== -1) {
            updatedClasses[existingIndex] = null;
            updatedClasses[existingIndex + 1] = null;//this should never overflow
        }

        updatedClasses[rowIndex] = "operating-centres-clicked-row-style";
        updatedClasses[rowIndex + 1] = "operating-centres-clicked-inner-table-row-style";
        setOperatingCentreRowClasses(updatedClasses);
    }

    function toggleRowExpansion(index) {
        setExpandedRows((prev) => ({
            [index]: !prev[index]
        }));
    }

    function onDeleteOperatingCentre(centre, e) {
        setSelectedOperatingCentreId(centre.centreId);
        e.stopPropagation();

        const totalTrailers = centre.locations.reduce((sum, location) => {
            return sum + location.assignedTrailers;
        }, 0);

        const totalVehicles = centre.locations.reduce((sum, location) => {
            return sum + location.assignedVehicles;
        }, 0);

        setOperatingCentreAuthorisedTrailers(totalTrailers);
        setOperatingCentreAuthorisedVehicles(totalVehicles);
        setOperatingCentreNumberOfLocations(centre.locations.length);
        setShowDeleteOperatingCentre(true);
    }

    function onConfirmDeleteOperatingCentre() {
        setLoadingOperatingCentres(true);
        setIsInitialising(true);
        resetOperatingCentreState();

        OperatingCentreService.deleteOperatingCentre(selectedOperatingCentreId)
            .then(
                function () {
                    NotificationToaster.show(Intent.SUCCESS, "Operating Centre deleted successfully.");
                }, 
                function () {
                    NotificationToaster.show(Intent.DANGER, "Unable to delete the Operating Centre, please try again.");
                }
            )
            .finally(() => {
                setShowDeleteOperatingCentre(false);
                setLoadingOperatingCentres(false);
                setIsInitialising(false);
            });

    }


    function resetOperatingCentreState() {
        setShowDeleteOperatingCentre(false);
        setOperatingCentres(operatingCentres.filter(item => item.centreId !== selectedOperatingCentreId));
        setSelectedOperatingCentreId(0);
        setOperatingCentreRowClasses([]);
        setOperatingCentreAuthorisedVehicles(0);
        setOperatingCentreTotalVehicles(0);
        setOperatingCentreAuthorisedTrailers(0);
        setOperatingCentreTotalTrailers(0);
        setOperatingCentreNumberOfLocations(0);
        setOperatingCentreAddress1("");
        setOperatingCentreAddress2("");
        setOperatingCentreAddress3("");
        setOperatingCentreOverviewText("Please select to view data");
    }

    function onCancelDeleteOperatingCentre() {
        setShowDeleteOperatingCentre(false);
    }

    const operatingCentreRows = operatingCentres.flatMap((x, index) => {
        const isRowExpanded = expandedRows[index] === true;

        const listingRow = [
            <div key={`${x.operatingLicenceId}-centre-name`}>{x.centreName}</div>,
            <div key={`${x.operatingLicenceId}-locations`}>{x.locations.length}</div>,
            <div
                key={`${x.operatingLicenceId}-vehicles`}
                className={classNames({ "operating-licence-assets-over-limit": x.locations.reduce((prev, current) => prev + current.assignedVehicles, 0) > x.totalVehicles })}
            >
                <div>{x.locations.reduce((prev, current) => prev + current.assignedVehicles, 0)}/{x.totalVehicles}</div>
            </div>,
            <div
                key={`${x.operatingLicenceId}-trailers`}
                className={classNames({ "operating-licence-assets-over-limit": x.locations.reduce((prev, current) => prev + current.assignedTrailers, 0) > x.totalTrailers })}
            >
                <div>{x.locations.reduce((prev, current) => prev + current.assignedTrailers, 0)}/{x.totalTrailers}</div>
            </div>,
            <div key={`${x.operatingLicenceId}-post-code`}>{x.postCode}</div>,
            <div key={`${x.operatingLicenceId}-expand-button`}>
                <Button
                    onClick={() => toggleRowExpansion(index)}
                    icon={isRowExpanded ? "caret-up" : "caret-down"}
                    iconOnly={true}
                    minimal={true}
                />
            </div>
        ];

        if (canManageLicence) {
            var spliceIndex = 5;
            listingRow.splice(spliceIndex, 0,
                <div key={`${x.operatingLicenceId}-edit`} >
                    <Button
                        onClick={(e) => onUpdateCentreClick(x.centreId, e)}
                        icon="edit"
                        iconOnly={true}
                        minimal={true}
                    />
                </div>,
                <div key={`${x.operatingLicenceId}-delete`}>
                    <Button
                        onClick={(e) => { onDeleteOperatingCentre(x, e) }}
                        icon="trash"
                        iconOnly={true}
                        minimal={true}
                        className="note-button-delete"
                    />
                </div>
            )
        }

        const locationData = x.locations.map((y, locationIndex) => {
            return [
                <div key={`${x.operatingLicenceId}-${locationIndex}-location`}>{y.location}</div>,
                <div key={`${x.operatingLicenceId}-${locationIndex}-assigned-vehicles`}>{y.assignedVehicles}</div>,
                <div key={`${x.operatingLicenceId}-${locationIndex}-assigned-trailers`}>{y.assignedTrailers}</div>
            ];
        });

        const locationBreakDownRow = [
            {
                content: (
                    <div>
                        <ShowHide
                            evaluator={isRowExpanded}
                            show={
                                <>
                                    <ListingTable
                                        headers={nestedColumnHeaders}
                                        pageable={false}
                                        data={locationData}
                                        loadingData={isInitialising}
                                        totalRecordCount={x.locations.length}
                                    />
                                </>
                            }
                        >
                        </ShowHide>
                    </div>
                ),
                colSpan: 8,
                className: "inner-row"
            }
        ];

        return [listingRow, locationBreakDownRow];
    });

    function clickMarkerHandler(markerId) {
        handleRowSelectionFromMarkerClick(markerId);
        if (selectedOperatingCentreId !== markerId) {
            OperatingCentreService.getOperatingCentre(markerId).then(function (operatingCentre) {
                setOperatingCentreOverviewText(operatingCentre.name);
                setOperatingCentreAuthorisedVehicles(operatingCentre.assignedVehicles);
                setOperatingCentreTotalVehicles(operatingCentre.authorisedVehicles);
                setOperatingCentreAuthorisedTrailers(operatingCentre.assignedTrailers);
                setOperatingCentreTotalTrailers(operatingCentre.authorisedTrailers);
                setOperatingCentreNumberOfLocations(operatingCentre.locations.length);
                setOperatingCentreAddress1(operatingCentre.address1);
                setOperatingCentreAddress2(operatingCentre.address2);
                setOperatingCentreAddress3(operatingCentre.address3);
            });
        }
    };

    function onHeaderToggle() {
        setShowLicenceInfo((prev) => !prev);
    }


    function calculateOcrsBand(score, category) {

        if (score == null) {
            return <span className="ocrs-grey">-</span>;
        }

        const trafficMaxBandGreen = 5;
        const nonTrafficMaxBandGreen = 10;

        let greenBand = category === "TRAFFIC" ? trafficMaxBandGreen : nonTrafficMaxBandGreen;

        if (score <= greenBand) {
            return <span className="ocrs-green"><Numeric>{score}</Numeric></span>;
        }

        const trafficMaxBandAmber = 30;
        const nonTrafficMaxBandAmber = 25;

        let amberBand = category === "TRAFFIC" ? trafficMaxBandAmber : nonTrafficMaxBandAmber;

        if (score > amberBand) {
            return <span className="ocrs-red"><Numeric>{score}</Numeric></span>;
        }

        return <span className="ocrs-amber"><Numeric>{score}</Numeric></span>;

    }

    function onOcrsScoreUpdate(newValue, field) {

        setOcrsScoresInvalid(false);

        let parsedValue = newValue === "" ? null : newValue;

        if (parsedValue != null && parsedValue < 0) {
            setOcrsScoresInvalid(true);
        }

        setEditLicenceModel((prevValue) => {

            let clonedScores = { ...prevValue }
            clonedScores[field] = parsedValue;

            return clonedScores;

        })

    }

    function onOcrsEditModalCancel(forceClose = false) {

        setBlockModalClose(false);

        if (isLicenceDataDirty && !forceClose) {
            setBlockModalClose(true);
            return;
        }

        setEditLicenceModel((prevValue) => {

            return {
                ...prevValue,
                ocrsRoadScore: currentLicence.ocrs?.roadScore,
                ocrsTrafficScore: currentLicence.ocrs?.trafficScore,
                ocrsCombinedScore: currentLicence.ocrs?.combinedScore
            };

        });
        setShowOcrsEdit(false);
    }

    function onSaveOcrsScores() {
        setSavingOcrsScores(true);

        OperatingLicenceService.updateOperatingLicence(id, editLicenceModel).then(function () {
            NotificationToaster.show("success", "OCRS scores successfully updated", true);
            setShowOcrsEdit(false);
            setOriginalEditLicenceModel(editLicenceModel);
            setCurrentLicence((prevValue) => {
                return {
                    ...prevValue,
                    ocrs: {
                        roadScore: editLicenceModel.ocrsRoadScore,
                        trafficScore: editLicenceModel.ocrsTrafficScore,
                        combinedScore: editLicenceModel.ocrsCombinedScore,
                    }
                }
            });
        }, function (error) {
            const badRequestCode = 400;

            if (error.status === badRequestCode) {
                NotificationToaster.show("warning", error.data, true);
            } else {
                NotificationToaster.show("danger", "Could not update OCRS scores, please try again", true);
            }

        }).finally(function () {
            setSavingOcrsScores(false);
        })
    }

    function handleExpandMap() {
        setShowExpandedMap(!showExpandedMap);
    }

    function onPagingChange(page) {
        setLoadingOperatingCentres(false);
        setOperatingCentrePage(page);

        OperatingCentreService.searchOperatingCentres(getOperatingCentresSearchPayload(page))
            .then((searchResults) => {
                handleOperatingCentresResponse(searchResults);
            })
            .finally(() => {
                setLoadingOperatingCentres(false);
            });
    }

    useEffect(() => {
        setTotalPages(Math.ceil(totalOperatingCentresCount / perPage));
    }, [totalOperatingCentresCount, perPage])

    function onAddCentreClick() {
        setShowCreateOperatingCentreModal(true);
    }

    function onSaveCreateCentreModal() {
        onAddCentreClick();
        setShowCreateOperatingCentreModal(false);
        initialiseOperatingCentres();
    }

    function addCentreModalClose() {
        setShowCreateOperatingCentreModal(false);
    }

    function onUpdateCentreClick(operatingCentreId, e) {
        e.stopPropagation();
        setOperatingCentreIdToUpdate(operatingCentreId);
        setShowUpdateOperatingCentreModal(true);
    }

    function onSaveUpdateCentreModal() {
        setShowUpdateOperatingCentreModal(false);
        setOperatingCentreIdToUpdate(null);
        initialiseOperatingCentres();
    }

    function onUpdateCentreModalClose(forceClose = false) {
        setOperatingCentreIdToUpdate(null);
        setShowUpdateOperatingCentreModal(false);

        // If the modal was force closed then refresh the operating centres as the operating centre that was opened likely doesn't exist anymore
        if (forceClose) {
            initialiseOperatingCentres();
        }
    }

    return (
        <>
            <div id="tab-operating-licence-details">
                <Collapsable title={currentLicence.licenceArea?.name} fullWidth expanded={showLicenceInfo} onClick={onHeaderToggle} loading={loadingData}>
                    <div className="collapsable-content">
                        <ShowHide
                            evaluator={currentLicence.earnedRecognitionOperator === true}
                            hide={(
                                <>
                                    <div className="inline-items inline-items-space spacer-bottom-small">
                                        <FormHeading headingLevel="h2" loading={loadingData}>OCRS</FormHeading>
                                        <div className="button-row">
                                            <Button icon="properties" iconOnly large={false} minimal onClick={() => setShowOcrsKey(true)} loading={loadingData} />
                                            
                                            <ShowHide
                                                evaluator={canManageLicence}
                                                show={(
                                                    <Button icon="edit" iconOnly large={false} minimal onClick={() => setShowOcrsEdit(true)} loading={loadingData} />
                                                )}/>
                                        </div>
                                    </div>
                                    <GridLayout numberOfColumns={4} className="spacer-bottom">
                                        <div className={classNames({ 'bp3-skeleton': loadingData })}>
                                            <div className="ol-stat-header">Road Worthiness Score</div>
                                            <div className="ol-stat-large">{calculateOcrsBand(currentLicence.ocrs?.roadScore, 'ROAD')}</div>
                                        </div>
                                        <div className={classNames({ 'bp3-skeleton': loadingData })}>
                                            <div className="ol-stat-header">Traffic Score</div>
                                            <div className="ol-stat-large">{calculateOcrsBand(currentLicence.ocrs?.trafficScore, 'TRAFFIC')}</div>
                                        </div>
                                        <div className={classNames({ 'bp3-skeleton': loadingData })}>
                                            <div className="ol-stat-header">Combined Score</div>
                                            <div className="ol-stat-large">{calculateOcrsBand(currentLicence.ocrs?.combinedScore, 'COMBINED')}</div>
                                        </div>
                                    </GridLayout>
                                </>
                            )}
                        />
                        <FormHeading headingLevel="h2" loading={loadingData}>Licence Information</FormHeading>
                        <GridLayout numberOfColumns={4} className="spacer-bottom">
                            <div className={classNames({ 'bp3-skeleton': loadingData })}>
                                <div className="ol-stat-header">Licence Area</div>
                                <div className="ol-stat">{currentLicence.licenceArea?.name}</div>
                            </div>
                            <div className={classNames({ 'bp3-skeleton': loadingData })}>
                                <div className="ol-stat-header">Licence Type</div>
                                <div className="ol-stat">{currentLicence.licenceType?.name}</div>
                            </div>
                            <div className={classNames({ 'bp3-skeleton': loadingData })}>
                                <div className="ol-stat-header">Licence Number</div>
                                <div className="ol-stat">********{currentLicence.licenceNumber}</div>
                            </div>
                            <div className={classNames({ 'bp3-skeleton': loadingData })}>
                                <div className="ol-stat-header">Licence Renewal Date</div>
                                <div className="ol-stat"><DateToLocal format={"DD MMM YYYY"}>{currentLicence.licenceRenewal}</DateToLocal></div>
                            </div>
                            <div className={classNames({ 'bp3-skeleton': loadingData })}>
                                <div className="ol-stat-header">No. of Operating Centres</div>
                                <div className="ol-stat"><Numeric>{currentListing?.operatingCentres}</Numeric></div>
                            </div>
                            <div className={classNames({ 'bp3-skeleton': loadingData })}>
                                <div className="ol-stat-header">Authorised Vehicles</div>
                                <div className="ol-stat"><Numeric>{currentListing?.totalVehicles}</Numeric></div>
                            </div>
                            <div className={classNames({ 'bp3-skeleton': loadingData })}>
                                <div className="ol-stat-header">Authorised Trailers</div>
                                <div className="ol-stat"><Numeric>{currentListing?.totalTrailers}</Numeric></div>
                            </div>
                            <ShowHide
                                evaluator={currentLicence.earnedRecognitionOperator === true}
                                show={(
                                    <div className={classNames({ 'bp3-skeleton': loadingData })}>
                                        <div className="ol-stat-header">Earned Recognition Number</div>
                                        <div className="ol-stat">{currentLicence?.ocrs?.earnedRecognitionNumber ?? "-"}</div>
                                    </div>
                                )}
                            />

                        </GridLayout>
                        <div className={classNames({ 'bp3-skeleton': loadingData })}>
                            <div className="ol-stat-header">Traffic Commisioner's office</div>
                            <div className="ol-stat">
                                <div className="inline-items">
                                    <Icon icon="map-marker" />
                                    <span>{currentTrafficCommisioner?.address}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </Collapsable>
                <div className={classNames("operating-licence-collapseable-section spacer-bottom", { "bp3-skeleton": loadingData })}>
                    {currentLicence && currentLicence.licenceArea && mapMarkers && currentTrafficCommisioner ? (
                        <div className="operating-centres-map-container">
                            <div className="operating-centre-map">
                                <OperatingLicenceMap
                                    width="100%"
                                    height="400px"
                                    operatingLicenceArea={currentLicence.licenceArea}
                                    mapMarkers={mapMarkers}
                                    trafficCommisioner={{
                                        lat: currentTrafficCommisioner.lat,
                                        lng: currentTrafficCommisioner.lng,
                                    }}
                                    selectedCentreId={selectedOperatingCentreId}
                                    clickMarkerHandler={(x) => clickMarkerHandler(x)}
                                />
                            </div>
                            <Button
                                className="operating-centre-map-fullscreen"
                                icon={"fullscreen"}
                                iconOnly={true}
                                minimal={true}
                                onClick={handleExpandMap}
                            />
                        </div>
                    ) : null}

                    <Card className="operating-centre-overview-section">
                        <h6>OPERATING CENTRE</h6>
                        <h2 className="operating-centre-overview-text">{operatingCentreOverviewText}</h2>
                        <Card className="operating-centre-inner-card-style">
                            <div className="operating-centre-inner-card-kvp-container">
                                <div className="operating-centre-inner-card-kvp">
                                    <h6>AUTHORISED VEHICLES</h6>
                                    <div>{operatingCentreAuthorisedVehicles}/{operatingCentreTotalVehicles}</div>
                                </div>
                                <div className="operating-centre-inner-card-kvp">
                                    <h6>AUTHORISED TRAILERS</h6>
                                    <div>{operatingCentreAuthorisedTrailers}/{operatingCentreTotalTrailers}</div>
                                </div>
                                <div className="operating-centre-inner-card-kvp">
                                    <h6>NUMBER OF LOCATIONS</h6>
                                    <div>{operatingCentreNumberOfLocations}</div>
                                </div>
                                <div className="operating-centre-inner-card-kvp">
                                    <h6>FULL ADDRESS</h6>
                                    <div>{operatingCentreAddress1}</div>
                                    <div>{operatingCentreAddress2}</div>
                                    <div>{operatingCentreAddress3}</div>
                                </div>
                            </div>
                        </Card>
                    </Card>

                </div>
                <div className={classNames("inline-items inline-items-space spacer-top spacer-bottom", { 'bp3-skeleton': loadingData })}>
                    <h1>Operating Centres</h1>
                    <ShowHide
                        evaluator={canManageLicence}
                        show={(
                            <div className="inline-items">
                                <ShowHide
                                    evaluator={
                                        !isInitialising
                                    && operatingCentreRows.length === 0
                                    }
                                    show={
                                        <Card className="add-operating-licence-object-card">
                                            Please select the plus button to add a new Operating Centre
                                        </Card>
                                    }
                                ></ShowHide>
                                <Button icon="plus" minimal={true} onClick={onAddCentreClick} disabled={loadingData} />
                            </div>
                        )}/>
                </div>
                <div className="operating-centres-headers-contained-content">
                    <ListingTable
                        id="listing-table-operating-centre-headers"
                        headers={getColumnHeaders()}
                        data={[]}
                        totalRecordCount={1}
                        loadingData={false}
                        columnWidths={columnWidths}
                        loadingClass={operatingCentres.length === 0 ? "bp3-skeleton" : "bp3-skeleton loading"}
                        pageable={false}
                        showPerPage={false}
                    />
                </div>
                <div className="operating-centres-contained-content-auto spacer-bottom-medium">
                    <ListingTable
                        id="listing-table-operating-centres"
                        headers={[]}
                        data={operatingCentreRows}
                        totalRecordCount={totalOperatingCentresCount}
                        onPagingChange={onPagingChange}
                        loadingData={isInitialising || loadingOperatingCentres}
                        noDataMessage={"No Operating Centres to display."}
                        rowClickEnabled={true}
                        rowClasses={operatingCentreRowClasses}
                        onRowClick={handleRowClick}
                        columnWidths={columnWidths}
                        loadingClass={operatingCentres.length === 0 ? "bp3-skeleton" : "bp3-skeleton loading"}
                        pageable={false}
                        showPerPage={false}
                    />
                </div>
                <div>
                    <ListingPagination 
                        currentPage={operatingCentrePage} 
                        totalPages={totalPages} 
                        onPageChange={onPagingChange} 
                        loadingData={isInitialising || loadingOperatingCentres} 
                        className="pagination-inline" 
                    />
                </div>
            </div>

            <Modal
                isOpen={showOcrsKey}
                title="OCRS Banding Key"
                onClose={() => setShowOcrsKey(false)}
            >
                <div className="spacer-bottom">
                    <Table
                        headerData={{
                            headers: [{ key: "band", value: "OCRS Band" }, { key: "road", value: "Road Worthiness" }, { key: "traffic", value: "Traffic" }, { key: "combined", value: "Combined" }]
                        }}
                        data={[
                            { cells: [<span className="ocrs-green" key="ocrs-green">Green</span>, "10 defect points or below", "5 offence points or below", "10 points or below"], key: "Green" },
                            { cells: [<span className="ocrs-amber" key="ocrs-amber">Amber</span>, "10.01 to 25 defect points", "5.01 to 30 offence points", "10.01 to 25 points"], key: "Amber" },
                            { cells: [<span className="ocrs-red" key="ocrs-red">Red</span>, "25.01 defect points or more", "30.01 offence points or more", "25.01 points or more"], key: "Red" },
                            { cells: [<span className="ocrs-grey" key="ocrs-grey">Grey</span>, "No score", "No score", "No score"], key: "Grey" }
                        ]}
                        loadingData={false}
                    />
                </div>
                <p>It is possible to have a score for one category but not for another. You are classified as a 'grey' operator if you have not had an interaction with DVSA and have no score in any category.</p>
            </Modal>

            {currentLicence && currentLicence.licenceArea && mapMarkers && currentTrafficCommisioner ?
                <OperatingLicenceMapModal
                    showMapModal={showExpandedMap}
                    currentLicence={currentLicence}
                    mapMarkers={mapMarkers}
                    trafficCommisioner={{
                        lat: currentTrafficCommisioner.lat,
                        lng: currentTrafficCommisioner.lng,
                    }}
                    selectedOperatingCentreId={selectedOperatingCentreId}
                    clickMarkerHandler={(x) => { clickMarkerHandler(x) }}
                    operatingCentreOverviewText={operatingCentreOverviewText}
                    operatingCentreAuthorisedVehicles={operatingCentreAuthorisedVehicles}
                    operatingCentreTotalVehicles={operatingCentreTotalVehicles}
                    operatingCentreAuthorisedTrailers={operatingCentreAuthorisedTrailers}
                    operatingCentreTotalTrailers={operatingCentreTotalTrailers}
                    operatingCentreNumberOfLocations={operatingCentreNumberOfLocations}
                    operatingCentreAddress1={operatingCentreAddress1}
                    operatingCentreAddress2={operatingCentreAddress2}
                    operatingCentreAddress3={operatingCentreAddress3}
                    handleCloseMapModal={handleExpandMap}
                ></OperatingLicenceMapModal>
                : null}


            <AlertConfirm
                isOpen={showOcrsEdit}
                title="OCRS"
                onCancel={onOcrsEditModalCancel}
                showCloseButton
                confirmButtonText="Save and close"
                confirmButtonIntent="primary"
                onConfirm={onSaveOcrsScores}
                disabled={savingOcrsScores || ocrsScoresInvalid}
            >
                <GridLayout numberOfColumns={3}>
                    <FormNumericInput min={0} max={9999} allowDecimal decimalPlaces={2} headingText="Road Worthiness Score" selectedValue={editLicenceModel?.ocrsRoadScore} onValueChange={(newValue) => onOcrsScoreUpdate(newValue, "ocrsRoadScore")} disabled={savingOcrsScores} />
                    <FormNumericInput min={0} max={9999} allowDecimal decimalPlaces={2} headingText="Traffic Score" selectedValue={editLicenceModel?.ocrsTrafficScore} onValueChange={(newValue) => onOcrsScoreUpdate(newValue, "ocrsTrafficScore")} disabled={savingOcrsScores} />
                    <FormNumericInput min={0} max={9999} allowDecimal decimalPlaces={2} headingText="Total Score" selectedValue={editLicenceModel?.ocrsCombinedScore} onValueChange={(newValue) => onOcrsScoreUpdate(newValue, "ocrsCombinedScore")} disabled={savingOcrsScores} />
                </GridLayout>
            </AlertConfirm>

            <CreateOperatingCentreModal
                showModal={showCreateOperatingCentreModal}
                operatingLicenceId={Number.parseInt(id)}
                onSaveModal={onSaveCreateCentreModal}
                onCloseModal={addCentreModalClose}
            />

            <UpdateOperatingCentreModal
                showModal={showUpdateOperatingCentreModal}
                operatingCentreId={operatingCentreIdToUpdate}
                operatingLicenceId={Number.parseInt(id)}
                onSaveModal={onSaveUpdateCentreModal}
                onCloseModal={onUpdateCentreModalClose}
            />

            <AlertConfirm
                title="Delete Operating Centre"
                isOpen={showDeleteOperatingCentre}
                onCancel={onCancelDeleteOperatingCentre}
                onConfirm={onConfirmDeleteOperatingCentre}
                confirmButtonText="Delete"
            >
                <p>Are you sure you want to delete this operating centre?</p>
                <p>Deleting this operating centre will remove {operatingCentreNumberOfLocations} locations and {operatingCentreAuthorisedTrailers + operatingCentreAuthorisedVehicles} assets from this operating licence.</p>
            </AlertConfirm>

            <AlertUnsavedData
                isDirty={isLicenceDataDirty}
                isDirtySamePage={blockModalClose}
                onCancelLeave={() => setBlockModalClose(false)}
                onConfirmLeave={() => onOcrsEditModalCancel(true)}
            />
        </>

    );
}

OperatingLicenceOverview.defaultProps = {
};

OperatingLicenceOverview.propTypes = {
    id: PropTypes.string.isRequired,
    operatingLicenceOptions: PropTypes.object,
    currentListing: PropTypes.object
}