import classNames from "classnames";
import { Button } from "components/buttons";
import { Filter } from "components/filtering";
import { FormLocationSelector, FormTextInput } from "components/form-fields";
import { ListingTable } from "components/listing";
import { Tooltip } from "components/tooltip";
import { useDebounce } from "hooks/useDebounce";
import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { SetFiltering } from 'state/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { AssetService, OperatingLicenceService, UserService } from "services";
import { AssetStatus } from "components/status/AssetStatus";


export function OperatingLicenceAssets(props) {

    const dispatch = useDispatch();

    const debounceTimeout = 750;
    const pageListingSize = 50;

    const [initalSetupComplete, setInitalSetupComplete] = useState(false);
    const [initialFiltersLoaded, setInitialFiltersLoaded] = useState(false);
    const [initialLocationsLoaded, setInitialLocationsLoaded] = useState(false);
    const [initialOptionsLoaded, setInitialOptionsLoaded] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);

    const [loadingData, setLoadingData] = useState(true);

    const [showFilters, setShowFilters] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [pageFilters, setPageFilters] = useState([]);


    const tableHeaders = ["Registration", "Asset Type", "Reference", "Location"];
    const [totalRecords, setTotalRecords] = useState(0);
    const [tableRows, setTableRows] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);

    const [locationList, setLocationList] = useState({});
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [locationsChanged, setLocationsChanged] = useState(false);
    const [useHierarchy, setUseHierarchy] = useState(true);

    const currentPageNumber = useRef();
    const currentSearchTerm = useRef();
    const currentAppliedFilters = useRef();
    const inputSearchRef = useRef(null);
    const isVehiclePage = props.type === "vehicles"

    const [assetStatusesMappings, setAssetStatusesMappings] = useState([]);
    const [assetOwnershipMappings, setAssetOwnershipMappings] = useState([]);

    const cachedLocations = useSelector(state => state.filters["operatingLicenceLocations"]);

    useEffect(() => {

        if (props.id == null) {
            return;
        }

        function mapLocationList(locationRepsonse) {
            return locationRepsonse.location[0].items.map(function (l) {
                return l.id;
            });
        }

        UserService.getLoggedInUserLocationsForMultipleBusinessAreas([1, 2], true, true).then(function(locationsResponse) {
            if(cachedLocations?.locations.length > 0) {
                setSelectedLocations(cachedLocations.locations);
            } else {
                setSelectedLocations(mapLocationList(locationsResponse));
            }

            setLocationList(locationsResponse);
        })

        AssetService.getAssetOptions().then(function (optionsResponse) {

            var filterArray = [
                {
                    "displayName": "Asset Status",
                    "name": "ASSETSTATUS",
                    "items": optionsResponse.assetStatuses.filter((option) => {
                        return option.name !== "Inactive";
                    }).map((option) => {
                        return { "id": option.id, "name": option.name }
                    })

                },
                {
                    "displayName": "Asset Ownership",
                    "name": "ASSETOWNERSHIP",
                    "items": optionsResponse.assetOwnerships.map((option) => {
                        return { "id": option.id, "name": option.name }
                    })
                }
            ];

            const allowedAssetTypes = ["Heavy Goods Vehicle", "Light Commercial Vehicle", "Public Service Vehicle"];

            if(isVehiclePage) {
                filterArray.unshift({
                    "displayName": "Asset Type",
                    "name": "ASSETTYPE",
                    "items": optionsResponse.assetTypes.filter(x => x.publicAllowed && allowedAssetTypes.includes(x.name)).map((option) => {
                        return { "id": option.id, "name": option.name }
                    })

                })
            }

            setPageFilters(filterArray);

            setAssetOwnershipMappings(optionsResponse.assetOwnerships.map((x) => {
                return { "name": x.name, "colours": x.colour, "id": x.id, "className": x.style, "icon": x.icon }
            }));
            setAssetStatusesMappings(optionsResponse.assetStatuses.map((x) => {
                return { "name": x.name, "colours": x.colour, "id": x.id }
            }));

            setInitialOptionsLoaded(true);

        }, function () {

        });

    }, [props.id]);

    function onSearchChange(e) {
        setSearchTerm(e.target.value);
    }

    function onToggleFilter() {
        setShowFilters((prevState) => {
            return !prevState;
        })
    }

    function onLocationSelect(locations) {
        if (selectedLocations.length !== locations.length) {
            setLocationsChanged(true);
        }
        var newLocationSelection = [...locations];

        setSelectedLocations(newLocationSelection);
    }

    function onLocationSelectorClose() {
        if (locationsChanged) {
            dispatch(SetFiltering("operatingLicenceLocations", { locations: selectedLocations }));
            setLocationsChanged(false);
            fetchData(true);
        }
    }

    function onPagingChange(newPageNumber) {
        setPageNumber(newPageNumber);
    }

    function onFilterChange(listingFilters) {
        setInitialFiltersLoaded(true);
        setPageNumber(1);
        setAppliedFilters(listingFilters);

        if (listingFilters.length > appliedFilters.length) {
            setShowFilters(true);
        }
    }

    useEffect(() => {
        setInitalSetupComplete(initialFiltersLoaded && initialLocationsLoaded && initialOptionsLoaded);
    }, [initialFiltersLoaded, initialLocationsLoaded, initialOptionsLoaded]);

    function fetchData(forceRefresh) {
        if (initalSetupComplete) {

            if (currentPageNumber.current === pageNumber &&
                currentSearchTerm.current === debouncedSearchTerm &&
                currentAppliedFilters.current === appliedFilters.length &&
                !forceRefresh
            ) {
                //If none of the values have changed, then 2 have been updated at once, so prevent the listing from performing another call.
                return;
            }

            currentPageNumber.current = pageNumber;
            currentSearchTerm.current = debouncedSearchTerm;
            currentAppliedFilters.current = appliedFilters.length;

            setLoadingData(true);

            OperatingLicenceService.getVehicleList(props.id, pageNumber, pageListingSize, debouncedSearchTerm, appliedFilters, props.type, selectedLocations).then(function (listResponse) {
                setTableRows(listResponse.data.data.map(function (row) {
                    return [
                        <div className="inline-items" key={row.id}>
                            <div className="inline-item">
                                <AssetStatus statusId={row.assetStatusId} ownershipId={row.assetOwnershipId} statusAttributeMappings={assetStatusesMappings} ownershipAttributeMappings={assetOwnershipMappings} />
                            </div>
                            <div className="inline-item">
                            <Link key={row.id} to={`/asset/${row.id}`}>{row.registration}</Link>
                            </div>
                        </div>,
                        row.assetType,
                        row.fleetNumber,
                        row.location];
                }));
    
                setTotalRecords(listResponse.data.totalCount);

                setTimeout(function () {
                    if (inputSearchRef.current != null) {
                        inputSearchRef.current.focus();
                    }
                });

            }, function () {
    
            }).finally(function () {
                setLoadingData(false);
            });

        }
    }

    useEffect(() => {
        if(cachedLocations != null && !initialLocationsLoaded) {
            setInitialLocationsLoaded(true);
            if(cachedLocations.locations?.length > 0) {
                setSelectedLocations(cachedLocations.locations);
            }
        }
    }, [cachedLocations]);

    useEffect(() => {
        fetchData(false);
    }, [pageNumber, debouncedSearchTerm, appliedFilters.length, initalSetupComplete, locationsChanged]);

    return (

        <div id="tab-operating-licence-assets" className="spacer-top-small">
            <h2>Asset List: {isVehiclePage ? "Vehicles" : "Trailers"}</h2>
            <p className="intro-text">The assets displayed below are pulled through from the locations associated with the O-Licence region. If an asset is moved from a location associated with this O-Licence to a location associated with another it will move from this page to the corresponding O-Licence.</p>
            <div className="spacer-bottom">
                <div className="inline-items inline-items-fill">
                    <div className="inline-item">
                        <FormTextInput placeholder="Search fleet" onChange={onSearchChange} inputRef={inputSearchRef} value={searchTerm} large disabled={loadingData} icon="search" id="asset-search-field" />
                    </div>
                    <FormLocationSelector
                        businessArea=""
                        loading={props.loading}
                        selectedLocations={selectedLocations}
                        setSelectedLocations={onLocationSelect}
                        useHierarchy={useHierarchy}
                        setUseHierarchy={setUseHierarchy}
                        useCustomLocations
                        customLocations={locationList}
                        disabled={loadingData}
                        onLocationListClose={onLocationSelectorClose}
                    />
                    <Tooltip content="Filter" position="right">
                        <Button icon="filter" onClick={onToggleFilter} className={classNames({ "active": appliedFilters.length > 0 })} minimal disabled={loadingData} />
                    </Tooltip>
                </div>
                <Filter filterName="operatingLicenceAssets" visible={showFilters} filters={pageFilters} onUpdate={onFilterChange} />

            </div>

            <ListingTable showPerPage={false} pageSize={pageListingSize} headers={tableHeaders} data={tableRows} totalRecordCount={totalRecords} loadingData={loadingData} onPagingChange={onPagingChange} />

        </div>
    );
}

OperatingLicenceAssets.defaultProps = {
    type: "",
};

OperatingLicenceAssets.propTypes = {
    id: PropTypes.number.isRequired,
    type: PropTypes.string,
};