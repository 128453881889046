import ApiService from 'services/ApiService'

const badRequestCode = 400;

export const TransportManagersService = {

	searchTransportManagers(data) {
		return new Promise((resolve, reject) => {
			ApiService.post('/asset/TransportManagers/search', data).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	deleteTransportManager(id) {
		return new Promise((resolve, reject) => {
			ApiService.delete(`/asset/TransportManagers/${id}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject("Unable to delete the Transport Manager. Please try again.");
				});
		});
	},
	createTransportManager(data) {
		return new Promise((resolve, reject) => {
			ApiService.post('/asset/TransportManagers', data).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	updateTransportManager(id, data) {
		return new Promise((resolve, reject) => {
			ApiService.put(`/asset/TransportManagers/${id}`, data).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	}
}