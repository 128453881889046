import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { UserHasAccess } from "components/user-access";
import { NotificationToaster } from "components/notifications";
import { Intent } from "@blueprintjs/core";
import { OperatingLicenceService } from 'services';
import { OperatingCentreModal } from 'pages/operating-licences/modals/shared';

export function UpdateOperatingCentreModal(
    {
        showModal,
        operatingCentreId,
        operatingLicenceId,
        onSaveModal,
        onCloseModal
    }
) {
    const requiredActions = ["OperatingLicence:Manage"];
    const canAccessPage = UserHasAccess(requiredActions);

    const operatingCentreModel = {
        name: '',
        address1: '',
        address2: '',
        address3: '',
        town: '',
        county: '',
        postcode: '',
        authorisedVehicles: null,
        authorisedTrailers: null,
        locations: []
    };

    const [fetchingOperatingCentre, setFetchingOperatingCentre] = useState(false);
    const [operatingCentreDetails, setOperatingCentreDetails] = useState(operatingCentreModel);

    useEffect(onOperatingCentreIdChange, [operatingCentreId, showModal]);

    function onOperatingCentreIdChange() {
        if (!canAccessPage || !showModal || fetchingOperatingCentre || operatingCentreId == null) {
            return;
        }

        setFetchingOperatingCentre(true);

        OperatingLicenceService.getOperatingCentre(operatingCentreId).then(
            (operatingCentreResponse) => {
                setOperatingCentreDetails(operatingCentreResponse);
                setFetchingOperatingCentre(false);
            }
        )
        .catch(() => {
            NotificationToaster.show(Intent.DANGER, "Failed to fetch operating centre");
            setFetchingOperatingCentre(false);
            onCloseModal(true);
        });
    }

    function onSave(operatingCentre) {
        return OperatingLicenceService.updateOperatingCentre(operatingCentreId, operatingCentre)
            .then(
                ({ data }) => {
                    NotificationToaster.show(Intent.SUCCESS, "Operating Centre created successfully.");
                    onSaveModal(data);
                }
            )
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    return (
        <>
            <OperatingCentreModal 
                showModal={showModal}
                modalTitle="Edit Operating Centre"
                operatingCentreId={operatingCentreId}
                operatingLicenceId={operatingLicenceId}
                requiredActions={requiredActions}
                isUpdate={true}
                loading={fetchingOperatingCentre}
                showFinishOnAllSteps={true}
                onSave={onSave}
                onCloseModal={onCloseModal}
                centreDetails={operatingCentreDetails}
            />
        </>
    );
}

UpdateOperatingCentreModal.defaultProps = {

};

UpdateOperatingCentreModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    operatingCentreId: PropTypes.number,
    operatingLicenceId: PropTypes.number.isRequired,
    onSaveModal: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired
};