import moment from "moment";
import { Tooltip, Position } from "@blueprintjs/core";
import { LinkButton } from "components/buttons";
import { HelperFunctions } from 'helpers';

const mmfCookie = 'Use_New_MMF';

export const LinkService = {
    renderAssetCalendarLink(assetId, date, usePortal = true) {
        return (
            <Tooltip position={Position.TOP} content={`Vehicle Calendar`} usePortal={usePortal}>
                <LinkButton
                    href={`${window.env.VISION_URL}/#/Fleet/RedirectToCalendar?vehicleId=${assetId}&activityDate=${moment(date).format('YYYY-MM-DD')}`}
                    icon={"calendar"}
                    externalLink={true}
                    minimal={true}
                    iconOnly={true}
                    large={false}
                    text="Calendar"
                />
            </Tooltip>
        );
    },

    renderEmployeeCalendarLink(employeeId, date, usePortal = true) {
        return (
            <Tooltip position={Position.TOP} content={`Driver Calendar`} usePortal={usePortal}>
                <LinkButton
                    href={`${window.env.VISION_URL}/#/Drivers/RedirectToCalendar?driverId=${employeeId}&activityDate=${moment(date).format('YYYY-MM-DD')}`}
                    icon={"calendar"}
                    externalLink={true}
                    minimal={true}
                    iconOnly={true}
                    large={false}
                />
            </Tooltip>
        );
    },

    getVehicleCalendarUrl(assetId, date) {
        return `${window.env.VISION_URL}/#/Fleet/RedirectToCalendar?vehicleId=${assetId}&activityDate=${moment(date).format('YYYY-MM-DD')}`
    },

    getVehicleProfileUrl(assetId, tabName, optionalParams = "") {
        if (HelperFunctions.checkForCookie(mmfCookie)) {
            return `/asset/${assetId}/${tabName}?${optionalParams}`;
        } else {
            //May need to review this for tab=tab-history
            return `${window.env.VISION_URL}/#/Fleet/RedirectToProfile?assetId=${assetId}&tab=${tabName}Tab&${optionalParams}`;
        }
    },

    getDriverProfileUrl(employeeId) {
        return `${window.env.VISION_URL}/#/Drivers/RedirectToProfile?employeeId=${employeeId}`;
    },

    getDriverCalendarUrl(employeeId, date) {
        return `${window.env.VISION_URL}/#/Drivers/RedirectToCalendar?driverId=${employeeId}&activityDate=${moment(date).format('YYYY-MM-DD')}`;
    },

    renderVehicleProfileLink(assetId, usePortal = true) {
        return (
            <Tooltip position={Position.TOP} content={"Vehicle Profile"} usePortal={usePortal}>
                <LinkButton
                    href={this.getVehicleProfileUrl(assetId)}
                    icon={"fa-truck"}
                    externalLink={!HelperFunctions.checkForCookie(mmfCookie)}
                    minimal={true}
                    iconOnly={true}
                    large={false}
                    faColour="#5c7080"
                />
            </Tooltip>
        );
    },

    renderEmployeeProfileLink(employeeId, usePortal = true) {
        return (
            <Tooltip position={Position.TOP} content={"Driver Profile"} usePortal={usePortal}>
                <LinkButton
                    href={`${window.env.VISION_URL}/#/Drivers/RedirectToProfile?employeeId=${employeeId}`}
                    icon={"fa-user"}
                    externalLink={true}
                    minimal={true}
                    iconOnly={true}
                    large={false}
                    faColour="#5c7080"
                />
            </Tooltip>
        );
    }
};