import React, { useState, useEffect } from "react";
import { FormSelect } from "components/form-fields";
import { Button, LinkButton } from "components/buttons";
import { Breadcrumbs, HorizontalLinkGroup } from "components/navigation";
import { OperatingLicenceService } from "services";
import { useParams, useHistory, Switch, Route, Redirect } from "react-router-dom";
import { Icon } from "@blueprintjs/core";
import { PageRestricted } from "pages/errors/page-restricted";
import {  ShowHide } from "components/layout";
import "./OperatingLicenceView.css"
import '../OperatingLicences.css';
import { NotificationInline } from "components/notifications";
import axios from "axios";
import { UpdateOperatingLicenceModal } from "pages/operating-licences/modals";
import { OperatingLicenceAssets } from "pages/operating-licences/view/assets";
import { UserAccess, UserHasAccess } from "components/user-access";
import { LoadScript } from "@react-google-maps/api";
import PropTypes from "prop-types";
import { OperatingLicenceOverview } from "pages/operating-licences/view/overview";
import { OperatingLicenceTransportManagers } from "./transport-managers";

export function OperatingLicenceView(props) {
    
    const history = useHistory();
    const { id } = useParams();

    const [tabs, setTabs] = useState([]);

    const [loadingData, setLoadingData] = useState(true);

    const [operatingLicences, setOperatingLicences] = useState([]);
    const [invalidLicence, setInvalidLicence] = useState(false);
    const requiredAction = "OperatingLicence:View";
    const canManageLicence = UserHasAccess("OperatingLicence:Manage");
    const [operatingLicenceOptions, setOperatingLicenceOptions] = useState(null);

    const [currentListing, setCurrentListing] = useState({});

    const [showUpdateOperatingLicenceModal, setShowUpdateOperatingLicenceModal] = useState(false);

    useEffect(() => {
        axios.all([
            OperatingLicenceService.getLicences(),
            OperatingLicenceService.getOptions()
        ]).then(axios.spread(function (licenceResponse, options) {
            setOperatingLicences(mapLicenceResponse(licenceResponse));
            setOperatingLicenceOptions(options);
            setLoadingData(false);
        }));
    }, []);

    function refreshLicences() {
        setLoadingData(true);

        OperatingLicenceService.getLicences()
            .then((response) => {
                setOperatingLicences(mapLicenceResponse(response));
            })
            .finally(() => {
                setLoadingData(false);
            });
    }

    function mapLicenceResponse(licenceResponse) {
        return licenceResponse.map(function (licence) {
            return {
                id: licence.operatingLicenceId.toString(),
                name: licence.isFavourite ?
                    <div className="inline-items" >
                        <div>{licence.licenceArea}</div>
                        <div className="operating-licence-favourite-icon">
                            <Icon icon="star" />
                        </div>
                    </div> : licence.licenceArea,
                ...licence,
                licenceArea: licence.licenceArea
            }
        });
    }

    useEffect(() => {
        if (id == null || operatingLicences.length === 0 || operatingLicenceOptions == null) {
            return;
        }

        setInvalidLicence(false);
        let licence = operatingLicences.find(x => x.id === id);

        if (licence == null) {
            //url contains an invalid licence
            setInvalidLicence(true);
            return;
        }

        setCurrentListing(licence)

        //Set the disabled flag on asset list based on whether there are assets or not

        let tabList = [
            { title: "Overview", key: "OVERVIEW", location: `/operating-licences/${id}/overview` },
            { title: "Asset List: Vehicles", key: "ASSETS:VEHICLES", disabled: licence.assignedVehicles === 0, location: `/operating-licences/${id}/vehicles` },
            { title: "Asset List: Trailers", key: "ASSETS:TRAILERS", disabled: licence.assignedTrailers === 0, location: `/operating-licences/${id}/trailers` },
            { title: "Transport Managers", key: "MANAGERS", location: `/operating-licences/${id}/transport-managers` }
        ];

        setTabs(tabList);

    }, [id, operatingLicences, operatingLicenceOptions]);


    function onOperatingLicenceSelect(item) {
        if (item != null && item.id !== id) {
            history.push(`/operating-licences/${item.id}`)
        }
    }

    function onEditLicence() {
        setShowUpdateOperatingLicenceModal(true);
    }


    function onSaveUpdateModal() {
        refreshLicences();
        setShowUpdateOperatingLicenceModal(false);
    }

    function onUpdateLicenceModalClose(forceClose = false) {
        setShowUpdateOperatingLicenceModal(false);

        // if force close is true we want to redirect to the licence listing page as it means the operating licence most likely doesn't exist
        if (forceClose) {
            history.push(`/operating-licences`)
        }
    }

    return (
        <UserAccess
            perform={requiredAction}
            yes={() => (
                <div>
                    <LoadScript googleMapsApiKey={window.env.GOOGLEMAPSAPIKEY}>
                        <Breadcrumbs items={props.breadcrumbs} />
                        <ShowHide
                            evaluator={invalidLicence}
                            hide={(

                                <div>
                                    <h1>Operating Licences</h1>
                                    <div className="spacer-bottom inline-items">
                                        <FormSelect items={operatingLicences} selectedValue={id} scrollable onItemSelect={onOperatingLicenceSelect} disabled={loadingData}></FormSelect>
                                        <LinkButton text="Licence List View" intent="primary" href="/operating-licences?redirect=0" disabled={loadingData} />
                                        
                                        <ShowHide
                                            evaluator={canManageLicence}
                                            show={(
                                                <Button 
                                                    text="Edit Licence" 
                                                    intent="primary" 
                                                    onClick={onEditLicence} 
                                                    disabled={loadingData} 
                                                />
                                            )}
                                        />
                                    </div>
                                    <HorizontalLinkGroup links={tabs} disabled={loadingData} />
                                    <Switch>

                                        <Route path="/operating-licences/:id(\d+)/overview">
                                            <OperatingLicenceOverview id={id} operatingLicenceOptions={operatingLicenceOptions} currentListing={currentListing} />
                                        </Route>

                                        <Redirect exact from="/operating-licences/:id(\d+)" to="/operating-licences/:id(\d+)/overview" />

                                        <Route path="/operating-licences/:id(\d+)/vehicles">
                                            <OperatingLicenceAssets id={Number(id)} type="vehicles" key="vehicles" />
                                        </Route>

                                        <Route path="/operating-licences/:id(\d+)/trailers">
                                            <OperatingLicenceAssets id={Number(id)} type="trailers" key="trailers" />
                                        </Route>

                                        <Route path="/operating-licences/:id(\d+)/transport-managers">
                                            <OperatingLicenceTransportManagers id={Number(id)} />
                                        </Route>

                                    </Switch>

                                    <UpdateOperatingLicenceModal
                                        showModal={showUpdateOperatingLicenceModal}
                                        operatingLicenceId={Number.parseInt(id)}
                                        onSaveModal={onSaveUpdateModal}
                                        onCloseModal={onUpdateLicenceModalClose}
                                        shouldForceClose={true}
                                    />

                                </div>
                            )}
                            show={(
                                <div>
                                    <NotificationInline
                                        allowClose={false}
                                        show
                                        text="You do not have permission to view this licence"
                                        intent="danger">
                                    </NotificationInline>
                                    <div>
                                        <LinkButton intent="primary" text="Back to list view" href="/operating-licences?redirect=0" id="return-licence-listing" />
                                    </div>
                                </div>
                            )}
                        />
                    </LoadScript>
                </div>
            )}
            no={() => <PageRestricted />}
        />

    );
}

OperatingLicenceView.defaultProps = {
    breadcrumbs: []
};

OperatingLicenceView.propTypes = {
    breadcrumbs: PropTypes.array.isRequired
}