import React, { useState, useEffect } from 'react';
import { AlertService } from 'services';
import { Breadcrumbs, InternalLink } from 'components/navigation';
import { ListingTable } from "components/listing";
import './AlertTypeListing.css';
import classNames from 'classnames';

export function AlertTypeListing(props) {

    const tableHeaders = ["Alert Name", "Active Alerts"];
    const [loadingData, setLoadingData] = useState(true);
    const [alerts, setAlerts] = useState([
        {
            sectionName: 'Alert group 1', // This is just dummy alert data to get a loading state. They will not be shown to the user
            sectionAlerts: [
                {
                    title: 'Alert 1',
                    alertCount: 1
                }
            ]
        },
        {
            sectionName: 'Alert group 2',
            sectionAlerts: [
                {
                    title: 'Alert 2',
                    alertCount: 1
                },
                {
                    title: 'Alert 3',
                    alertCount: 1
                },
                {
                    title: 'Alert 4',
                    alertCount: 1
                }
            ]
        }
    ]);
    const [tableRowSections, setTableRowSections] = useState([
        {
            sectionName: 'Alert group 1', // This is just dummy alert data to get a loading state. They will not be shown to the user
            baseColumns: [
                {
                    title: 'Alert 1',
                    alertCount: 1
                }
            ]
        },
        {
            sectionName: 'Alert group 2',
            baseColumns: [
                {
                    title: 'Alert 2',
                    alertCount: 1
                },
                {
                    title: 'Alert 3',
                    alertCount: 1
                },
                {
                    title: 'Alert 4',
                    alertCount: 1
                }
            ]
        }
    ]);

    function fetchTableData() {
        if (loadingData) {
            AlertService.getAlertTypes(true, true).then(function (alertTypes) {
                var groupedAlerts = AlertService.mapAlertGroupings(alertTypes);
                setAlerts(groupedAlerts);

                let data = groupedAlerts.map((s) => {

                    var baseColumns = [];
                    s.sectionAlerts.map((a) => {
                        baseColumns.push(a);
                    })

                    return {
                        sectionName: s.sectionName,
                        baseColumns: baseColumns
                    };
                });

                setTableRowSections(data);

                setLoadingData(false);
            });
        }
    }

    useEffect(() => {
        fetchTableData();
    }, []);

    function getAlertSection(sectionName) {
        if (tableRowSections?.length > 0) {
            var selectedSection = tableRowSections.filter(s => s.sectionName === sectionName);
            if (selectedSection?.length > 0) {
                //Should only be one entry
                return selectedSection[0].baseColumns.map((a) => {
                    if (a.storedInLegacySystem) {
                        return [
                            <a href={window.env.VISION_URL + '#/Alerts/' + a.alertTypeId} key={`alert-link-${a.key}`}>{a.title}</a>,
                            a.alertCount];
                    } else {
                        return [<InternalLink link={'alert/' + a.key} key={`alert-link-${a.key}`}>{a.title}</InternalLink>,
                            a.alertCount]
                    }
                });
            }
        }
        return [];
    }

    return (
        <div className="row">

            <Breadcrumbs items={props.breadcrumbs} />

            <h1>Alerts</h1>

            <div id="alerts-listing-container" className="data-table-header">
                {
                    alerts.map((alertsSection) => {
                        return (
                            <div className="spacer-bottom-small">
                                <h2 className={classNames({ "bp3-skeleton": loadingData })}>{alertsSection.sectionName}</h2>
                                <ListingTable
                                    id={"listing-table-alert-" + alertsSection.sectionName.replace(' ', '-').toLowerCase()}
                                    headers={tableHeaders}
                                    data={getAlertSection(alertsSection.sectionName)}
                                    loadingData={loadingData}
                                    noDataMessage="No alerts found."
                                    totalRecordCount={alertsSection?.sectionAlerts?.length}
                                    pageable={false}
                                    />
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );

}