import { Card, Icon, Intent, Position } from "@blueprintjs/core";
import { ListingTable } from "components/listing";
import React, { useEffect, useState } from "react";
import { OperatingLicenceService } from "services";
import classNames from "classnames";
import "./OperatingLicenceListing.css"
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { Modal, NotificationToaster } from "components/notifications";
import { UserAccess, UserHasAccess } from "components/user-access";
import { PageRestricted } from "pages/errors/page-restricted";
import { DateToLocal } from "components/dates";
import { Button, ButtonSave } from "components/buttons";
import { Breadcrumbs } from "components/navigation";
import { Tooltip } from "components/tooltip";
import { CreateOperatingLicenceModal, UpdateOperatingLicenceModal } from "pages/operating-licences/modals";
import { ShowHide } from "components/layout";
import { FormTextInput } from "components/form-fields";

export function OperatingLicenceListing(props) {

    const requiredAction = "OperatingLicence:View";
    const canManageLicence = UserHasAccess("OperatingLicence:Manage");
    const [operatingLicences, setOperatingLicences] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [redirectIndex, setRedirectIndex] = useState(-1);
    const location = useLocation();
    const requiredDeleteDataPhrase = 'confirm delete';

    const [selectedRowId, setSelectedRowId] = useState(null);
    const [showDeleteDataModal, setShowDeleteDataModal] = useState(false);
    const [deleteDataPhrase, setDeleteDataPhrase] = useState('');
    const [isDeletingData, setIsDeletingData] = useState(false);

    const [showAddOperatingLicenceModal, setShowAddOperatingLicenceModal] = useState(false);
    const [showUpdateOperatingLicenceModal, setShowUpdateOperatingLicenceModal] = useState(false);
    const [operatingLicenceIdToUpdate, setOperatingLicenceIdToUpdate] = useState(null);
    const [operatingLicenceRowClasses, setOperatingLicenceRowClasses] = useState([]);
    let history = useHistory();
    
    function updateFavourite(index) {
        const updatedLicences = operatingLicences.map((licence, idx) => ({
            ...licence,
            isFavourite: (idx === index) && !licence.isFavourite,
        }));
        setOperatingLicences(updatedLicences);
        OperatingLicenceService.setFavourite(updatedLicences[index].operatingLicenceId);
        handleFavouriteDisplay(index);
    }

    useEffect(fetchTableData, [redirectIndex]);

    function fetchTableData() {

        setIsLoading(true);

        OperatingLicenceService.getLicences().then(function (operatingLicencesResponse) {
            setOperatingLicences(operatingLicencesResponse);
            let favouriteLicence = operatingLicencesResponse.find(x => x.isFavourite)
            var index = operatingLicencesResponse.findIndex(i => i.isFavourite);
            handleFavouriteDisplay(index);
            if (favouriteLicence) {
                setRedirectIndex(favouriteLicence.operatingLicenceId);
            }
        }).catch((error) => {
            NotificationToaster.show(Intent.DANGER, `Failed to get Operating Licences. Please refresh the page.`);
        }).finally(() => {
            setIsLoading(false);
            
        });
    }

    function handleFavouriteDisplay(index) {
        const updatedClasses = [...operatingLicenceRowClasses];
        var existingIndex = updatedClasses.indexOf("operating-licences-favourited-row-style");

        if (existingIndex !== -1) {
            updatedClasses[existingIndex] = null;
        }

        updatedClasses[index] = "operating-licences-favourited-row-style";

        setOperatingLicenceRowClasses(updatedClasses);
    }


    if (redirectIndex > -1 && !location.search.includes('redirect=0')) {
        return <Redirect to={`/operating-licences/${redirectIndex}`} />;
    }

    let columnHeaders = [
        "LICENCE AREA",
        "LICENCE TYPE",
        "LICENCE NUMBER",
        "OPERATING CENTRES",
        "ASSIGNED VEHICLES",
        "ASSIGNED TRAILERS",
        "LICENCE RENEWAL DATE",
        <div className="favourite-header-wrapper" key="favourite-header">
            <div className="favourite-header-text">FAVOURITE</div>
                <Tooltip
                    content="Select a favourite Licence Area to make it your default Operating Licence page."
                    position={Position.TOP}
                >
                    <Icon className="operating-licences-info-icon" icon="info-sign" />
                </Tooltip>
        </div>,
    ];

    if (canManageLicence) {
        columnHeaders.push("EDIT", "DELETE");
    }

    function onAddLicenceClick() {
        setShowAddOperatingLicenceModal(true);
    }

    function onSaveCreateModal(operatingLicenceId) {
        onAddLicenceClick();
        setShowUpdateOperatingLicenceModal(false);
        setTimeout(() => {
            history.push(`/operating-licences/${operatingLicenceId}`);
        });
    }

    function addLicenceModalClose() {
        setShowAddOperatingLicenceModal(false);
    }

    function onUpdateLicenceClick(operatingLicenceId) {
        setOperatingLicenceIdToUpdate(operatingLicenceId);
        setShowUpdateOperatingLicenceModal(true);
    }

    function onSaveUpdateModal() {
        setShowUpdateOperatingLicenceModal(false);
        setOperatingLicenceIdToUpdate(null);
        fetchTableData();
    }

    function updateLicenceModalClose() {
        setOperatingLicenceIdToUpdate(null);
        setShowUpdateOperatingLicenceModal(false);
    }
    const dataRows = operatingLicences.map((x, index) => {

        let tableRow = [
            <Link
                key={`${x.operatingLicenceId}-link`}
                to={`/operating-licences/${x.operatingLicenceId}`}>
                {x.licenceArea}
            </Link>,
            <div key={`${x.operatingLicenceId}-licenceType`}>{x.licenceType}</div>,
            <div key={`${x.operatingLicenceId}-licenceNumber`}>{x.licenceNumber}</div>,
            <div key={`${x.operatingLicenceId}-operatingCentres`}>{x.operatingCentres}</div>,
            <div
                key={`${x.operatingLicenceId}-vehicles`}
                className={classNames({
                    "operating-licence-assets-over-limit": x.assignedVehicles > x.totalVehicles,
                })}
            >
                {x.assignedVehicles}/{x.totalVehicles}
            </div>,
            <div key={`${x.operatingLicenceId}-trailers`}>
                {x.assignedTrailers}/{x.totalTrailers}
            </div>,
            <div key={`${x.operatingLicenceId}-renewalDate`}>
                {<DateToLocal format='DD/MM/YYYY'>{x.licenceRenewalDate}</DateToLocal>}
            </div>,
            <div key={`${x.operatingLicenceId}-favourite`} className="operating-licence-icon-column">
                <Button
                    onClick={() => updateFavourite(index)}
                    icon={x.isFavourite ? "star" : "star-empty"}
                    iconOnly={true}
                    minimal={true}
                />
            </div>,

        ];

        if (canManageLicence) {
            tableRow.push(
                <div key={`${x.operatingLicenceId}-edit`} className="operating-licence-icon-column">
                    <Button
                        icon={"edit"}
                        iconOnly={true}
                        minimal={true}
                        onClick={() => onUpdateLicenceClick(x.operatingLicenceId)}
                    />
                </div>,
                <div key={`${x.operatingLicenceId}-delete`} className="operating-licence-icon-column">
                    <Button
                        icon={"trash"}
                        iconOnly={true}
                        minimal={true}
                        intent="danger"
                        onClick={() => onDeleteModalShow(x.operatingLicenceId)}
                    />
                </div>,
            );
        }

        return tableRow;

    });

    function onDeleteDataCancel() {
        setShowDeleteDataModal(false);
        setDeleteDataPhrase('');
        setSelectedRowId(null);
    }

    function onDeleteDataPhraseChange(event) {
		setDeleteDataPhrase(event.target.value);
    }

    function deleteRiskData() {

        setIsDeletingData(true);

        OperatingLicenceService.deleteLicence(selectedRowId).then(function() {
            NotificationToaster.show(Intent.SUCCESS, `Operating Licence deleted successfully`);
            setShowDeleteDataModal(false);
            fetchTableData();
        }, function() {
            NotificationToaster.show(Intent.DANGER, `Failed to delete the Operating Licence. Please try again.`);
        }).finally(function() {
            setIsDeletingData(false);
            setDeleteDataPhrase('');
        });
    }

    function onDeleteModalShow(operatingCentreId) {
        setShowDeleteDataModal(true);
        setSelectedRowId(operatingCentreId);
    }


    return (
        <UserAccess
            perform={requiredAction}
            yes={() => (
                <>
                    <div>
                        <Breadcrumbs items={props.breadcrumbs} />
                        <h1>Operating Licences</h1>
                        
                        <ShowHide 
                            evaluator={canManageLicence}
                            show={(
                                <div className="add-operating-licence">
                                    <div className="spacer-bottom inline-items add-operating-licence-button">
                                        <Button
                                            text="Add Licence"
                                            intent="primary"
                                            onClick={onAddLicenceClick}
                                        />
                                    </div>
                                    <ShowHide
                                        evaluator={!isLoading && dataRows.length === 0}
                                        show={
                                            <Card className="add-operating-licence-object-card">
                                                Please select the Add Licence button to get started
                                            </Card>
                                        }
                                    />
                                </div>
                            )}
                        />

                        <ListingTable
                            headers={columnHeaders}
                            pageable={false}
                            data={dataRows}
                            totalRecordCount={dataRows.length}
                            loadingData={isLoading}
                            noDataMessage={"No Operating Licences to display."}
                            rowClasses={operatingLicenceRowClasses}
                        >
                        </ListingTable>

                        <CreateOperatingLicenceModal
                            showModal={showAddOperatingLicenceModal}
                            onSaveModal={onSaveCreateModal}
                            onCloseModal={addLicenceModalClose}
                        />

                        <UpdateOperatingLicenceModal
                            showModal={showUpdateOperatingLicenceModal}
                            operatingLicenceId={operatingLicenceIdToUpdate}
                            onSaveModal={onSaveUpdateModal}
                            onCloseModal={updateLicenceModalClose}
                        />
                    </div>

                    <Modal
                        isOpen={showDeleteDataModal}
                        clickOutsideClose={false}
                        title={'Delete Operating Licence'}
                        onClose={onDeleteDataCancel}
                    >

                        <p>Are you sure you want to delete this operating licence and all associated data?</p>
                        <p>Enter the phrase 'confirm delete' to delete this operating licence</p>
                        <FormTextInput
                            value={deleteDataPhrase}
                            onChange={onDeleteDataPhraseChange}
                        />

                        <div className="bp3-dialog-footer">

                            <div className="bp3-dialog-footer-actions">
                                <Button
                                    text="Cancel"
                                    intent="none"
                                    disabled={isDeletingData}
                                    onClick={onDeleteDataCancel}
                                ></Button>
                                <ButtonSave
                                    id="btn-delete-operating-licence"
                                    onClick={deleteRiskData}
                                    intent="danger"
                                    icon="trash"
                                    simpleDisabled={deleteDataPhrase?.toLowerCase() !== requiredDeleteDataPhrase?.toLowerCase()}
                                    disabled={isDeletingData}
                                    text={'Delete'}
                                ></ButtonSave>
                            </div>
                        </div>


                    </Modal>

                </>
            )}
            no={() => <PageRestricted />}
        >
        </UserAccess>
    )
}
