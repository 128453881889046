import React, { useState, useEffect, Fragment } from "react";
import { UserAccess, UserHasAccess } from "components/user-access";
import { AlertUnsavedData, Modal, NotificationToaster } from "components/notifications";
import { PageRestricted } from "pages/errors/page-restricted";
import PropTypes from "prop-types";
import { useDirtyData } from 'hooks/useDirtyData';
import { Wizard } from "components/wizard";
import { FormDateInput, FormNumericInput, FormRadio, FormSelect, FormTextInput } from 'components/form-fields';
import axios from 'axios';
import { OperatingLicenceService } from "services";
import { Intent } from "@blueprintjs/core";
import { useValidation } from "hooks/useValidation";
import moment from 'moment';
import { ShowHide } from "components/layout";
import { GridLayout } from "components/grid";
import { Button } from "components/buttons";

export function OperatingLicenceModal(
    { 
        showModal, 
        modalTitle,
        showFinishOnAllSteps,
        requiredActions,
        onSave, 
        onCloseModal, 
        licenceDetails,
        loading,
        isUpdate
    }
) {
    const canAccessPage = UserHasAccess(requiredActions);
    const [loadingOptions, setLoadingOptions] = useState(true);
    const [saving, setSaving] = useState(false);

    const operatingLicenceModel = {
        licenceArea: null,
        licenceType: null,
        licenceNumber: '',
        licenceRenewal: null,
        earnedRecognitionOperator: true,
        ocrsRoadScore: null,
        ocrsTrafficScore: null,
        ocrsCombinedScore: null,
        earnedRecognitionNumber: '',
        vehicleInspectionFrequency: null,
        trailerInspectionFrequency: null
    };
    
    const [originalOperatingLicence, setOriginalOperatingLicence] = useState(operatingLicenceModel);
    const [operatingLicence, setOperatingLicence] = useState(originalOperatingLicence);
    const isOperatingLicenceDirty = useDirtyData(originalOperatingLicence, operatingLicence);
    const [isOperatingLicenceValid, operatingLicenceErrors, validateOperatingLicence] = useValidation();

    const [wizardStep, setWizardStep] = useState(0);
    const [wizardValid, setWizardValid] = useState(false);

    const [licenceAreas, setLicenceAreas] = useState([]);
    const [licenceTypes, setLicenceTypes] = useState([]);

    const [blockModalClose, setBlockModalClose] = useState(false);

    useEffect(initialLoad, [showModal]);

    function initialLoad() { 
        if (!canAccessPage || !showModal) {
            return;
        }

        // If we already have the licence areas and types, don't fetch them again
        if (licenceAreas !== null && licenceAreas.length > 0 && licenceTypes !== null && licenceTypes.length > 0) { 
            setLoadingOptions(false);
            return;
        }

        setLoadingOptions(true);

        axios.all([
            OperatingLicenceService.getOperatorLicenceOptions()
        ]).then(axios.spread(function (operatorLicenceOptionsResponse) {
            setLicenceAreas(operatorLicenceOptionsResponse.licenceAreas);
            setLicenceTypes(operatorLicenceOptionsResponse.licenceTypes);
        }), function () {
            NotificationToaster.show(Intent.DANGER, "Unable to load, please try again.");
        })
        .finally(() => {
            setLoadingOptions(false);
        });
    }

    useEffect(loadDetails, [licenceDetails]);

    function loadDetails() {
        if (!licenceDetails) {
            return;
        }

        const mappedLicenceDetails = {
            licenceArea: licenceDetails.licenceArea,
            licenceType: licenceDetails.licenceType,
            licenceNumber: licenceDetails.licenceNumber,
            licenceRenewal: licenceDetails.licenceRenewal,
            earnedRecognitionOperator: licenceDetails.earnedRecognitionOperator,
            ocrsRoadScore: licenceDetails.ocrs?.roadScore,
            ocrsTrafficScore: licenceDetails.ocrs?.trafficScore,
            ocrsCombinedScore: licenceDetails.ocrs?.combinedScore,
            earnedRecognitionNumber: licenceDetails.ocrs?.earnedRecognitionNumber,
            vehicleInspectionFrequency: licenceDetails.vehicleInspectionFrequency,
            trailerInspectionFrequency: licenceDetails.trailerInspectionFrequency
        }

        setOperatingLicence(mappedLicenceDetails);
        setOriginalOperatingLicence(mappedLicenceDetails); 
    }

    function onValueChange(newValue, field, controlType = "default") {
        let passedValue = newValue;
        
        if (controlType === "date") { 
            passedValue = newValue ? moment(newValue).format("YYYY-MM-DD") : null
        }

        if (controlType === "radio") {
            passedValue = newValue === "true"; 
        }

        passedValue = passedValue === "" ? null : passedValue;

        setOperatingLicence((prevValue) => {
            let clonedScores = { ...prevValue }
            clonedScores[field] = passedValue;
    
            return clonedScores;
        })
    }

    useEffect(validate, [operatingLicence, isOperatingLicenceDirty, isOperatingLicenceValid, wizardStep, loading])

    function validate() { 
        let rules = [
            { fieldName: 'licenceArea', required: true },
            { fieldName: 'licenceType', required: true },
            { fieldName: 'licenceNumber', required: true, minLength: 3 }
        ];

        if (wizardStep === 1) {
            rules.push({ fieldName: 'earnedRecognitionOperator', required: true });
            rules.push({ fieldName: 'ocrsRoadScore', minValue: 0 });
            rules.push({ fieldName: 'ocrsTrafficScore', minValue: 0 });
            rules.push({ fieldName: 'ocrsCombinedScore', minValue: 0 });
        }

        if (wizardStep === 2) { 
            rules.push({ fieldName: 'vehicleInspectionFrequency', minValue: 1 });
            rules.push({ fieldName: 'trailerInspectionFrequency', minValue: 1 });
        }

        validateOperatingLicence(rules, operatingLicence);
    }

    function onSaveClick() {
        // if we are not updating then you can only save if the modal is valid and dirty
        // if we are updating then we can only save if the modal is valid (saving is allowed on all steps)
        if (
            isUpdate && (!isOperatingLicenceValid || !isOperatingLicenceDirty) || 
            !isUpdate && !isOperatingLicenceValid
        ) {
            return;
        }

        setSaving(true);
    
        onSave(operatingLicence)
            .then(
                () => {
                    resetModal();
                    setSaving(false);
                }
            )
            .catch(() => {
                NotificationToaster.show(Intent.DANGER, 'Failed to save operating licence');
            })
            .finally(() => {
                setSaving(false);
            });
    }

    function onWizardChange(step) {
        setWizardStep(step);
    }

    useEffect(setIfWizardValid, [wizardStep, isOperatingLicenceValid, isOperatingLicenceDirty]);

    function setIfWizardValid() {
        var valid = isOperatingLicenceValid;

        setWizardValid(valid);
    }

    function showOcrsScoreFields() {
        return operatingLicence.earnedRecognitionOperator !== null 
            && operatingLicence.earnedRecognitionOperator !== undefined
            && !operatingLicence.earnedRecognitionOperator;
    }

    function showEarnedRecognitionNumber() {
        return operatingLicence.earnedRecognitionOperator !== null 
            && operatingLicence.earnedRecognitionOperator !== undefined
            && operatingLicence.earnedRecognitionOperator;
    }

    function getAdditionalButtons() {
        return (
            <Button 
                intent="secondary" 
                loading={loading || loadingOptions} 
                text="Close" 
                onClick={() => onCloseClick(false)} 
                disabled={saving || loading || loadingOptions} 
                id="close-btn"
            />
        );
    }

    function onCloseClick(forceClose = false) {
        if (isOperatingLicenceDirty && !forceClose) {
            setBlockModalClose(true);
            return;
        }

        resetModal();
        onCloseModal();
    }

    function resetModal() {
        setOperatingLicence(operatingLicenceModel);
        setOriginalOperatingLicence(operatingLicenceModel);
        setWizardStep(0);
        setBlockModalClose(false);
    }

    return (
        <Fragment>
            <Modal 
                isOpen={showModal}
                onClose={onCloseClick}
                clickOutsideClose={true}
                title={modalTitle}
                isCloseButtonShown={false}
                updateStateOnClose={false}
                className='operating-licence-modal'
            >
                <UserAccess 
                    perform={requiredActions}
                    yes={() => {
                        return (
                            <>
                                <Wizard 
                                    loading={loading || loadingOptions} 
                                    onStepChange={onWizardChange} 
                                    onFinish={onSaveClick}
                                    canProceed={wizardValid} 
                                    showFinishOnAllSteps={showFinishOnAllSteps}
                                    disabled={saving}
                                    alignButtonsRight={true}
                                    additionalButtonsStart={getAdditionalButtons()}
                                    finishButtonText="Save and Close"
                                >
                                    <div>
                                        <h2 id="header-licence-details">Licence Details</h2>
                                        
                                        <GridLayout numberOfColumns={2} className="spacer-bottom">
                                            
                                            <FormSelect
                                                disabled={saving || loading || loadingOptions}
                                                loading={loading || loadingOptions}
                                                items={licenceAreas}
                                                onItemSelect={(item) => onValueChange(item.id, "licenceArea")}
                                                placeholder="Please select"
                                                headingText="Licence area"
                                                selectedValue={operatingLicence.licenceArea}
                                                dangerHelperText={operatingLicenceErrors.licenceArea}
                                                id="input-licence-area"
                                            ></FormSelect>
                                            
                                            <FormSelect
                                                disabled={saving || loading || loadingOptions}
                                                loading={loading || loadingOptions}
                                                items={licenceTypes}
                                                onItemSelect={(item) => onValueChange(item.id, "licenceType")}
                                                placeholder="Please select"
                                                headingText="Licence type"
                                                selectedValue={operatingLicence.licenceType}
                                                dangerHelperText={operatingLicenceErrors.licenceType}
                                                id="input-licence-type"
                                            ></FormSelect>

                                            <FormTextInput
                                                id="input-licence-number"
                                                disabled={saving || loading || loadingOptions}
                                                loading={loading || loadingOptions}
                                                value={operatingLicence.licenceNumber}
                                                headingText="Licence number"
                                                onChange={(event) => onValueChange(event.target.value, "licenceNumber")}
                                                dangerHelperText={operatingLicenceErrors.licenceNumber}
                                            ></FormTextInput>

                                            <FormDateInput
                                                disabled={saving || loading || loadingOptions}
                                                loading={loading || loadingOptions}
                                                headingText="Licence renewal date"
                                                onChange={(event) => onValueChange(event, "licenceRenewal", "date")}
                                                value={operatingLicence.licenceRenewal}
                                                dangerHelperText={operatingLicenceErrors.licenceRenewal}
                                                id="input-licence-renewal"
                                            ></FormDateInput>

                                        </GridLayout>
                                    </div>

                                    <div>
                                        <h2 id="header-earned-recognition-and-ocrs">Earned Recognition and OCRS</h2>
                                        <div className="inline-items spacer-bottom">
                                            <FormRadio
                                                disabled={saving || loading || loadingOptions}
                                                loading={loading || loadingOptions}
                                                onChange={(event) => onValueChange(event.target.value, "earnedRecognitionOperator", "radio")}
                                                headingText="Are you an earned recognition operator?"
                                                selectedValue={operatingLicence.earnedRecognitionOperator}
                                                dangerHelperText={operatingLicenceErrors.earnedRecognitionOperator}
                                                id="input-earned-recognition-operator"
                                            ></FormRadio>
                                        </div>
                                        <div>
                                            <ShowHide
                                                evaluator={showOcrsScoreFields()}
                                                show={(
                                                    <GridLayout numberOfColumns={2} className="spacer-bottom">
                                                        <FormNumericInput
                                                            disabled={saving || loading || loadingOptions}
                                                            loading={loading || loadingOptions}
                                                            min={0} 
                                                            max={9999}
                                                            allowDecimal={true} 
                                                            decimalPlaces={2} 
                                                            selectedValue={operatingLicence.ocrsRoadScore}
                                                            onValueChange={(event) => onValueChange(event, "ocrsRoadScore")}
                                                            headingText={"Road worthiness score"}
                                                            dangerHelperText={operatingLicenceErrors.ocrsRoadScore}
                                                            id="input-road-score"
                                                        ></FormNumericInput>
                                                        <FormNumericInput
                                                            disabled={saving || loading || loadingOptions}
                                                            min={0} 
                                                            max={9999}
                                                            allowDecimal={true} 
                                                            decimalPlaces={2} 
                                                            selectedValue={operatingLicence.ocrsTrafficScore}
                                                            onValueChange={(event) => onValueChange(event, "ocrsTrafficScore")}
                                                            headingText={"Traffic score"}
                                                            loading={loading || loadingOptions}
                                                            dangerHelperText={operatingLicenceErrors.ocrsTrafficScore}
                                                            id="input-traffic-score"
                                                        ></FormNumericInput>
                                                        <FormNumericInput
                                                            disabled={saving || loading || loadingOptions}
                                                            loading={loading || loadingOptions}
                                                            min={0} 
                                                            max={9999}
                                                            allowDecimal={true} 
                                                            decimalPlaces={2} 
                                                            selectedValue={operatingLicence.ocrsCombinedScore}
                                                            onValueChange={(event) => onValueChange(event, "ocrsCombinedScore")}
                                                            headingText={"Combined score"}
                                                            dangerHelperText={operatingLicenceErrors.ocrsCombinedScore}
                                                            id="input-traffic-score"
                                                        ></FormNumericInput>
                                                    </GridLayout>
                                                )}
                                            >
                                            </ShowHide>
                                            <ShowHide
                                                evaluator={showEarnedRecognitionNumber()}
                                                show={(
                                                    <div className="spacer-bottom">
                                                        <FormTextInput
                                                            id="input-earned-recognition-number"
                                                            disabled={saving || loading || loadingOptions}
                                                            loading={loading || loadingOptions}
                                                            value={operatingLicence.earnedRecognitionNumber}
                                                            headingText="Earned recognition number"
                                                            onChange={(event) => onValueChange(event.target.value, "earnedRecognitionNumber")}
                                                            dangerHelperText={operatingLicenceErrors.earnedRecognitionNumber}
                                                        ></FormTextInput>
                                                    </div>
                                                )}
                                            >
                                            </ShowHide>
                                        </div>
                                    </div>

                                    <div>
                                        <h1 id="header-inspection-details">Inspection Details</h1>
                                        <GridLayout numberOfColumns={2} className="spacer-bottom">
                                            <FormNumericInput
                                                disabled={saving || loading || loadingOptions}
                                                min={1} 
                                                max={9999}
                                                allowDecimal={false}
                                                selectedValue={operatingLicence.vehicleInspectionFrequency}
                                                onValueChange={(event) => onValueChange(event, "vehicleInspectionFrequency")}
                                                headingText={"Vehicle inspection frequency (weeks)"}
                                                dangerHelperText={operatingLicenceErrors.vehicleInspectionFrequency}
                                                loading={loading || loadingOptions}
                                                id="input-vehicle-inspection-frequency"
                                            ></FormNumericInput>
                                            <FormNumericInput 
                                                disabled={saving || loading || loadingOptions}
                                                min={1} 
                                                max={9999}
                                                allowDecimal={false}
                                                selectedValue={operatingLicence.trailerInspectionFrequency}
                                                onValueChange={(event) => onValueChange(event, "trailerInspectionFrequency")}
                                                headingText={"Trailer inspection frequency (weeks)"}
                                                dangerHelperText={operatingLicenceErrors.trailerInspectionFrequency}
                                                loading={loading || loadingOptions}
                                                id="input-trailer-inspection-frequency"
                                            ></FormNumericInput>
                                        </GridLayout>
                                    </div>

                                </Wizard>
                            </>
                        )
                    }}
                    no={() => <PageRestricted/>}
                />

            </Modal>
            <AlertUnsavedData
                isDirty={isOperatingLicenceDirty}
                isDirtySamePage={blockModalClose}
                onCancelLeave={() => setBlockModalClose(false)}
                onConfirmLeave={() => onCloseClick(true)}
            />
        </Fragment>
    );
}

OperatingLicenceModal.defaultProps = {
    showFinishOnAllSteps: false,
    loading: true,
    isUpdate: false
};

OperatingLicenceModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    modalTitle: PropTypes.string.isRequired,
    showFinishOnAllSteps: PropTypes.bool,
    requiredActions: PropTypes.array.isRequired,
    onSave: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    licenceDetails: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    isUpdate: PropTypes.bool
};