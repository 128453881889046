import React from "react";
import PropTypes from "prop-types";
import { OperatingCentreModal } from "pages/operating-licences/modals/shared";
import { OperatingLicenceService } from "services";
import { NotificationToaster } from "components/notifications";
import { Intent } from "@blueprintjs/core";

export function CreateOperatingCentreModal(
    { 
        showModal, 
        onSaveModal, 
        onCloseModal,
        operatingLicenceId
    }
) {
    const requiredActions = ["OperatingLicence:Manage"];

    const operatingCentreModel = {
        name: "",
        address1: "",
        address2: "",
        address3: "",
        town: "",
        county: "",
        postcode: "",
        authorisedVehicles: null,
        authorisedTrailers: null,
        locations: []
    };

    function onSave(operatingCentre) {
        return OperatingLicenceService.createOperatingCentre(operatingCentre)
            .then(
                ({ data }) => {
                    NotificationToaster.show(Intent.SUCCESS, "Operating Centre created successfully.");
                    onSaveModal(data);
                },          
            )
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    return (
        <>
            <OperatingCentreModal
                showModal={showModal}
                modalTitle="New Operating Centre"
                operatingLicenceId={operatingLicenceId}
                requiredActions={requiredActions}
                onSave={onSave}
                loading={false}
                onCloseModal={onCloseModal}
                centreDetails={operatingCentreModel}
            />
        </>
    );
}

CreateOperatingCentreModal.defaultProps = {

};

CreateOperatingCentreModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    operatingLicenceId: PropTypes.number.isRequired,
    onSaveModal: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
};