import { Intent } from '@blueprintjs/core';
import { ButtonSave, Button } from 'components/buttons';
import { FormTextArea, FormDateInput } from "components/form-fields";
import { Modal, NotificationToaster, NotificationInline } from 'components/notifications';
import React, { useEffect, useState, Fragment, useRef } from 'react';
import { JobService } from 'services';
import { UserAccess } from 'components/user-access/UserAccess';
import { useValidation } from "hooks/useValidation";
import { PageRestricted } from 'pages/errors/page-restricted';
import { useDirtyData } from "hooks/useDirtyData";
import PropTypes from 'prop-types';
import moment from "moment";
import { ShowHide } from "components/layout";

const defaultFailAppointmentReason = {
    failureReason: "",
    failureAdditionalComments: "",
    completedDate: moment().format("YYYY-MM-DD")
};

export function FailVisit(props) {

    const [failAppointmentReason, setFailAppointmentReason] = useState(defaultFailAppointmentReason);
    const [originalFailAppointmentValue, setOriginalFailAppointmentValue] = useState(defaultFailAppointmentReason);
    const [isFailAppointmentValid, failAppointmentErrors, failAppointmentsValidate] = useValidation();
    const [showFailJobModal, setShowFailJobModal] = useState(false);
    const [showCreateCaseFailed, setShowCreateCaseFailed] = useState(false);
    const [savingFailedAppointment, setSavingFailedAppointment] = useState(false);
    const isDataDirty = useDirtyData(originalFailAppointmentValue, failAppointmentReason);
    const requiredAction = ["Internal"];
    const failVisitReasonRef = useRef(null);
    const rules = [
        { fieldName: "failureReason", required: true }
    ];

    useEffect(() => {
        if (props.failAppointmentReason !== null && props.failAppointmentReason !== undefined) {
            setOriginalFailAppointmentValue(props.failAppointmentReason);
            setFailAppointmentReason(props.failAppointmentReason);
        }
    }, [props.failAppointmentReason]);

    useEffect(() => {
        failAppointmentsValidate(rules, failAppointmentReason);
    }, [failAppointmentReason]);

    useEffect(() => {
        setShowFailJobModal(props.showFailedAppointment);
        if (props.showFailedAppointment) {
            setTimeout(function () {
                if (failVisitReasonRef.current != null) {
                    failVisitReasonRef.current.focus();
                    failVisitReasonRef.current.selectionStart = failVisitReasonRef.current.selectionEnd - 1;
                }
            });
        }
    }, [props.showFailedAppointment]);

    function onFailAppointmentReasonChange(event) {
        setFailAppointmentReason({
            ...failAppointmentReason,
            failureReason: event.target.value
        });
    }
    function onFailAppointmentCommentChange(event) {
        setFailAppointmentReason({
            ...failAppointmentReason,
            failureAdditionalComments: event.target.value
        });
    }

    function onFailVisitSave(failAppointmentReasonToReturn) {
        if (props.onFailVisitSave !== null) {
            props.onFailVisitSave(failAppointmentReasonToReturn);
        };
    }

    function onFailVisitClose(isCancel, caseFailed) {
        if (props.onFailVisitClose !== null) {
            props.onFailVisitClose(!caseFailed);
        };
        if (isCancel) {
            setFailAppointmentReason(originalFailAppointmentValue);
        }
        setShowFailJobModal(caseFailed);
    }

    function onFailedDateChange(item) {
        setFailAppointmentReason({
            ...failAppointmentReason,
            completedDate: item ? moment(item).format("YYYY-MM-DD") : null
        });
    }

    function onCaseCreateFailedClose() {
        setOriginalFailAppointmentValue(failAppointmentReason);
        onFailVisitSave(failAppointmentReason);
        setShowCreateCaseFailed(false);
        onFailVisitClose(true);
    }

    function onFailJobConfirm() {
        if (!isFailAppointmentValid) {
            return;
        }

        var failedCase = false;
        setSavingFailedAppointment(true);
        JobService.failAppointment(props.jobId, failAppointmentReason).then(function () {
            setOriginalFailAppointmentValue(failAppointmentReason);
            NotificationToaster.show(Intent.SUCCESS, `The Appointment has been ${props.isFailed ? "failed" : "saved"} successfully.`);
            onFailVisitSave(failAppointmentReason);
        }, function (error) {
            if (error === "Failed Appointment OK. Dynamic Case Failed.") {
                setShowCreateCaseFailed(true);
                failedCase = true;
            }
            else {
                NotificationToaster.show(Intent.DANGER, error);
            }

        }).finally(function () {
            setSavingFailedAppointment(false);
            onFailVisitClose(false, failedCase);
        });
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (
                <Fragment>
                    <ShowHide
                        evaluator={!showCreateCaseFailed}
                        show={(
                            <Modal
                                isOpen={showFailJobModal}
                                title="Are you sure you want to fail this appointment?"
                                showCloseButton={false}
                                clickOutsideClose={false}
                            >
                                <div>
                                    <NotificationInline
                                        allowClose={false}
                                        show
                                        text="Please note, once you fail this appointment, the action cannot be reversed."
                                        intent={Intent.WARNING}
                                    />
                                    <NotificationInline
                                        allowClose={false}
                                        show={showCreateCaseFailed}
                                        text="The failed appointment was saved. The dynamics case was not created. Please contact IT support."
                                        intent={Intent.WARNING}
                                    />
                                    <FormDateInput
                                        id="fail-appointment-date"
                                        headingText="Failed Date:"
                                        onChange={onFailedDateChange}
                                        value={failAppointmentReason.completedDate}
                                        minDate={moment("1900-01-01").toDate()}
                                        maxDate={moment().toDate()}
                                        dangerHelperText={failAppointmentErrors.completedDate}
                                    />
                                    <FormTextArea
                                        headingText="Reason for failing appointment:"
                                        value={failAppointmentReason.failureReason}
                                        onChange={onFailAppointmentReasonChange}
                                        dangerHelperText={failAppointmentErrors.failureReason}
                                        id="input-fail-appointment-reason"
                                        inputRef={failVisitReasonRef}
                                    />
                                    <FormTextArea
                                        headingText="Additional comments:"
                                        value={failAppointmentReason.failureAdditionalComments}
                                        onChange={onFailAppointmentCommentChange}
                                        id="input-fail-appointment-comments"
                                    />
                                </div>
                                <div className="bp3-dialog-footer">
                                    <div className="bp3-dialog-footer-actions">
                                        <ButtonSave text={(props.jobFailed ? "Save" : "Fail appointment")} icon={null} intent={(props.jobFailed ? Intent.PRIMARY : Intent.DANGER)} onClick={onFailJobConfirm} simpleDisabled={!isFailAppointmentValid && isDataDirty} disabled={savingFailedAppointment}></ButtonSave>
                                        <Button text="Cancel" onClick={() => { onFailVisitClose(true); }} disabled={savingFailedAppointment}></Button>
                                    </div>
                                </div>
                            </Modal>
                        )}
                    />
                    <ShowHide
                        evaluator={showCreateCaseFailed}
                        show={(
                                <Modal
                                    isOpen={showFailJobModal}
                                    title="Failed Appointment warning."
                                    showCloseButton={false}
                                    clickOutsideClose={false}
                                >
                                    <NotificationInline
                                        allowClose={false}
                                        show={showCreateCaseFailed}
                                    text="The failed appointment was saved. However the dynamics case could not be created. Please create the case in dynamics, and contact IT support to see why the case could not be created."
                                        intent={Intent.WARNING}
                                    />
                                    <Button text="Close" onClick={() => { onCaseCreateFailedClose(); }}></Button>
                                </Modal>
                        )}
                    />
                </Fragment>
            )}
            no={() => (
                <PageRestricted />
            )}
        />

    );
}

FailVisit.defaultProps = {
    jobFailed: false,
    failAppointmentReason: defaultFailAppointmentReason,
    addressSettings: null,
    loading: false,
    onFailVisitClose: null,
    onFailVisitSave: null,
    showFailedAppointment: false,
    isFailed: false
}

FailVisit.propTypes = {
    jobFailed: PropTypes.bool.isRequired,
    failAppointmentReason: PropTypes.object,
    jobId: PropTypes.number,
    loading: PropTypes.bool.isRequired,
    onFailVisitClose: PropTypes.func,
    onFailVisitSave: PropTypes.func,
    showFailedAppointment: PropTypes.bool.isRequired,
    isFailed: PropTypes.bool.isRequired
};