import React from 'react';
import classNames from 'classnames';
import { InputGroup, Spinner } from "@blueprintjs/core";
import PropTypes from 'prop-types';
import { ShowHide } from "components/layout";

import { FormHelperText } from "components/form-fields";


export function FormTextInput(props) {

    return (
        <div className="form-field">
            <ShowHide
                evaluator={props.headingText != null}
                show={(
                    <h4 className={classNames({ "bp3-skeleton": props.loading })}>{props.headingText}</h4>
                )}
            />
            <FormHelperText loading={props.loading}>{props.helperText}</FormHelperText>
            <div className={classNames({ "inline-items": props.showLoader })}>
                <InputGroup
                    id={props.id}
                    className={classNames({ "bp3-skeleton": props.loading, "wide": props.wide, "really-wide": props.reallyWide, "ultra-wide": props.ultraWide, "fill": props.fill })}
                    disabled={props.disabled}
                    readOnly={props.onChange == null}
                    value={props.value}
                    onChange={props.onChange}
                    placeholder={props.placeholder}
                    large={props.large}
                    leftIcon={props.icon}
                    inputRef={props.inputRef}
                    type={props.type}
                    onKeyPress={props.onKeyPress}
                    minLength={props.minLength}
                    maxLength={props.maxLength}
                    autoComplete="off"
                />
                <ShowHide
                    evaluator={props.showLoader}
                    show={(
                        <div className="save-loader">
                            <Spinner size="25" />
                        </div>
                    )}
                />

            </div>
            <FormHelperText loading={props.loading} danger={true}>{props.dangerHelperText}</FormHelperText>
        </div>
    );
}

FormTextInput.defaultProps = {
    id: null,
    value: "",
    loading: false,
    headingText: null,
    helperText: null,
    disabled: false,
    dangerHelperText: null,
    placeholder: "",
    large: false,
    icon: null,
    type: "text",
    minLength: null,
    maxLength: null,
    showLoader: false,
    wide: false,
    reallyWide: false,
    ultraWide: false,
    fill: false
};

FormTextInput.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    loading: PropTypes.bool,
    headingText: PropTypes.string,
    helperText: PropTypes.node,
    disabled: PropTypes.bool,
    dangerHelperText: PropTypes.node,
    placeholder: PropTypes.string,
    large: PropTypes.bool,
    icon: PropTypes.string,
    inputRef: PropTypes.object,
    type: PropTypes.string,
    onKeyPress: PropTypes.func,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    showLoader: PropTypes.bool,
    wide: PropTypes.bool,
    reallyWide: PropTypes.bool,
    ultraWide: PropTypes.bool,
    fill: PropTypes.bool
};