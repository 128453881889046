import { Intent } from '@blueprintjs/core';
import { Button, ButtonSave, LinkButton } from 'components/buttons';
import { FormSuggest, FormDateInput, FormLocationSelector } from 'components/form-fields';
import { AlertUnsavedData, NotificationToaster, NotificationInline } from 'components/notifications';
import { useDirtyData } from 'hooks/useDirtyData';
import React, { useEffect, useState, Fragment } from 'react';
import { JobService, UserService, AssetService } from 'services';
import { UserAccess, UserHasAccess } from 'components/user-access/UserAccess';
import { DataDisplayTable } from "components/data-display";
import axios from 'axios';
import moment from "moment";
import { useValidation } from "hooks/useValidation";
import { ShowHide } from "components/layout";
import { PageRestricted } from 'pages/errors/page-restricted';
import { FailVisit, CancelJob } from 'pages/job/edit/shared';
import { HelperFunctions } from 'helpers';
import { DateToLocal } from "components/dates";

export function JobDetails(props) {
    const defaultJobDetails = {
        jobId: null,
        state: null,
        productName: null,
        productDescription: null,
        intendedAssetId: null,
        assetType: null,
        locationId: null,
        dueDate: null,
        scheduledDate: null,
        flsStatus: null,
        jobNote: null,
        jobProductBusinessAreaId: null,
        completed: null
    };

    const [isValid, errors, validate] = useValidation();
    const [jobDetails, setJobDetails] = useState(defaultJobDetails);
    const [originalJobDetails, setOriginalJobDetails] = useState(defaultJobDetails);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [locationList, setLocationList] = useState({ location: [], hierarchy: [] });
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [assets, setAssets] = useState([]);
    const [assetSearch, setAssetSearch] = useState('');
    const isDataDirty = useDirtyData(originalJobDetails, jobDetails);
    const [canSave, setCanSave] = useState(false);
    const [jobIsFailed, setJobIsFailed] = useState(false);
    const [jobIsCancelled, setJobIsCancelled] = useState(false);
    const [jobCanBeFailed, setJobCanBeFailed] = useState(false);
    const [jobCanBeClosed, setJobCanBeClosed] = useState(false);
    const [jobCanBeCancelled, setJobCanBeCancelled] = useState(false);
    const [amendmentRequest, setAmendmentRequest] = useState(null);
    const monthsInPastDefault = 240;
    const minDateDefault = moment().subtract(monthsInPastDefault, 'months').toDate();
    const [minIntendedDate, setMinIntendedDate] = useState(minDateDefault);
    const [showLocationsAsHierarachy, setShowLocationsAsHierarachy] = useState(true);
    const [headerSections, setHeaderSections] = useState([]);
    const [showFailedVisit, setShowFailedVisit] = useState(false);
    const [failAppointmentReason, setFailAppointmentReason] = useState(null);
    const isInternal = UserHasAccess("Internal");
    const isInternalJobEditor = UserHasAccess("Internal:Jobeditor");
    const failedAppointementState = "Failed Appointment";
    const jobCancelledState = "Cancelled";
    const jobTachoProductBusinessAreaId = 1;
    const requiredAction = ["ScheduledAppointment:View", "Internal"];
    const [closeButtonText, setCloseButtonText] = useState("Complete Job");
    const [activating, setActivating] = useState(false);
    const [canActivate, setCanActivate] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);

    useEffect(initialLoad, [props.job, props.hasTachoContact]);


    function setHeaders(jobFailed, failureReason, failureAdditionalComments, amendment, completedDate) {
        var headers = [
            {
                header: isInternal ? "Product Name" : "Product Details",
                value: isInternal ? props.job.productName : props.job.productDescription,
                key: 'product-type'
            },
            {
                header: isInternal && props.job.pubsJobId != null ? "Reference / PUBS ID" : "Reference",
                value: isInternal && props.job.pubsJobId != null ? props.job.jobId + " / " + props.job.pubsJobId : props.job.jobId,
                key: 'jod-id'
            },
            {
                header: "Job Status",
                value: props.job.state,
                key: 'job-status'
            },
            {
                header: "Business Area",
                value: props.job.jobProductBusinessArea,
                key: 'job-business-area'
            },
            {
                header: "Schedule Date",
                value: <DateToLocal format="DD/MM/YYYY HH:mm">
                    {props.job.scheduledDate}
                </DateToLocal>,
                key: 'scheduled-date'
            }
        ];

        if (completedDate !== null && completedDate !== undefined) {
            headers.push({
                header: jobFailed ? "Failed Date" : "Completed Date",
                value: <DateToLocal hideTimeIfMidnight={true} format="DD/MM/YYYY HH:mm">
                    {completedDate}
                </DateToLocal>,
                key: 'completed-date'
            });
        };

        if (failureReason !== null && failureReason !== undefined && jobFailed) {
            headers.push({
                header: "Failure Reason",
                value: failureReason,
                key: 'failure-reason'
            },
            {
                header: "Failure Additional Comments",
                value: failureAdditionalComments,
                key: 'failure-additional-comments'
            });
        }

        if (amendment) {
            var amendByText = "Amendment By";
            var amendDateText = "Amendment Date";
            var rescheduleDateText = "Requested Re-schedule Date";

            if (props.job.amendmentType === "Cancellation") {
                amendByText = "Cancellation By";
                amendDateText = "Cancellation Date";
            } else {
                headers.unshift({
                    header: rescheduleDateText,
                    value: props.job.rescheduleDate !== null ? moment(props.job.rescheduleDate).format("DD/MM/YYYY") : '',
                    key: 'job-reschedule-date'
                });
            }

            headers.unshift({
                header: amendByText,
                value: props.job.amendmentBy,
                key: 'job-amendment-by'
            });
            headers.unshift({
                header: amendDateText,
                value: props.job.amendmentDate !== null ? moment(props.job.amendmentDate).format("DD/MM/YYYY") : '',
                key: 'job-amendment-date'
            });
        }

        setHeaderSections(headers);
    };

    function initialLoad() {

        if (props.job?.jobId === null || props.job?.jobId === undefined || props.hasTachoContact === null) {
            return;
        }
        const loadDataPromises = [];

        var locationPermissionsResponse = null;
        var completedVisit = null;
        var assetResponse = null;

        const getUserLocations = UserService.getLoggedInUserLocations(1, false, true)
            .then(response => locationPermissionsResponse = response);
        loadDataPromises.push(getUserLocations);

        if (props.job.state === failedAppointementState || props.job.state === "Done") {
            const getCompletedVisit = JobService.getCompletedVisit(props.job.jobId)
                .then(response => completedVisit = response);
            loadDataPromises.push(getCompletedVisit);
        }

        if (props.job.intendedAssetId) {
            const getAssets = AssetService.getAsset(props.job.intendedAssetId)
                .then(response => assetResponse = response);
            loadDataPromises.push(getAssets);
        }

        axios.all(loadDataPromises).then(function () {

            var cbText = "Complete Job";
            if (!isComplianceReview(props.job) && completedVisit && completedVisit.jobVisitCompletionId !== 0 && props.job.completed) {

                cbText = "View Completed Job";

                if (isInternalJobEditor) {
                    cbText = "Edit Completed Job"
                }

                setCloseButtonText(cbText);
            }

            searchAsset(assetResponse !== null ? assetResponse?.registration : '');
            setJobDetails(props.job);
            setOriginalJobDetails(props.job);
            setLocationList(locationPermissionsResponse);
            setSelectedLocations([props.job.locationId]);
            setCanSave(props.job.jobProductBusinessAreaId === jobTachoProductBusinessAreaId && isInternal && !props.job.completed && props.job.scheduledDate === null);
            setJobIsFailed(props.job.state === failedAppointementState);
            setJobIsCancelled(props.job.state === jobCancelledState);
            setJobCanBeFailed(props.job.jobProductBusinessAreaId === jobTachoProductBusinessAreaId &&
                !props.job.completed && props.job.state !== "Pending" &&
                !isComplianceReview(props.job));
            setJobCanBeCancelled(!props.job.completed
                && !props.job.contractActive
                && props.job.contractLineDeleted
                && props.job.flsStatus !== null
                && props.job.flsStatus.toUpperCase() === "RECORDED");

            setJobCanBeClosed(cbText !== "Complete Job" ||
                (!props.job.completed && props.job.jobProductBusinessArea === "Tacho" &&
                    !isComplianceReview(props.job) && props.job.state !== "Pending"));
            setFailAppointmentReason({
                failureReason: props.job.failureReason ?? "",
                failureAdditionalComments: props.job.failureAdditionalComments ?? "",
                completedDate: props.job.completedDate ?? moment().format("YYYY-MM-DD")
            });

            setCanActivate(getCanActivate(props.job))
            setAmendmentRequest(getAmendment(props.job));
            setHeaders(props.job.state === failedAppointementState, props.job.failureReason, props.job.failureAdditionalComments, getAmendment(props.job), props.job.completedDate);
            setLoading(false);
        }, () => {
            NotificationToaster.show(Intent.DANGER, "Could not get job details. Please refresh the page.");
        });
    }

    useEffect(() => {
        if (jobDetails.jobId === null) {
            return;
        }
        var minDate = moment().toDate();
        if (jobDetails.dueDate !== null) {
            var dueDate = moment(jobDetails.dueDate).toDate();
            if (dueDate < minDate) {
                minDate = dueDate;
            }
        }
        setMinIntendedDate(canSave ? minDate : minDateDefault);
        validateModel();
    }, [canSave, jobDetails]);

    function getAmendment(job) {
        return job.amendmentType != null && (job.amendmentType === "Amendment" || job.amendmentType === "Cancellation");
    }

    function getCanActivate(job) {
        return props.hasTachoContact &&
            moment(job.dueDate).toDate() >= moment().toDate() &&
            job.state === 'Pending'
    }

    function saveJobDetails() {
        setSaving(true);

        JobService.saveJobDetails(jobDetails)
            .then(
                () => {
                    var changedLocation = jobDetails.locationId !== originalJobDetails.locationId;

                    setOriginalJobDetails(jobDetails);
                    NotificationToaster.show(Intent.SUCCESS, "job details updated successfully.");
                    setSaving(false);
                    setCanActivate(getCanActivate(jobDetails))

                    if (changedLocation) {
                        window.location.href = `/job/${jobDetails.jobId}/job-details`;
                    }
                    else {
                        HelperFunctions.executeIfNotNull(props.onSave, jobDetails);
                    }
                },
                () => {
                    NotificationToaster.show(Intent.DANGER, "Could not save job details. Please try again.");
                    setSaving(false);
                },
            );
    }

    function activateJob() {
        setActivating(true);

        var jobIds = [jobDetails.jobId];
        JobService.activateJobs(jobIds)
            .then(
                () => {

                    var clonedJobDetails = {
                        ...jobDetails,
                        state: 'New'
                    };

                    setJobDetails(clonedJobDetails);

                    setOriginalJobDetails(clonedJobDetails);
                    setHeaders(clonedJobDetails.state === failedAppointementState, clonedJobDetails.failureReason, clonedJobDetails.failureAdditionalComments, getAmendment(clonedJobDetails), clonedJobDetails.completedDate);
                    NotificationToaster.show(Intent.SUCCESS, "job activated successfully.");
                    setActivating(false);
                    setCanActivate(getCanActivate(clonedJobDetails));
                    HelperFunctions.executeIfNotNull(props.onSave, clonedJobDetails);
                },
                () => {
                    NotificationToaster.show(Intent.DANGER, "Could not activate the job. Please try again.");
                    setActivating(false);
                },
            );
    }

    function isComplianceReview(job) {
        return (job.productCode.toUpperCase().startsWith("TA064") || job.productName.toUpperCase().includes("COMPLIANCE REVIEW"))
    }
    function onDueDateChange(item) {
        setJobDetails((previousValue) => {
            return {
                ...previousValue,
                dueDate: item ? moment(item).format("YYYY-MM-DD") + ' 00:00:00' : null
            }
        });
    }

    function onLocationSelect(locations) {

        var lastLocation = locations.length > 0 ? locations.pop() : null;

        setJobDetails((previousValue) => {
            return {
                ...previousValue,
                locationId: lastLocation
            }
        });

        setSelectedLocations(lastLocation == null ? [] : [lastLocation]);
    }

    function onRegistationChange(item) {
        setJobDetails((previousValue) => {
            return {
                ...previousValue,
                intendedAssetId: item.id
            }
        });
    }

    function setUseHierarchy() {
        setShowLocationsAsHierarachy(previous => !previous);
    }

    function validateModel() {
        let rules = [{ fieldName: "locationId", required: true }];

        if (isInternal) {
            rules.push({ fieldName: "dueDate", required: true, });
        }

        validate(rules, jobDetails);
    }

    function formatAssets(assetList) {
        return assetList.map(asset => {
            return {
                id: asset.assetId,
                name: asset.assetRegistration
            };
        });
    }

    function searchAsset(searchText) {
        const assetsRequest = {
            pageNumber: 1,
            searchTerm: searchText,
            filters: []
        };

        AssetService.searchAssets(assetsRequest).then(function (assetResponse) {
            setAssets(formatAssets(assetResponse.data));
        });
    }

    function openCancelModal(e) {
        setShowCancelModal(true);
    }

    function closeCancelModal() {
        setShowCancelModal(false);
    }

    useEffect(function () {
        //On inital load isPostCodeValid is set to true, so we'll add the length check
        if (assetSearch.length > 0) {
            searchAsset(assetSearch);
        }
    }, [assetSearch])

    function onAssetSearchTextChange(event) {
        setAssetSearch(event);
    }

    function onFailVisit() {
        setShowFailedVisit(true);
    }

    function onFailVisitClose(closeModal) {
        setShowFailedVisit(!closeModal);
    }

    function onFailVisitSave(newFailAppointmentReason) {
        if (newFailAppointmentReason != null) {
            setJobIsFailed(true);
            setFailAppointmentReason(newFailAppointmentReason);
            var clonedJobDetails = {
                ...jobDetails,
                state: failedAppointementState,
                failureReason: newFailAppointmentReason.failureReason,
                failureAdditionalComments: newFailAppointmentReason.failureAdditionalComments,
                completedDate: newFailAppointmentReason.completedDate,
                completed: true
            };

            setJobDetails(clonedJobDetails);

            setOriginalJobDetails(clonedJobDetails);
            setHeaders(true, newFailAppointmentReason.failureReason, newFailAppointmentReason.failureAdditionalComments, getAmendment(jobDetails), clonedJobDetails.completedDate);
            HelperFunctions.executeIfNotNull(props.onSave, clonedJobDetails);

        }
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (
                <Fragment>
                    <div className="row">
                        <h2>Job Details</h2>
                        <ShowHide
                            evaluator={amendmentRequest !== null && amendmentRequest}
                            show={(
                                <NotificationInline show={true} allowClose={false} intent="warning" text={props.job.amendmentType === "Amendment" ? "Re-schedule requested" : "Cancellation requested"} />
                            )}
                        />
                        <ShowHide
                            evaluator={jobCanBeCancelled && !jobIsCancelled}
                            show={(
                                <NotificationInline show={true} allowClose={false} intent="warning" text="This job can be cancelled" />
                            )}
                        />
                        <ShowHide
                            evaluator={jobIsCancelled}
                            show={(
                                <NotificationInline show={true} allowClose={false} intent="info" text="This job has been cancelled" />
                            )}
                        />
                        

                        <DataDisplayTable
                            data={headerSections}
                            displayInRows={false}
                            loading={loading}
                        />
                        <UserAccess perform={["Internal"]}
                            yes={() => (
                                <Fragment>
                                    <FormDateInput
                                        id="field-asset-start-date"
                                        disabled={saving || !canSave}
                                        headingText="Due date:"
                                        onChange={onDueDateChange}
                                        value={jobDetails.dueDate}
                                        loading={loading}
                                        dangerHelperText={errors.dueDate}
                                        minDate={minIntendedDate}
                                    ></FormDateInput>
                                </Fragment>
                            )}
                        />
                        <FormLocationSelector
                            headingText={`Visit Location:`}
                            dangerHelperText={errors["location"]}
                            businessArea={"Tacho"}
                            loading={loading}
                            selectedLocations={selectedLocations}
                            setSelectedLocations={onLocationSelect}
                            useHierarchy={showLocationsAsHierarachy}
                            setUseHierarchy={setUseHierarchy}
                            useLocationDefaults={false}
                            useCustomLocations
                            customLocations={locationList}
                            locationDefaultsToTrue
                            singleSelection
                            disabled={saving || !canSave}
                        />
                        <ShowHide
                            evaluator={jobDetails.jobProductBusinessAreaId === 2}
                            show=
                            {
                                <FormSuggest
                                    onQueryChange={onAssetSearchTextChange}
                                    items={assets}
                                    selectedValue={jobDetails.intendedAssetId}
                                    loading={loading}
                                    onItemSelect={onRegistationChange}
                                    headingText="Registration"
                                    dangerHelperText={errors.intendedAssetId}
                                    disabled={saving || !canSave}
                                    clearSelection={false}
                                />
                            }
                        >
                        </ShowHide>

                        <AlertUnsavedData
                            isDirty={isDataDirty}>
                        </AlertUnsavedData>
                        <ShowHide
                            evaluator={canSave}
                            show=
                            {
                                <Fragment>
                                    <div className="inline-item">
                                        <ButtonSave
                                            onClick={saveJobDetails}
                                            disabled={saving}
                                            simpleDisabled={!isValid}
                                            loading={loading}
                                        ></ButtonSave>
                                    </div>
                                </Fragment>
                            }
                        >
                        </ShowHide>
                        <ShowHide
                            evaluator={jobCanBeFailed || (jobIsFailed && isInternalJobEditor)}
                            show=
                            {
                                <div className="inline-item">
                                    <Button onClick={onFailVisit}
                                        id="button-edit-failed-visit"
                                        text={(jobIsFailed ? "Edit Failed Visit" : "Fail Visit")}
                                        intent={(jobIsFailed ? Intent.PRIMARY : Intent.DANGER)}
                                        disabled={isDataDirty}
                                        loading={loading}
                                    ></Button>
                                </div>
                            }
                        >
                        </ShowHide>

                        <ShowHide
                            evaluator={!jobIsFailed && (jobCanBeClosed || (jobDetails.complete && isInternalJobEditor))}
                            show=
                            {
                                <div className="inline-item">
                                    <LinkButton text={closeButtonText} intent="primary" href={`/job/${jobDetails.jobId}/close-job`} disabled={isDataDirty} loading={loading} />
                                </div>
                            }
                        >
                        </ShowHide>
                        <ShowHide
                            evaluator={canSave && canActivate}
                            show=
                            {
                                <div className="inline-item">
                                    <ButtonSave
                                        onClick={activateJob}
                                        disabled={activating}
                                        simpleDisabled={isDataDirty}
                                        loading={loading}
                                        text="Activate"
                                    ></ButtonSave>
                                </div>

                            }
                        >
                        </ShowHide>

                        <ShowHide
                            evaluator={jobCanBeCancelled && !jobIsCancelled}
                            show=
                            {
                                <div className="inline-item">
                                    <Button intent="primary" text="Cancel Job" id="cancel-job" onClick={openCancelModal} disabled={loading} />
                                </div>
                            }
                        >
                        </ShowHide>
                        

                    </div>
                    
                    <ShowHide
                        evaluator={(jobDetails.jobProductBusinessArea === "Tacho" && !isComplianceReview(jobDetails))}
                        show=
                        {
                            <FailVisit
                                jobFailed={jobIsFailed}
                                failAppointmentReason={failAppointmentReason}
                                jobId={jobDetails.jobId}
                                showFailedAppointment={showFailedVisit}
                                loading={loading}
                                onFailVisitClose={onFailVisitClose}
                                onFailVisitSave={onFailVisitSave}
                                isFailed={true}
                            />
                        }
                    >
                    </ShowHide>

                    <ShowHide
                        evaluator={jobCanBeCancelled && !jobIsCancelled}
                        show=
                        {
                            <CancelJob
                                showCancelModal={showCancelModal}
                                closeCancelModal={closeCancelModal}
                                job={jobDetails.job}
                                loading={loading}
                            />
                        }
                    >
                    </ShowHide>

                    
                    
                </Fragment>

            )}
            no={() => (
                <PageRestricted />
            )}
        />

    );
}
