import ApiService from 'services/ApiService'

const badRequestCode = 400;

export const OperatingLicenceService = {

	getOptions() {
		return new Promise((resolve, reject) => {
			ApiService.get('/asset/options/operatorlicence').then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getLicences() {
		return new Promise((resolve, reject) => {
			ApiService.get('/asset/OperatingLicences').then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	setFavourite(id) {
		return new Promise((resolve, reject) => {
			ApiService.post(`/asset/OperatingLicences/${id}/favourite`).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					if (error.response.status === badRequestCode) {
						reject(error.response);
					} else {
						reject("Unable to favourite licence. Please try again later.");
					}
				}
			)
		})
	},
	createOperatingLicence(operatingLicence) {
		return new Promise((resolve, reject) => {
			ApiService.post(`/asset/OperatingLicences`, operatingLicence).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					if (error.response.status === badRequestCode) {
						reject(error.response);
					} else {
						reject("Unable to create licence. Please try again later.");
					}
				}
			)
		})
	},
	getOperatorLicenceOptions() {
		return new Promise((resolve, reject) => {
			ApiService.get('/asset/Options/OperatorLicence').then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getLicence(licenceId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/asset/OperatingLicences/${licenceId}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					if (error.response.status === badRequestCode) {
						reject(error.response);
					} else {
						reject("Unable to fetch licence. Please try again later.");
					}
				});
		});
	},
	deleteLicence(licenceId) {
		return new Promise((resolve, reject) => {
			ApiService.delete(`/asset/OperatingLicences/${licenceId}`).then(
				(result) => {
					resolve();
				},
				(error) => {
					reject(error);
				});
		});
	},
	updateOperatingLicence(licenceId, licenceDetails) {
		return new Promise((resolve, reject) => {
			ApiService.put(`/asset/OperatingLicences/${licenceId}`, licenceDetails).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error.response);
				});
		});
	},
	getMapMarkers(licenceId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/asset/OperatingLicences/${licenceId}/MapMarkers`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getVehicleList(licenceId, pageNumber, pageSize, searchTerm, filters, assetType, locations) {

		let mappedFilters = filters.map((filter) => {
			return {"key": filter.name, "value": filter.value};
		})

		mappedFilters = mappedFilters.concat(locations.map((location) => {
			return {"key": "LOCATION", "value": location};
		}));

		if(assetType === "trailers") {
			mappedFilters = mappedFilters.filter(x => x.key !== "ASSETTYPE");
		}

		return new Promise((resolve, reject) => {
			ApiService.post(`/asset/OperatingLicences/${licenceId}/${assetType}`, {
				"requestCount": pageSize,
				"pageNumber": pageNumber,
				"searchTerm": searchTerm,
				"sortField": "Registration",
				"sortDirection": "A",
				"filters": mappedFilters
			}).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					if (error.response.status === badRequestCode) {
						reject(error.response);
					} else {
						reject("Unable to fetch vehicle list. Please try again later.");
					}
				}
			)
		})
	},
	getOperatingCentre(centreId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/asset/OperatingCentres/${centreId}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	createOperatingCentre(operatingCentre) {
		return new Promise((resolve, reject) => {
			ApiService.post(`/asset/OperatingCentres`, operatingCentre).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					if (error.response.status === badRequestCode) {
						reject(error.response.data);
					} else {
						reject("Unable to create operating centre. Please try again later.");
					}
				}
			)
		})
	},
	updateOperatingCentre(centreId, centreDetails) {
		return new Promise((resolve, reject) => {
			ApiService.put(`/asset/OperatingCentres/${centreId}`, centreDetails).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error.response);
				});
		});
	},
	getAvailableLocationsForOperatingCentres(operatingCentreId) {
		let url = operatingCentreId !== null && operatingCentreId !== undefined 
			? `/asset/OperatingCentres/AvailableLocations?operatingCentreId=${operatingCentreId}`
			: `/asset/OperatingCentres/AvailableLocations`;

		return new Promise((resolve, reject) => {
			ApiService.get(url).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	}
}