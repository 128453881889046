import { Card } from "@blueprintjs/core";
import { Modal } from "components/notifications";
import OperatingLicenceMap from "./OperatingLicenceMap";
import './OperatingLicenceMap.css';
import PropTypes from "prop-types";

export function OperatingLicenceMapModal(props) {
    return (
        <Modal
            className="operating-licence-map-modal"
            isOpen={props.showMapModal}
            fullScreen={true}
            onClose={props.handleCloseMapModal}
        >
            <div className="operating-licence-map-container">
                <OperatingLicenceMap
                    width="100%"
                    height="100%"
                    operatingLicenceArea={props.currentLicence.licenceArea}
                    mapMarkers={props.mapMarkers}
                    trafficCommisioner={props.trafficCommisioner}
                    selectedCentreId={props.selectedOperatingCentreId}
                    clickMarkerHandler={(id) => { props.clickMarkerHandler(id) }}
                />
            </div>
            <Card className="operating-centre-overview-section">
                <h6>OPERATING CENTRE</h6>
                <h2 className="operating-centre-overview-text">{props.operatingCentreOverviewText}</h2>
                <Card className="operating-centre-inner-card-style">
                    <div className="operating-centre-inner-card-kvp-container">
                        <div className="operating-centre-inner-card-kvp">
                            <h6>AUTHORISED VEHICLES</h6>
                            <div>{props.operatingCentreAuthorisedVehicles}/{props.operatingCentreTotalVehicles}</div>
                        </div>
                        <div className="operating-centre-inner-card-kvp">
                            <h6>AUTHORISED TRAILERS</h6>
                            <div>{props.operatingCentreAuthorisedTrailers}/{props.operatingCentreTotalTrailers}</div>
                        </div>
                        <div className="operating-centre-inner-card-kvp">
                            <h6>NUMBER OF LOCATIONS</h6>
                            <div>{props.operatingCentreNumberOfLocations}</div>
                        </div>
                        <div className="operating-centre-inner-card-kvp">
                            <h6>FULL ADDRESS</h6>
                            <div>{props.operatingCentreAddress1}</div>
                            <div>{props.operatingCentreAddress2}</div>
                            <div>{props.operatingCentreAddress3}</div>
                        </div>
                    </div>
                </Card>
            </Card>
        </Modal>
    );
}

export default OperatingLicenceMapModal;

OperatingLicenceMapModal.defaultProps = {
    showMapModal: false,
    handleCloseMapModal: () => { },
    currentLicence: null,
    mapMarkers: [],
    trafficCommisioner: null,
    selectedOperatingCentreId: 0,
    clickMarkerHandler: () => { },
    operatingCentreOverviewText: "",
    operatingCentreTotalVehicles: 0,
    operatingCentreTotalTrailers: 0,
    operatingCentreAuthorisedVehicles: 0,
    operatingCentreAuthorisedTrailers: 0,
    operatingCentreNumberOfLocations: 0,
    operatingCentreAddress1: "",
    operatingCentreAddress2: "",
    operatingCentreAddress3: ""
}

OperatingLicenceMapModal.propTypes = {
    showMapModal: PropTypes.bool.isRequired,
    handleCloseMapModal: PropTypes.func.isRequired,
    currentLicence: PropTypes.object.isRequired,
    mapMarkers: PropTypes.array.isRequired,
    trafficCommisioner: PropTypes.object.isRequired,
    selectedOperatingCentreId: PropTypes.number.isRequired,
    clickMarkerHandler: PropTypes.func.isRequired,
    operatingCentreOverviewText: PropTypes.string.isRequired,
    operatingCentreTotalVehicles: PropTypes.number.isRequired,
    operatingCentreTotalTrailers: PropTypes.number.isRequired,
    operatingCentreAuthorisedVehicles: PropTypes.number.isRequired,
    operatingCentreAuthorisedTrailers: PropTypes.number.isRequired,
    operatingCentreNumberOfLocations: PropTypes.number.isRequired,
    operatingCentreAddress1: PropTypes.string.isRequired,
    operatingCentreAddress2: PropTypes.string.isRequired,
    operatingCentreAddress3: PropTypes.string.isRequired
}