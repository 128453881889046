import React, { useState, useEffect, Fragment, useRef } from "react";
import { UserAccess } from "components/user-access";
import { AlertUnsavedData, Modal, NotificationToaster } from "components/notifications";
import { PageRestricted } from "pages/errors/page-restricted";
import PropTypes from "prop-types";
import { useDirtyData } from 'hooks/useDirtyData';
import { Wizard } from "components/wizard";
import { FormCheckbox, FormNumericInput, FormSuggest, FormTextInput } from 'components/form-fields';
import { LocationService, OperatingLicenceService } from "services";
import { Intent } from "@blueprintjs/core";
import { useValidation } from "hooks/useValidation";
import moment from 'moment';
import { GridLayout } from "components/grid";
import { Button } from "components/buttons";
import { Table } from "components/listing";
import "./OperatingCentreModal.css";
import { Expandable } from "components/layout";

export function OperatingCentreModal(
    { 
        showModal, 
        modalTitle,
        operatingCentreId,
        operatingLicenceId,
        isUpdate,
        loading,
        showFinishOnAllSteps,
        requiredActions, 
        onSave, 
        onCloseModal, 
        centreDetails
    }
) {
    const [loadingLocations, setLoadingLocations] = useState(true);
    const [saving, setSaving] = useState(false);

    const forbiddenErrorCode = 403;
    const operatingCentreDetailsModel = {
        name: "",
        authorisedVehicles: null,
        authorisedTrailers: null
    };

    const operatingCentreAddressModel = {
        address1: "",
        address2: "",
        address3: "",
        town: "",
        county: "",
        postcode: ""
    };
    
    const [originalOperatingCentreDetails, setOriginalOperatingCentreDetails] = useState(operatingCentreDetailsModel);
    const [operatingCentreDetails, setOperatingCentreDetails] = useState(originalOperatingCentreDetails);
    const isOperatingCentreDetailsDirty = useDirtyData(originalOperatingCentreDetails, operatingCentreDetails);
    const [isOperatingCentreDetailsValid, operatingCentreDetailsErrors, validateOperatingDetailsCentre] = useValidation();

    const [originalOperatingCentreAddress, setOriginalOperatingCentreAddress] = useState(operatingCentreAddressModel);
    const [operatingCentreAddress, setOperatingCentreAddress] = useState(originalOperatingCentreAddress);
    const isOperatingCentreAddressDirty = useDirtyData(originalOperatingCentreAddress, operatingCentreAddress);
    const [isOperatingCentreAddressValid, operatingCentreAddressErrors, validateOperatingAddressCentre] = useValidation();

    const [locationSearchTerm, setLocationSearchTerm] = useState("");
    const inputSearchRef = useRef(null);
    const [originalLocations, setOriginalLocations] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [availableLocations, setAvailableLocations] = useState([]);
    const [isLocationsSelectionDirty, setIsLocationSelectionDirty] = useState(false);
    const [isLocationsSelectionValid, setIsLocationSelectionValid] = useState(false);

    const [isPostCodeValid, postcodeErrors, validatePostcode] = useValidation();
    const [postcodeSearch, setPostcodeSearch] = useState("");
    const [postcodeResults, setPostcodeResults] = useState([]);
    const [addressCountry, setAddressCountry] = useState("GB");
    const inputRef = useRef(null);

    const [wizardStep, setWizardStep] = useState(0);
    const [wizardValid, setWizardValid] = useState(false);

    const [blockModalClose, setBlockModalClose] = useState(false);

    const tableHeaders = formatParamTableHeaders();

    const detailsStep = 0;
    const addressStep = 1;
    const locationStep = 2;
    const detailsSectionName = "details";
    const addressSectionName = "address";

    useEffect(loadDetails, [centreDetails]);

    function loadDetails() {
        if (!showModal || !centreDetails) {
            return;
        }

        const mappedCentreDetails = {
            name: centreDetails.name,
            authorisedVehicles: centreDetails.authorisedVehicles,
            authorisedTrailers: centreDetails.authorisedTrailers
        };

        const mappedCentreAddress = {
            address1: centreDetails.address1,
            address2: centreDetails.address2,
            address3: centreDetails.address3,
            town: centreDetails.town,
            county: centreDetails.county,
            postcode: centreDetails.postcode
        };

        setOriginalOperatingCentreDetails(mappedCentreDetails); 
        setOperatingCentreDetails(mappedCentreDetails);
        setOriginalOperatingCentreAddress(mappedCentreAddress);
        setOperatingCentreAddress(mappedCentreAddress);
        setOriginalLocations(centreDetails.locations);
        setSelectedLocations(centreDetails.locations);
    }

    function onValueChange(newValue, field, modelName, controlType = "default") {
        let passedValue = newValue;
        
        if (controlType === "date") { 
            passedValue = newValue ? moment(newValue).format("YYYY-MM-DD") : null
        }

        if (controlType === "radio") {
            passedValue = newValue === "true"; 
        }

        passedValue = passedValue === "" ? null : passedValue;

        switch (modelName) {
            case "details":
                setOperatingCentreDetail(field, passedValue);
                break;
            case "address":
                setOperatingCentreAddress((prevValue) => {
                    let clonedScores = { ...prevValue }
                    clonedScores[field] = passedValue;
            
                    return clonedScores;
                });
                break;
            default:
                setOperatingCentreDetail(field, passedValue);
                break;
        }
    }

    function setOperatingCentreDetail(field, passedValue) {
        setOperatingCentreDetails((prevValue) => {
            let clonedScores = { ...prevValue }
            clonedScores[field] = passedValue;
    
            return clonedScores;
        });
    }

    useEffect(validate, [
        operatingCentreDetails, 
        isOperatingCentreDetailsDirty, 
        isOperatingCentreDetailsValid,
        operatingCentreAddress,
        isOperatingCentreAddressDirty,
        isOperatingCentreAddressValid,
        wizardStep,
        loading
    ]);

    function validate() {
        if (!showModal || loading) {
            return; 
        }

        if (wizardStep === detailsStep) {
            let detailsRules = [
                { fieldName: "name", required: true },
                { fieldName: "authorisedVehicles", required: true },
                { fieldName: "authorisedTrailers", required: true }
            ];
    
            validateOperatingDetailsCentre(detailsRules, operatingCentreDetails);
        }

        if (wizardStep === addressStep && (isOperatingCentreAddressDirty || isUpdate)) {
            let addressRules = [
                { fieldName: "address1", required: true },
                { fieldName: "town", required: true },
                { fieldName: "county", required: true },
                { fieldName: "postcode", required: true }
            ];

            validateOperatingAddressCentre(addressRules, operatingCentreAddress);
        }
    }

    function onSaveClick() {
        if (!isUpdate && 
            (
                !isOperatingCentreDetailsValid || 
                !isOperatingCentreAddressValid || 
                !isLocationsValid() || 
                (!isOperatingCentreDetailsDirty && !isOperatingCentreAddressDirty && !isLocationsDirty())
            )
        ) {
            return;
        }

        setSaving(true);

        const operatingCentre = {
            operatingLicenceId: operatingLicenceId,
            name: operatingCentreDetails.name,
            address1: operatingCentreAddress.address1,
            address2: operatingCentreAddress.address2,
            address3: operatingCentreAddress.address3,
            town: operatingCentreAddress.town,
            county: operatingCentreAddress.county,
            postcode: operatingCentreAddress.postcode,
            authorisedVehicles: operatingCentreDetails.authorisedVehicles,
            authorisedTrailers: operatingCentreDetails.authorisedTrailers,
            locations: selectedLocations
        };
    
        onSave(operatingCentre)
            .then(
                () => {
                    resetModal();
                    setSaving(false);
                }
            )
            .catch((error) => {
                // if we get a 403 then close the modal
                if (error !== null && error !== undefined && error.status === forbiddenErrorCode) {
                    NotificationToaster.show(Intent.DANGER, `You are not allowed to change this operating centre.`);
                    onCloseClick(true);
                } else {
                    NotificationToaster.show(Intent.DANGER, `Failed to ${isUpdate ? "update" : "create"} operating centre.`);
                }
            })
            .finally(() => {
                setSaving(false);
            });
    }

    function isLocationsValid() {
        return selectedLocations.length > 0;
    }

    function isLocationsDirty() {
        return originalLocations.length !== selectedLocations.length &&
            (
                originalLocations.some(ol => !selectedLocations.some(sl => sl === ol)) ||
                selectedLocations.some(sl => !originalLocations.some(ol => ol === sl))
            );
    }

    function onWizardChange(step) {
        setWizardStep(step);
    }

    function getAdditionalButtons() {

        return (
            <Button 
                intent="secondary" 
                loading={loading} 
                text="Close" 
                onClick={() => onCloseClick(false)} 
                disabled={saving || loading} 
                id="close-btn"
            />
        );
    }

    // POST CODE SEARCH

    useEffect(function () {
        if (wizardStep !== addressStep) {
            return;
        }

        let postcodeRules = [
            { fieldName: "postCode", required: false, type: "postcode", maxLength: 255 }
        ];

        setPostcodeResults([]);
        validatePostcode(postcodeRules, postcodeSearch);
    }, [postcodeSearch])

    useEffect(function () {
        if (wizardStep !== addressStep) {
            return;
        }

        if (postcodeSearch.length > 0 && isPostCodeValid) {
            searchPostcode();
        }
    }, [isPostCodeValid])

    function searchPostcode() {
        if (!isPostCodeValid) {
            return;
        }

        LocationService.postCodeSearch(postcodeSearch).then(
            (postcodeRes) => postCodeReturn(postcodeRes, false),
            (error) => postCodeReturnError(error));
    }

    function onPostcodeSearchTextChange(event) {
        setPostcodeSearch(event);
    }

    function onPostcodeResultsSelect(item) {
        var addCountry = item.addressCountry;

        if (!addCountry || addCountry.length < 1) {
            addCountry = addressCountry;
        } else {
            setAddressCountry(addCountry);
        }

        if (item.container) {
            LocationService.postCodeDrillDown(item.id, addCountry).then(
                (postcodeRes) => postCodeReturn(postcodeRes, false),
                (error) => postCodeReturnError(error));
        } else {
            //This will kick off the postCodeRetrieve, seems longwinded but wanted the postcode to clear once address set
            LocationService.postCodeRetrieve(item.id, addressCountry).then(
                (postcodeRes) => postCodeReturn(postcodeRes, true),
                (error) => postCodeReturnError(error));
        }
    }

    function postCodeReturn(postcodeRes, retrieve) {
        if (!retrieve) {
            if (postcodeRes.length < 1) {
                NotificationToaster.show(Intent.WARNING, "No addresses found with the search criteria.");
                return;
            }

            var mappedAddresses = postcodeRes.map(function (add) {
                add.name = add.label;
                add.id = add.data8Id;
                add.icon = add.container ? 'folder-close' : 'map-marker'
                return add;
            });

            //The initial call returns this, but the drilldown doesn't for some reason
            if (retrieve.addressCountry && retrieve.addressCountry.length > 0) {
                setAddressCountry(retrieve.addressCountry);
            }

            setPostcodeResults(mappedAddresses);
            //Need to set focus back so the dropdown opens
            inputRef.current.focus();
        } else {
            onAddressSettingsUpdate(postcodeRes);
        }
    }

    function onAddressSettingsUpdate(address) {
        onValueChange(address.county, "county", addressSectionName);
        onValueChange(address.postCode, "postcode", addressSectionName);
        onValueChange(address.addressLine1, "address1", addressSectionName);
        onValueChange(address.addressLine2, "address2", addressSectionName);
        onValueChange(address.addressLine3, "address3", addressSectionName);
        onValueChange(address.postTown, "town", addressSectionName);
        onValueChange(address.postCode.replace(" ", ""), "postcode", addressSectionName);
    }
    
    function postCodeReturnError(error) {
        NotificationToaster.show(Intent.DANGER, error);
    }

    // LOCATIONS

    function onSearchTermChange(item) {
        setLocationSearchTerm(item);
    }

    useEffect(() => {
        if(wizardStep !== locationStep) {
            return;
        }

        loadLocations();
    }, [wizardStep]);

    function loadLocations() {
        setLoadingLocations(true);

        OperatingLicenceService.getAvailableLocationsForOperatingCentres(operatingCentreId)
            .then(
                (locations) => {
                    const mappedLocations = locations.map((location) => {

                        return {
                            id: location.id,
                            name: location.name,
                            businessAreas: location.businessAreas.map(b => b).join(", "),
                            checked: selectedLocations.some(l => l === location.id)
                        }
                    });

                    setAvailableLocations(mappedLocations);
                }
            )
            .finally(() => {
                setLoadingLocations(false);
            });
    }

    function formatParamTableHeaders() {

        let paramHeaders = ["Location", "Business Areas", ""];

        return {
            headers: paramHeaders.map((value) => {
                return {
                    key: value,
                    value: value
                }
            })
        };
    }

    function formatParamTable(locations) {
        if (locations == null) {
            return [];
        }

        const filteredLocations = locationSearchTerm !== null && locationSearchTerm !== undefined 
            ? locations.filter(l => l.name.toLowerCase().includes(locationSearchTerm.toLowerCase()))
            : locations;

        return filteredLocations.map((location, index) => {
            return {
                cells: [
                    <div key={`location-name-${location.id}`}>
                        <p>{location.name}</p>
                    </div>,
                    <div key={`location-business-areas-${location.id}`}>
                        <p>{location.businessAreas}</p>
                    </div>,
                    <FormCheckbox
                        checked={availableLocations[index].checked}
                        label=''
                        id={`location-checkbox-${location.id}`}
                        key={`location-checkbox-${location.id}`}
                        onChange={() => onLocationSelected(location.id)}
                        loading={loading}
                        disabled={saving}
                    />
                ],
                key: location.id
            }

        });
    }

    function onLocationSelected(locationId) {
        setAvailableLocations((prev) => {
            var clonedLocations = [...prev];
            var matchedLocationIndex = clonedLocations.findIndex(l => l.id === locationId);

            if (matchedLocationIndex === -1) { 
                return clonedLocations;
            }

            clonedLocations[matchedLocationIndex].checked = !clonedLocations[matchedLocationIndex].checked;

            return clonedLocations;
        });
    }

    useEffect(() => {
        setIsLocationSelectionValid(isLocationsValid());
        setIsLocationSelectionDirty(isLocationsDirty());
    }, [selectedLocations, originalLocations]);

    useEffect(() => {
        var locationIds = availableLocations.filter(l => l.checked).map(l => l.id);
        setSelectedLocations(locationIds);
    }, [availableLocations]);

    useEffect(setIfWizardValid, [
        wizardStep, 
        isOperatingCentreDetailsValid, 
        isOperatingCentreDetailsDirty, 
        isOperatingCentreAddressValid, 
        isOperatingCentreAddressDirty,
        isLocationsSelectionValid,
        isLocationsSelectionDirty
    ]);

    function setIfWizardValid() {
        var valid = (wizardStep === detailsStep && isOperatingCentreDetailsValid === true && (isUpdate || isOperatingCentreDetailsDirty)) ||
            (wizardStep === addressStep && isOperatingCentreAddressValid === true && (isUpdate || isOperatingCentreAddressDirty)) ||
            (wizardStep === locationStep && isLocationsSelectionValid && (isUpdate || isLocationsSelectionDirty));

        setWizardValid(valid);
    }

    function onCloseClick(forceClose = false) {
        if ((isOperatingCentreDetailsDirty || isOperatingCentreAddressDirty || isLocationsSelectionDirty) && !forceClose) {
            setBlockModalClose(true);
            return;
        }

        resetModal();
        onCloseModal();
    }

    function resetModal() {
        setOriginalOperatingCentreDetails(operatingCentreDetailsModel);
        setOperatingCentreDetail(operatingCentreDetailsModel);
        setOriginalOperatingCentreAddress(operatingCentreAddressModel);
        setOperatingCentreAddress(operatingCentreAddressModel);
        setAvailableLocations([]);
        setOriginalLocations([]);
        setSelectedLocations([]);
        setWizardStep(0);
        setBlockModalClose(false);
    }
    
    return (
        <Fragment>
            <Modal 
                isOpen={showModal}
                onClose={onCloseClick}
                clickOutsideClose={true}
                title={modalTitle}
                isCloseButtonShown={false}
                updateStateOnClose={false}
                className='operating-centre-modal'
            >
                <UserAccess 
                    perform={requiredActions}
                    yes={() => {
                        return (
                            <>
                                <Wizard 
                                    loading={loading} 
                                    onStepChange={onWizardChange} 
                                    onFinish={onSaveClick}
                                    canProceed={wizardValid}
                                    showFinishOnAllSteps={showFinishOnAllSteps}
                                    disabled={saving}
                                    alignButtonsRight={true}
                                    additionalButtonsStart={getAdditionalButtons()}
                                    finishButtonText="Save and Close"
                                >
                                    <div>
                                        <h2 id="header-centre-details">Operating Centre Details</h2>
                                        
                                        <GridLayout numberOfColumns={1} className="spacer-bottom">
                                            <FormTextInput
                                                id="input-licence-number"
                                                disabled={saving || loading}
                                                loading={loading}
                                                value={operatingCentreDetails.name}
                                                headingText="Operating centre name"
                                                onChange={(event) => onValueChange(event.target.value, "name", detailsSectionName)}
                                                dangerHelperText={operatingCentreDetailsErrors.name}
                                                fill={true}
                                            />
        
                                            <GridLayout numberOfColumns={2} className="spacer-bottom">
                                                <FormNumericInput
                                                    id="input-number-of-authorised-vehicles"
                                                    disabled={saving || loading}
                                                    min={1}
                                                    max={9999}
                                                    allowDecimal={false}
                                                    selectedValue={operatingCentreDetails.authorisedVehicles}
                                                    onValueChange={(event) => onValueChange(event, "authorisedVehicles", detailsSectionName)}
                                                    headingText={"Number of authorised vehicles"}
                                                    dangerHelperText={operatingCentreDetailsErrors.authorisedVehicles}
                                                    loading={loading}
                                                />
                                                <FormNumericInput
                                                    id="input-number-of-authorised-trailers"
                                                    disabled={saving || loading}
                                                    min={0}
                                                    max={9999}
                                                    allowDecimal={false}
                                                    selectedValue={operatingCentreDetails.authorisedTrailers}
                                                    onValueChange={(event) => onValueChange(event, "authorisedTrailers", detailsSectionName)}
                                                    headingText={"Number of authorised trailers"}
                                                    dangerHelperText={operatingCentreDetailsErrors.authorisedTrailers}
                                                    loading={loading}
                                                />
                                            </GridLayout>
                                        </GridLayout>
                                    </div>

                                    <div>
                                        <h2 id="header-centre-address">Operating Centre Address</h2>
        
                                        <GridLayout numberOfColumns={2} className="spacer-bottom">
                                            <FormSuggest 
                                                loading={loading}
                                                onQueryChange={onPostcodeSearchTextChange}
                                                disabled={false}
                                                items={postcodeResults}
                                                onItemSelect={onPostcodeResultsSelect}
                                                headingText="Postcode search"
                                                onFilter={null}
                                                debounceTime={0}
                                                itemsToReturn={0}
                                                resetOnSelect={false}
                                                inputRef={inputRef}
                                                dangerHelperText={postcodeErrors.postCode}
                                                id="postcode-search-field">
                                            </FormSuggest>

                                            <FormTextInput
                                                id="input-county"
                                                disabled={saving || loading}
                                                loading={loading}
                                                value={operatingCentreAddress.county}
                                                headingText="County"
                                                onChange={(event) => onValueChange(event.target.value, "county", addressSectionName)}
                                                dangerHelperText={operatingCentreAddressErrors.county}
                                            />
                                        </GridLayout>
                                        <GridLayout numberOfColumns={2} className="spacer-bottom-medium">
                                            <div className="inline-item">
                                                <FormTextInput
                                                    id="input-address-line-1"
                                                    disabled={saving || loading}
                                                    loading={loading}
                                                    value={operatingCentreAddress.address1}
                                                    headingText="Address"
                                                    placeholder="Address line 1"
                                                    onChange={(event) => onValueChange(event.target.value, "address1", addressSectionName)}
                                                    dangerHelperText={operatingCentreAddressErrors.address1}
                                                />
                                            </div>
                                            <div className="inline-item">
                                                <FormTextInput
                                                    id="input-postcode"
                                                    disabled={saving || loading}
                                                    loading={loading}
                                                    value={operatingCentreAddress.postcode}
                                                    headingText="Postcode"
                                                    onChange={(event) => onValueChange(event.target.value, "postcode", addressSectionName)}
                                                    dangerHelperText={operatingCentreAddressErrors.postcode}
                                                />
                                            </div>
                                            <div className="inline-item">
                                                <FormTextInput
                                                    id="input-address-line-2"
                                                    disabled={saving || loading}
                                                    loading={loading}
                                                    value={operatingCentreAddress.address2}
                                                    placeholder="Address line 2"
                                                    onChange={(event) => onValueChange(event.target.value, "address2", addressSectionName)}
                                                    dangerHelperText={operatingCentreAddressErrors.address2}
                                                />
                                            </div>
                                        </GridLayout>
                                        <GridLayout numberOfColumns={2} className="spacer-bottom">
                                            <FormTextInput
                                                id="input-address-line-3"
                                                disabled={saving || loading}
                                                loading={loading}
                                                value={operatingCentreAddress.address3}
                                                placeholder="Address line 3"
                                                onChange={(event) => onValueChange(event.target.value, "address3", addressSectionName)}
                                                dangerHelperText={operatingCentreAddressErrors.address3}
                                            />
                                        </GridLayout>
                                        <GridLayout numberOfColumns={1} className="spacer-bottom">
                                            <FormTextInput
                                                id="input-town-city"
                                                disabled={saving || loading}
                                                loading={loading}
                                                value={operatingCentreAddress.town}
                                                headingText="Town/City"
                                                onChange={(event) => onValueChange(event.target.value, "town", addressSectionName)}
                                                dangerHelperText={operatingCentreAddressErrors.town}
                                            />
                                        </GridLayout>
                                    </div>

                                    <div>
                                        <FormTextInput 
                                            id="location-search-field" 
                                            inputRef={inputSearchRef} 
                                            placeholder="Search and select all associated locations" 
                                            onChange={(x) => onSearchTermChange(x.target.value)} 
                                            value={locationSearchTerm} 
                                            large 
                                            disabled={saving} 
                                            icon="search"
                                            fill={true}
                                        />

                                        <GridLayout numberOfColumns={1} className="spacer-bottom">
                                            <Expandable expanded={false} stretched={true}>
                                                <Table
                                                    headerData={tableHeaders}
                                                    data={formatParamTable(availableLocations)}
                                                    loadingData={loadingLocations}
                                                    fitToContent={false}
                                                />
                                            </Expandable>
                                        </GridLayout>
                                    </div>

                                </Wizard>
                            </>
                        )
                    }}
                    no={() => <PageRestricted/>}
                />

            </Modal>
            <AlertUnsavedData
                isDirty={isOperatingCentreDetailsDirty || isOperatingCentreAddressDirty}
                isDirtySamePage={blockModalClose}
                onCancelLeave={() => setBlockModalClose(false)}
                onConfirmLeave={() => onCloseClick(true)}
            />
        </Fragment>
    );
}

OperatingCentreModal.defaultProps = {
    showFinishOnAllSteps: false,
    loading: true,
    isUpdate: false
};

OperatingCentreModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    modalTitle: PropTypes.string.isRequired,
    operatingCentreId: PropTypes.number,
    operatingLicenceId: PropTypes.number.isRequired,
    showFinishOnAllSteps: PropTypes.bool,
    isUpdate: PropTypes.bool,
    loading: PropTypes.bool,
    requiredActions: PropTypes.array.isRequired,
    onSave: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    centreDetails: PropTypes.object.isRequired
};