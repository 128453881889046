import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker, Polygon } from '@react-google-maps/api';
import PropTypes from 'prop-types';

export function OperatingLicenceMap(props) {

    const [center, setCenter] = useState({lat: 0, lng: 0});
    const [zoom, setZoom] = useState(1);
    const [polygons, setPolygons] = useState([]);
    const [selectedCentreId, setSelectedCentreId] = useState(-1);
    const [selectedAreaId, setSelectedAreaId] = useState(null);

    const mapContainerStyle = {
        width: props.width,
        height: props.height,
    };
    const areas = [
        {
            "id": 1,
            "name": "England - London & South East",
            "lat": 51.257641,
            "long": 0.040700,
            "zoom": 8.2
        },
        {
            "id": 2,
            "name": "England - North East",
            "lat": 54.407065,
            "long": -1.452440,
            "zoom": 6.6
        },
        {
            "id": 3,
            "name": "Scotland",
            "lat": 56.659406,
            "long": -4.011214,
            "zoom": 5.8

        },
        {
            "id": 4,
            "name": "England - North West",
            "lat": 53.953849,
            "long": -2.540248,
            "zoom": 7.1
        },
        {
            "id": 5,
            "name": "England - West Midlands",
            "lat": 52.534140,
            "long": -2.273900,
            "zoom": 7.45
        },
        {
            "id": 6,
            "name": "England - East",
            "lat": 52.562008,
            "long": 0.097841,
            "zoom": 7.1
        },
        {
            "id": 7,
            "name": "England - West",
            "lat": 51.155850,
            "long": -2.839985,
            "zoom": 7.1
        },
        {
            "id": 8,
            "name": "Wales",
            "lat": 52.488203,
            "long": -3.602509,
            "zoom": 7.1
        }
    ]

    useEffect(() => {
        let operatingLicenceArea = areas.find(x => x.name === props.operatingLicenceArea.name);
        setCenter({
            lat: operatingLicenceArea.lat,
            lng: operatingLicenceArea.long
        });
        setZoom(operatingLicenceArea.zoom);
        setSelectedAreaId(props.operatingLicenceArea.id);
    }, [props.operatingLicenceArea])

    useEffect(() => {
        if (props.selectedCentreId) {
            setSelectedCentreId(props.selectedCentreId);
        }
    }, [props.selectedCentreId])

    useEffect(() => {
        const fetchGeoJSON = async () => {
            try {
                const response = await fetch("/map.json");
                const geoJson = await response.json();

                const extractedPolygons = [];

                let filteredRegion = geoJson.features.find(x => x.id === selectedAreaId);

                if (filteredRegion.geometry.type === "Polygon") {
                    extractedPolygons.push({
                        name: filteredRegion.properties.name,
                        path: normalizeCoordinates(filteredRegion.geometry.coordinates[0]),
                        id: 1
                    });
                }
                else if (filteredRegion.geometry.type === "GeometryCollection") {
                    const geometryPolygons = processGeometryCollection(filteredRegion.geometry);
                    let counter = 1;
                    geometryPolygons.forEach((polygon) => {
                        extractedPolygons.push({
                            name: filteredRegion.properties.rgn_name,
                            path: polygon.path,
                            id: counter
                        });
                        counter = counter + 1;
                    });
                }
                setPolygons(extractedPolygons);
            } catch (error) {
            }
        };

        fetchGeoJSON();
    }, [selectedAreaId]);

    function normalizeCoordinates(coordinates) {
        return coordinates.map(([lng, lat]) => ({ lat, lng }));
    }

    function processGeometryCollection(geometry){
        const returnPolygons = [];

        geometry.geometries.forEach((subGeometry) => {
        if (subGeometry.type === "Polygon") {
            returnPolygons.push({
            path: normalizeCoordinates(subGeometry.coordinates[0]),
            });
        } else if (subGeometry.type === "MultiPolygon") {
            subGeometry.coordinates.forEach((polygonCoords) => {
            returnPolygons.push({
                path: normalizeCoordinates(polygonCoords[0]),
            });
            });
        }
        });

        return returnPolygons;
    };

    const ukBounds = {
        north: 61.7648,
        south: 47.8923,
        west: -13.72299,
        east: 6.33029,
    };

    return (
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={zoom}
            options={{
                mapId: window.env.GOOGLEMAPSOPERATINGLICENCEMAPSTYLE,
                mapTypeControl: false,
                zoomControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                restriction: {
                    latLngBounds: ukBounds,
                    strictBounds: true
                }
            }}
            >
            {props.mapMarkers.map((marker) => (
                marker.lat && marker.lng ? (
                    <Marker
                        key={marker.id}
                        position={{ lat: marker.lat, lng: marker.lng }}
                        onClick={() => {
                            selectedCentreId === marker.id
                                ? setSelectedCentreId(null)
                                : setSelectedCentreId(marker.id);
                            props.clickMarkerHandler(marker.id);
                        }}
                        icon={marker.id === selectedCentreId
                            ? "/icons/map-markers/0070CC-dot.png"
                            : "/icons/map-markers/008CFF-dot.png"}
                    />
                ) : null
            ))}
                {polygons.map((polygon, index) => (
                    <Polygon
                    key={polygon.id}
                    path={polygon.path}
                        options={{
                            fillOpacity: 0,
                            strokeOpacity: 1,
                            strokeWeight: 2,
                            strokeColor: '#66BAFF'
                    }}
                    />
                ))}
                <Marker
                    position={props.trafficCommisioner}
                    icon="/icons/map-markers/F0578A-dot.png"
                />
            ))}
        </GoogleMap>
    );
};

export default OperatingLicenceMap;

OperatingLicenceMap.defaultProps = {
    width: 0,
    height: 0,
    operatingLicenceArea: null,
    selectedCentreId: 0,
    mapMarkers: [],
    clickMarkerHandler: () => { },
    trafficCommisioner: null
}

OperatingLicenceMap.propTypes = {
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    operatingLicenceArea: PropTypes.object.isRequired,
    selectedCentreId: PropTypes.number.isRequired,
    mapMarkers: PropTypes.array.isRequired,
    clickMarkerHandler: PropTypes.func.isRequired,
    trafficCommisioner: PropTypes.object.isRequired
}
