import ApiService from 'services/ApiService'

const badRequestCode = 400;

export const OperatingCentreService = {
	searchOperatingCentres(data) {
		return new Promise((resolve, reject) => {
			ApiService.post('/asset/OperatingCentres/Search', data).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getOperatingCentre(id) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/asset/OperatingCentres/${id}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	deleteOperatingCentre(id) {
		return new Promise((resolve, reject) => {
			ApiService.delete(`/asset/OperatingCentres/${id}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject("Unable to delete the Operating Centre. Please try again.");
				});
		});
	}
}