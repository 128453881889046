import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'components/buttons'
import { SelfReportingService } from 'services';
import { Intent, MenuItem } from '@blueprintjs/core';
import { NotificationToaster } from "components/notifications";
import PropTypes from 'prop-types';

export function InstantReportButton(props){

    const [downloadingReport, setDownloadingReport] = useState(false);
    const generationCancelTokenSource = useRef();
    
    function notifyResponse(response) {
        if (response)
        {
            NotificationToaster.show(
                Intent.SUCCESS,
                "Report has been generated successfully, download will now begin"
            );
        }
        else
        {
            NotificationToaster.show(
                Intent.DANGER, 
                "Could not generate the report. Please try again.");
        }
        setDownloadingReport(false);
        if (props.onReportDownloadComplete) {
            props.onReportDownloadComplete();
        }
    }

    useEffect(function (){
        setDownloadingReport(props.buttonDisabled);
    }, [props.buttonDisabled])

    useEffect(function () {
        if (props.reportId != null) {
            SelfReportingService.getInstantReport(props.reportId, generationCancelTokenSource, notifyResponse );
        }
    }, [props.reportId]);

    
    return props.buttonType === "MenuItem" ?
    (
        <MenuItem icon={props.icon} intent={props.intent} onClick={props.onReportGenerate} id={props.id} disabled={downloadingReport} text={props.text} />
    ) : (
        <Button icon={props.icon} intent={props.intent} onClick={props.onReportGenerate} id={props.id} disabled={downloadingReport} text={props.text} />
    );
}

InstantReportButton.defaultProps = {
    id: null,
    text: "Download report",
    icon: "download",
    intent: "primary",
    reportId: null,
    buttonEnabled: true,
    buttontype: "Button"
};

InstantReportButton.propTypes = {
    id: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    intent: PropTypes.string,
    reportId: PropTypes.number,
    buttonDisabled: PropTypes.bool,
    onReportGenerate: PropTypes.func.isRequired,
    onReportDownloadComplete: PropTypes.func.isRequired,
    buttonType: PropTypes.string
};