import React from "react";
import PropTypes from "prop-types";
import { OperatingLicenceModal } from "pages/operating-licences/modals/shared";
import { OperatingLicenceService } from "services";
import { NotificationToaster } from "components/notifications";
import { Intent } from "@blueprintjs/core";

export function CreateOperatingLicenceModal(
    { 
        showModal, 
        onSaveModal, 
        onCloseModal 
    }
) {
    const requiredActions = ["OperatingLicence:Manage"];

    const operatingLicenceDetailsModel = {
        licenceArea: null,
        licenceType: null,
        licenceNumber: '',
        licenceRenewal: null,
        earnedRecognitionOperator: null,
        ocrs: {
            roadScore: null,
            trafficScore: null,
            combinedScore: null,
            earnedRecognitionNumber: '',
        },
        vehicleInspectionFrequency: null,
        trailerInspectionFrequency: null
    };

    function onSave(operatingLicence) {
        return OperatingLicenceService.createOperatingLicence(operatingLicence).then(
            ({ data }) => {
                NotificationToaster.show(Intent.SUCCESS, "Operating Licence created successfully.");
                onSaveModal(data);
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    }

    return (
        <>
            <OperatingLicenceModal
                showModal={showModal}
                modalTitle="New Operating Licence"
                requiredActions={requiredActions}
                onSave={onSave}
                onCloseModal={onCloseModal}
                licenceDetails={operatingLicenceDetailsModel}
                loading={false}
            />
        </>
    );
}

CreateOperatingLicenceModal.defaultProps = {

};

CreateOperatingLicenceModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onSaveModal: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired
};