import React, { Fragment, useState, useEffect } from 'react';
import { Intent } from '@blueprintjs/core';

import { NotificationInline, NotificationToaster } from 'components/notifications';

import { FormDateRangeInput, FormRadio, FormSelect, FormTextInput, FormSuggest, FormNumericInput, FormDateInput, FormLocationSelector } from 'components/form-fields';
import { useValidation } from "hooks/useValidation";
import { ShowHide } from 'components/layout';
import { EmployeeService } from "services";

import PropTypes from 'prop-types';
import moment from "moment";


export function ReportParameters(props) {

    const [isValid, errors, validate] = useValidation();
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [filterType, setFilterType] = useState(null);
    const [driverList, setDriverList] = useState([]);
    const [validationRules, setValidationRules] = useState([]);
    const [locations, setLocations] = useState([]);
    const [showLocationsAsHierarachy, setShowLocationsAsHierarchy] = useState(true);
    const numWithBounds = 'number with bounds';
    const intMinDefault = 0;
    const intMaxDefault = 99999999;

    useEffect(function () {

        if (props.parameters == null) {
            return;
        }

        var rules = [
            { fieldName: "reportType", required: true }
        ];

        props.parameters.forEach(function (rp) {
            if (!rp.hidden && !rp.optional && rp.parameterType.key !== "LocationList" && rp.parameterType.key !== "DriverList") {
                rules.push({ fieldName: rp.name, required: true })
            }
        });

        setValidationRules(rules);

    }, [props.parameters]);

    useEffect(function () {
        setLocations(props.locations);
    }, [props.locations]);

    useEffect(function () {
        setSelectedLocations([]);
        setSelectedDriver(null);
        setDriverList([]);
        setFilterType(null);
    }, [props.reportId]);

    function setUseHierarchy() {
        setShowLocationsAsHierarchy(previous => !previous);
    }


    function printFormInput(parameter) {

        if (parameter.hidden) {
            return (<Fragment key={parameter.id}></Fragment>);
        }

        switch (parameter.parameterType.key) {
            case "String":
                if (parameter.options && parameter.options.length > 0) {
                    return (
                        <div key={parameter.id}>
                            <FormSelect headingText={`${parameter.friendlyName}:`} disabled={props.disabled} loading={props.loading} dangerHelperText={errors[parameter.name]} onItemSelect={(item) => { onSelectChange(item, parameter.name); }} selectedValue={getParamValue(parameter.name)} items={parameter.options.map(function (o) {
                                return { id: o.value, name: o.name };
                            })} />
                        </div>
                    );
                } else {
                    return (
                        <div key={parameter.id}>
                            <FormTextInput headingText={`${parameter.friendlyName}:`} disabled={props.disabled} dangerHelperText={errors[parameter.name]} loading={props.loading} onChange={(item) => { onTextChange(item, parameter.name) }} value={getParamValue(parameter.name)} />
                        </div>
                    );
                }
            case "Integer":
            case "Decimal":
                return (
                    <div key={parameter.id}>
                        <FormNumericInput headingText={`${parameter.friendlyName}:`} disabled={props.disabled} dangerHelperText={errors[parameter.name]} loading={props.loading} allowDecimal={parameter.parameterType.key === "Decimal"} onValueChange={(item) => { onNumericChange(item, parameter.name); }} min={parameter.intMin == null ? intMinDefault : parameter.intMin} max={parameter.intMax == null ? intMaxDefault : parameter.intMax} selectedValue={getParamValue(parameter.name)} />
                    </div>
                );
            case "Boolean":
                return (
                    <div key={parameter.id}>
                        <FormRadio headingText={`${parameter.friendlyName}:`} disabled={props.disabled} dangerHelperText={errors[parameter.name]} loading={props.loading} onChange={(item) => { onRadioChange(item, parameter.name); }} selectedValue={getParamValue(parameter.name)} options={[{ value: 'true', label: "Yes" }, { value: 'false', label: "No" }]} />
                    </div>
                );
            case "StartDate":
                return (
                    <div key={parameter.id}>
                        <FormDateRangeInput headingText={`Date range:`} helperText={props.restrictDateRange && props.restrictNumberOfMonths != null ? `Restricted to a maximum of ${props.restrictNumberOfMonths} months` : null} disabled={props.disabled || props.fixedStartDate || props.fixedEndDate || props.editing} dangerHelperText={errors[parameter.name]} loading={props.loading} onChange={onDateRangeChange} startDate={getParamValueByType('StartDate')} endDate={getParamValueByType('EndDate')} maxDate={calculateMaxDateRangeDate()} initialMonthOffset={-1} />
                        <NotificationInline allowClose={false} text={"The date range has been locked because you have selected for your reports to run on a 4-4-5 schedule. Dates will be moved on automatically each time the schedule runs."} intent="info" show={props.fixedStartDate || props.fixedEndDate} />
                    </div>
                );
            case "Date":
                return (
                    <div key={parameter.id}>
                        <FormDateInput headingText={`${parameter.friendlyName}:`} disabled={props.disabled || props.editing} dangerHelperText={errors[parameter.name]} loading={props.loading} onChange={(item) => { onDateChange(item, parameter.name); }} value={getParamValue(parameter.name)} />
                    </div>
                );
            case "EndDate":
            case "DriverList":
                return (
                    <Fragment key={parameter.id}></Fragment>
                );
            case "LocationList":

                var hasDriverSelect = getParamByType('DriverList') != null;

                if (hasDriverSelect) {
                    return (
                        <div key={parameter.id}>
                            <FormRadio headingText="How would you like to filter your report?" disabled={props.disabled} dangerHelperText={errors.filterType} loading={props.loading} onChange={(item) => { onFilterSelect(item); }} selectedValue={filterType} options={[{ value: 'location', label: "By location" }, { value: 'driver', label: "By driver" }]} />
                            <ShowHide
                                evaluator={filterType === "location"}
                                show={(
                                    <FormLocationSelector
                                        headingText={`Select locations:`}
                                        dangerHelperText={errors[parameter.name]}
                                        businessArea={"Tacho"}
                                        loading={props.loading || locations.length === 0}
                                        selectedLocations={selectedLocations}
                                        setSelectedLocations={onLocationSelect}
                                        useHierarchy={showLocationsAsHierarachy}
                                        setUseHierarchy={setUseHierarchy}
                                        useLocationDefaults={false}
                                        useCustomLocations
                                        customLocations={locations}
                                        locationDefaultsToTrue
                                        disabled={props.disabled}
                                    />
                                )}
                            />
                            <ShowHide
                                evaluator={filterType === "driver"}
                                show={(
                                    <FormSuggest loading={props.loading} disabled={props.disabled} items={driverList} onItemSelect={onDriverSelect} dangerHelperText={errors.DriverID} placeholder="Select driver" headingText="Select driver:" selectedValue={selectedDriver} onFilter={onDriverSearch} debounceTime={500} />
                                )}
                            />
                        </div>
                    );
                } else {
                    return (
                        <div key={parameter.id}>
                            <FormLocationSelector
                                headingText={`Select locations:`}
                                dangerHelperText={errors[parameter.name]}
                                businessArea={"Tacho"}
                                loading={props.loading || locations.length === 0}
                                selectedLocations={selectedLocations}
                                setSelectedLocations={onLocationSelect}
                                useHierarchy={showLocationsAsHierarachy}
                                setUseHierarchy={setUseHierarchy}
                                useLocationDefaults={false}
                                useCustomLocations
                                customLocations={locations}
                                locationDefaultsToTrue
                                disabled={props.disabled}
                            />
                        </div>
                    );
                }

            default:
                return (
                    <div key={parameter.id}>
                        <p>{parameter.friendlyName}</p>
                    </div>
                );
        }
    }

    function onFormatChange(item) {
        var reportTypeId = parseInt(item.currentTarget.value);
        props.onFormatUpdate(reportTypeId);
    }

    function onRadioChange(item, paramName) {
        setGenerationParam(paramName, item.currentTarget.value);
    }

    function onNumericChange(item, paramName) {
        if (item != null) {
            setGenerationParam(paramName, item);
        }
    }

    function onSelectChange(item, paramName) {
        setGenerationParam(paramName, item.id);
    }

    function onDateRangeChange(item) {
        setGenerationParamByType('StartDate', item.startDate.date != null ? item.startDate.dateString : null);
        setGenerationParamByType('EndDate', item.endDate.dateString != null ? item.endDate.dateString : null);
    }

    useEffect(function () {

        if (props.fixedStartDate != null && props.fixedEndDate != null) {
            setGenerationParamByType('StartDate', props.fixedStartDate);
            setGenerationParamByType('EndDate', props.fixedEndDate);
        }
    }, [props.fixedStartDate, props.fixedEndDate, props.values.parameters.length])

    function onDateChange(item, paramName) {
        setGenerationParam(paramName, item ? moment(item).format("YYYY-MM-DD") : null);
    }

    function onTextChange(item, paramName) {
        setGenerationParam(paramName, item.target.value);
    }

    function getParamValue(paramName) {
        var foundParam = props.values.parameters.find(function (p) {
            return p.Key === paramName;
        });

        return foundParam == null ? null : foundParam.Value
    }

    function getParamValueByType(typeName) {

        var foundParam = props.values.parameters.find(function (p) {
            return p.Type === typeName;
        });

        return foundParam == null ? null : foundParam.Value
    }

    function getParamByType(typeName) {
        return props.values.parameters.find(function (p) {
            return p.Type === typeName;
        });
    }

    function setGenerationParam(paramName, value) {

        var clonedParams = [
            ...props.values.parameters
        ];

        var changingParam = clonedParams.find(function (p) {
            return p.Key === paramName;
        });

        if (changingParam == null) {
            return;
        }

        changingParam.Value = value;

        props.onParamUpdate(clonedParams);
    }

    function setGenerationParamByType(paramType, value) {

        var clonedParams = [
            ...props.values.parameters
        ];


        var changingParam = clonedParams.find(function (p) {
            return p.Type === paramType;
        });

        if (changingParam == null) {
            return;
        }

        changingParam.Value = value;

        props.onParamUpdate(clonedParams);

    }

    function calculateMaxDateRangeDate() {

        if (props.restrictDateRange && props.restrictNumberOfMonths != null) {
            var startParam = props.values.parameters.find(function (p) {
                return p.Type === "StartDate";
            });

            if (startParam != null && startParam.Value != null && startParam.Value !== "") {
                return moment(startParam.Value).add(props.restrictNumberOfMonths, 'months').locale("en-gb").toDate();
            }
        }

        var maxMonths = 12;
        return moment().add(maxMonths, 'months').locale("en-gb").toDate();
    }

    function onFilterSelect(item) {
        setFilterType(item.currentTarget.value);

        if (item.currentTarget.value === "location") {
            setSelectedDriver(null)
        } else {
            setSelectedLocations([]);
        }

    }

    function onLocationSelect(allSelectedlocations) {
        setSelectedLocations([
            ...allSelectedlocations
        ]);
        setGenerationParamByType('LocationList', allSelectedlocations.join(","));
    }

    function onDriverSearch(query) {

        var driversToShow = 50;

        EmployeeService.getEmployeeList(driversToShow, 1, query, "FIRSTNAME", "A", [{ name: "status", value: "active" }, { name: "status", value: "away" }]).then(function (driverResponse) {
            setDriverList(driverResponse.data.map(function (d) {
                return {
                    id: d.employeeId,
                    name: `${d.fullName} - ${d.location.name}`
                }
            }));
        }, function () {
            NotificationToaster.show(Intent.WARNING, "Could not search for a specific driver, please try agian.");
            setDriverList([]);
        });
    }

    function onDriverSelect(item) {
        setSelectedDriver(item.id);
    }

    useEffect(function () {
        setGenerationParamByType('DriverList', selectedDriver);
    }, [selectedDriver]);


    function validateFields() {

        var requestValidation = {
            reportType: props.values.reportType,
            filterType: filterType
        };

        props.values.parameters.forEach(param => {
            requestValidation[param.Key] = param.Value;
        });

        var rules = [
            ...validationRules
        ];

        //Check for location and driver fields and if present add that to validation

        if (props.filterRequired) {
            var locationParam = getParamByType("LocationList");
            var driverParam = getParamByType("DriverList");

            if (locationParam != null && driverParam != null) {
                rules.push({ fieldName: "filterType", required: true });

                if (filterType === "location") {
                    rules.push({ fieldName: locationParam.Key, required: true });
                }

                if (filterType === "driver") {
                    rules.push({ fieldName: driverParam.Key, required: true });
                }

            } else if (locationParam != null) {
                rules.push({ fieldName: locationParam.Key, required: true })
            }
        }

        let paramsWithBounds = null;

        if (props.parameters && props.parameters.length > 0) {
            paramsWithBounds = props.parameters.filter(p => p.parameterType.name.toLowerCase() === numWithBounds);
        }

        if (paramsWithBounds && paramsWithBounds.length > 0) {
            paramsWithBounds.forEach(p => {
                rules.push({ fieldName: p.name, minValue: p.intMin, maxValue: p.intMax })
            });
        }

        validate(rules, requestValidation);
    }

    useEffect(validateFields, [props.values.parameters, filterType, props.values.reportType, validationRules]);

    useEffect(function () {
        props.onValidationUpdate(isValid);
    }, [isValid]);

    return (
        <Fragment>

            {props.parameters && props.parameters.map(function (p) {
                return printFormInput(p)
            })}

            <FormRadio
                disabled={props.disabled}
                onChange={onFormatChange}
                headingText="Report format:"
                selectedValue={props.values.reportType}
                loading={props.loading}
                options={props.fileTypes}
                id="generate-report-format"
                dangerHelperText={errors.reportType}
            ></FormRadio>

        </Fragment>

    );
}

ReportParameters.defaultProps = {
    reportId: 0,
    disabled: false,
    loading: false,
    locations: [],
    restrictDateRange: false,
    restrictNumberOfMonths: null,
    filterRequired: true,
    fixedStartDate: null,
    fixedEndDate: null,
    editing: false
};

ReportParameters.propTypes = {
    reportId: PropTypes.number,
    parameters: PropTypes.array.isRequired,
    fileTypes: PropTypes.array.isRequired,
    values: PropTypes.object.isRequired,
    onParamUpdate: PropTypes.func.isRequired,
    onFormatUpdate: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    locations: PropTypes.array,
    restrictDateRange: PropTypes.bool,
    restrictNumberOfMonths: PropTypes.number,
    onValidationUpdate: PropTypes.func.isRequired,
    filterRequired: PropTypes.bool,
    fixedStartDate: PropTypes.string,
    fixedEndDate: PropTypes.string,
    editing: PropTypes.bool
};