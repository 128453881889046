import React from 'react';
import { NavLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types';
import { ShowHide } from 'components/layout';
import classNames from 'classnames';

export function VerticalLink(props) {
    const location = useLocation();

    function checkIfRoutesMatch() {
        if (props.exactRouteMatch) {
            return props.location === location.pathname;
        } else {
            let path = props.location.split('?')
            return location.pathname.startsWith(path[0]);
        }
    }

    return (
        <li className={classNames("bp3-tab", {"tab-disabled": props.disabled})} role="tab" aria-selected={checkIfRoutesMatch()}>
            <ShowHide
                evaluator={props.externalLink}
                show={(
                    <a href={props.location}>{props.title}</a>
                )}
                hide={(
                    <NavLink to={props.location} exact>{props.title}</NavLink>
                )}
            />
        </li>
    );
}

VerticalLink.propTypes = {
    location: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    exactRouteMatch: PropTypes.bool,
    externalLink: PropTypes.bool,
    disabled: PropTypes.bool
};

VerticalLink.defaultProps = {
    exactRouteMatch: true,
    externalLink: false,
    disabled: false
};
