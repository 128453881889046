import React, { useState, useEffect } from 'react';
import { WizardSteps } from 'components/wizard';
import { Button, ButtonSave } from 'components/buttons';
import { ShowHide } from 'components/layout';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

export function Wizard(props) {
	const [currentState, setCurrentState] = useState({
		step: 0,
		direction: "forward",
		previousStep: 0,
		stepTitle: ''
	});
	const [totalSteps, setTotalSteps] = useState(0);

	const isDataDirty = useSelector(state => state.dirtyData.dirty);

	useEffect(() => {
		setTotalSteps(props.children.length);

		setCurrentState({
			...currentState,
			stepTitle: getStepTitle(currentState.step)
		});
	}, [props.children]);

	useEffect(onStepChange, [currentState.step]);

	function onStepChange() {
		if (totalSteps === 0) {
			return;
		}

		props.onStepChange(currentState.step, currentState.direction, currentState.previousStep);
	}

	function onNextStep() {
		moveToStep(currentState.step + 1);
	}

	function onPreviousStep() {
		moveToStep(currentState.step - 1);
	}

	function onStepClick(step) {
		if (props.allowDirectStepNavigation) {
			moveToStep(step);
		}
	}

	function moveToStep(step) {
		const updateState = () => {
			setCurrentState((state) => {
				return {
					...state,
					step: step,
					direction: step >= state.step ? "forward" : "backward",
					previousStep: step - 1,
					stepTitle: getStepTitle(step)
				};
			});
		};

		if (isDataDirty && props.saveBetweenSteps && props.saveAction) {
			props.saveAction().then(updateState);
		} else {
			updateState();
		}
	}

	function getStepTitle(stepIndex) {
		let stepTitle = '';

		if (props.additionalStepInformation && stepIndex < props.additionalStepInformation.length) {
			stepTitle = props.additionalStepInformation[stepIndex].stepTitle;
		}

		return stepTitle;
	}

    return (
		<div id={props.id} className="wizard">

			<WizardSteps
				currentStep={currentState.step}
				totalSteps={totalSteps}
				additionalStepInformation={props.additionalStepInformation}
				showStepTitles={props.showStepTitles}
				onStepClick={onStepClick}
				allowDirectStepNavigation={props.allowDirectStepNavigation}
				loading={props.loading}
				wizardComplete={props.isComplete}
				minimal
			/>
			{props.children.map((child, i) => {
				if (i === currentState.step) {
					return child;
				}
				return null;
			})}
			<div>
				{props.footer()}
			</div>
			<div className={classNames('inline-items', { "inline-items-space": !props.alignButtonsRight, "inline-items-end": props.alignButtonsRight })}>
				<div className="button-row">
					{props.additionalButtonsStart}
					<ShowHide
						evaluator={props.allowBack && currentState.step > 0}
						show={(
							<Button icon="double-chevron-left" intent="primary" loading={props.loading} text="Previous" onClick={onPreviousStep} disabled={props.disabled} id="prev-btn"/>
						)}
					/>
					<ShowHide
						evaluator={currentState.step < (totalSteps - 1)}
						show={(
							<Button icon="double-chevron-right" intent="primary" loading={props.loading} text="Next" onClick={onNextStep} disabled={props.disabled || !props.canProceed} id="next-btn" />
						)}
					/>
					<ShowHide
						evaluator={(props.showFinishOnAllSteps || currentState.step >= (totalSteps - 1)) && props.canFinish}
						show={(
							<ButtonSave intent="primary" loading={props.loading} text={props.finishButtonText} onClick={props.onFinish} disabled={props.disabled} simpleDisabled={!props.canProceed} id="finish-btn" />
						)}
					/>
				</div>
				<div className="button-row">
					<ShowHide
						evaluator={props.saveBetweenSteps}
						show={(
							<ButtonSave intent="primary" loading={props.loading} text="Save" disabled={props.disabled} onClick={props.saveAction} id="save-btn" />
						)}
					/>
					{props.additionalButtons}
				</div>
			</div>
        </div>
    );
}

Wizard.defaultProps = {
	id: null,
	allowBack: true,
	canProceed: true,
	additionalStepInformation: [],
	showStepTitles: false,
	allowDirectStepNavigation: false,
	saveBetweenSteps: false,
	disabled: false,
	loading: false,
	alignButtonsRight: false,
	canFinish: true,
	isComplete: false,
	saveAction: () => null,
	footer: () => null,
	showFinishOnAllSteps: false,
	finishButtonText: 'Finish'
};

Wizard.propTypes = {
	id: PropTypes.string,
	allowBack: PropTypes.bool,
	canProceed: PropTypes.bool,
	onStepChange: PropTypes.func.isRequired,
	onFinish: PropTypes.func.isRequired,
	additionalStepInformation: PropTypes.array,
	showStepTitles: PropTypes.bool,
	allowDirectStepNavigation: PropTypes.bool,
	saveBetweenSteps: PropTypes.bool,
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	additionalButtonsStart: PropTypes.node,
	additionalButtons: PropTypes.node,
	alignButtonsRight: PropTypes.bool,
	canFinish: PropTypes.bool,
	isComplete: PropTypes.bool,
	saveAction: PropTypes.func,
	footer: PropTypes.func,
	showFinishOnAllSteps : PropTypes.bool,
	finishButtonText: PropTypes.string
};
