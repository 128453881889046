import React, { useState, useEffect } from 'react';
import { PageRestricted } from 'pages/errors/page-restricted';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { AssetService, BlobStorageService } from "services";
import { Tabs } from "components/navigation";
import { AuditingTable } from "components/auditing";
import PropTypes from 'prop-types';
import { HelperFunctions } from 'helpers';
import { NotificationToaster } from 'components/notifications';
import { Intent } from '@blueprintjs/core';
import { Tooltip } from 'components/tooltip';
import { Button } from "components/buttons"
import { ShowHide } from "../../../components/layout/ShowHide";

export function AssetProfileHistory(props) {

    const AssetProfileKey = 0;
    const AssetActivitiesKey = 1;
    const VehicleOffRoadKey = 2;
    const UploadsKey = 3;

    const tabSettingsDefault = [
        { title: 'Asset Profile', key: AssetProfileKey, selected: true },
        { title: 'Asset Activities', key: AssetActivitiesKey, selected: false },
        { title: 'Vehicle off Road', key: VehicleOffRoadKey, selected: false }
    ];

    const assetProfileHeaders = ["Date", "User", "Setting", "Old Value", "New Value"];
    const assetProfileNoDataMessage = "No changes have been made to your asset profile for the selected date period";
    const dateTimeFormat = "DD MMM YYYY HH:mm";

    const [loading, setLoading] = useState(true);
    const [initalising, setInitalising] = useState(true);

    const requiredActionUpload = ["Fleet:View"];
    const requiredAction = ["Fleet:View", "FleetPCN:View", "FleetVIS:View"];
    const canSeeUpload = UserHasAccess(requiredActionUpload);
    const [selectedTab, setSelectedTab] = useState(0);
    const [tabSettings, setTabSettings] = useState(tabSettingsDefault);

    const [changeLogTableData, setChangeLogTableData] = useState(null);
    const [auditTrailTableData, setAuditTrailTableData] = useState(null);
    const [vorTableData, setVorTableData] = useState(null);
    const [uploadsTableData, setUploadsTableData] = useState(null);
    const [changeLogTotalCount, setChangeLogTotalCount] = useState(0);
    const [auditTrailTotalCount, setAuditTrailTotalCount] = useState(0);
    const [vorTotalCount, setVorTotalCount] = useState(0);
    const [uploadsTotalCount, setUploadsTotalCount] = useState(0);
    const [uploadingDownloading, setUploadingDownloading] = useState(false);
    const [uploadIds, setUploadIds] = useState([]);

    useEffect(() => {
        if (canSeeUpload && !tabSettings.some(t => t.key === UploadsKey)) {
            setTabSettings((prev) => {
                return [...prev, { title: 'Uploads', key: UploadsKey, selected: false } ]
            });
        }
        setInitalising(false);
    }, []);

    function onTableChange(pageSize, pageNumber, startDate, endDate) {
        switch (selectedTab) {
            case AssetProfileKey:
                getChangeLog(pageSize, pageNumber, startDate, endDate)
                break;
            case AssetActivitiesKey:
                getAuditTrailForAsset(pageSize, pageNumber, startDate, endDate);
                break;
            case VehicleOffRoadKey:
                getVorHistory(pageSize, pageNumber, startDate, endDate);
                break;
            case UploadsKey:
                getUploads(pageSize, pageNumber, startDate, endDate);
                break;
            default:
                break;
        }
    }

    function onTabClick(tabIndex) {
        setTabSettings((prev) => {
            return prev.map(function (c, index) {
                return {
                    ...c,
                    selected: index === tabIndex
                }
            })}
        );

        setSelectedTab(tabIndex);
    }

    function mapUploads(uploads) {
        let uploadedIds = [];

        let tableRows = uploads.map((u, idx) => {
            uploadedIds.push(u.vuQueueId);
            return (
                [HelperFunctions.setDateToLocal(u.uploadDate, dateTimeFormat),
                u.uploadEmailAddress,
                u.uploadSource,
                HelperFunctions.setDateToLocal(u.downloadDate, dateTimeFormat),
                HelperFunctions.setDateToLocal(u.lastActivityDate, dateTimeFormat),
                <Tooltip content='Click to download file.' key={`uploads-history-${u.vuQueueId}-${idx}`}>
                    <Button icon='import' minimal={true} large={false} onClick={() => OnGetFileClick(u.vuQueueId)} disabled={uploadingDownloading || loading}></Button>
                </Tooltip>]
            );
        });
        setUploadIds(uploadedIds);
        return tableRows;
    }

    function getChangeLog(pageSize, pageNumber, startDate, endDate) {
        setLoading(true);
        AssetService.getChangeLog(props.assetId, pageSize, pageNumber, startDate, endDate).then(changes => {
            setChangeLogTableData(changes.data);
            setChangeLogTotalCount(changes.totalCount);
        })
        .catch(() => {
            NotificationToaster.show(Intent.DANGER, "Failed to load asset profile history", false);
        }).finally(() => {
            setLoading(false);
        });
    }

    function getAuditTrailForAsset(pageSize, pageNumber, startDate, endDate) {
        setLoading(true);
        AssetService.getAuditTrailForAsset(props.assetId, pageSize, pageNumber, startDate, endDate).then(auditTrail => {
            setAuditTrailTableData(auditTrail.data.map(t => {
                return [HelperFunctions.setDateToLocal(t.dateAffected, dateTimeFormat),
                    t.changeType,
                    t.modifiedBy,
                    HelperFunctions.setDateToLocal(t.modifiedDate, dateTimeFormat)]
            }));
            setAuditTrailTotalCount(auditTrail.totalCount);
            setLoading(false);
        })
        .catch(() => {
            NotificationToaster.show(Intent.DANGER, "Failed to load asset activity history", false);
        }).finally(() => {
            setLoading(false);
        });
    }

    function getVorHistory(pageSize, pageNumber, startDate, endDate) {
        setLoading(true);
        AssetService.getVorChangeLog(props.assetId, pageSize, pageNumber, startDate, endDate).then(vorHistory => {
            setVorTableData(vorHistory.data.map(v => {
                return [HelperFunctions.setDateToLocal(v.startDate, dateTimeFormat),
                        HelperFunctions.setDateToLocal(v.endDate, dateTimeFormat),
                        v.vorReason]
            }));
            setVorTotalCount(vorHistory.totalCount);
            setLoading(false);
        })
        .catch(() => {
            NotificationToaster.show(Intent.DANGER, "Failed to load vehicle off road history", false);
        }).finally(() => {
            setLoading(false);
        });
    }

    function getUploads(pageSize, pageNumber, startDate, endDate) {
        setLoading(true);
        AssetService.getTachoDataUploads(props.assetId, pageSize, pageNumber, startDate, endDate).then(uploads => {
            setUploadsTableData(mapUploads(uploads.data));
            setUploadsTotalCount(uploads.totalCount);
        })
        .catch(() => {
            NotificationToaster.show(Intent.DANGER, "Failed to load uploads", false);
        }).finally(() => {
            setLoading(false);
        });
    }

    function OnGetFileClick(uploadId) {
        setLoading(true);
        AssetService.getDownloadLinkForVehicleUnitFile(uploadId).then((response) => {
            NotificationToaster.show(Intent.SUCCESS, "Your file will be downloaded shortly");
            setUploadingDownloading(true);
            BlobStorageService.downloadFile(response.storageName, response.containerName, response.fileName, response.token, response.downloadName).then(function () {
                setUploadingDownloading(false);
            })
            .catch(() => {
                NotificationToaster.show(Intent.DANGER, "Failed to get File", false);
            }).finally(() => {
                setUploadingDownloading(false);
            });
        })
        .catch(() => {
            NotificationToaster.show(Intent.DANGER, "Failed to get File link", false);
        }).finally(() => {
            setLoading(false);
        });
    }

    function OnGetFilesClick() {
        uploadIds.forEach((uploadId) => { OnGetFileClick(uploadId) });
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">
                    <h1>History</h1>

                    <div className="spacer-bottom">
                        <Tabs tabs={tabSettings} onClick={onTabClick} loading={initalising} />
                    </div>

                    <ShowHide
                        evaluator={selectedTab === UploadsKey}
                        show={
                            <Button icon='import' minimal={false} large={true} intent="primary" text="Download all files"
                                onClick={OnGetFilesClick} disabled={uploadingDownloading || loading || uploadIds.length < 1}
                                className="spacer-bottom"></Button>
                        }
                    />

                    <ShowHide
                        evaluator={selectedTab === AssetProfileKey}
                        show={
                            <AuditingTable
                                data={changeLogTableData}
                                totalCount={changeLogTotalCount}
                                onChange={onTableChange}
                                noDataMessage={assetProfileNoDataMessage}
                                loading={loading}
                                tableHeaders={assetProfileHeaders}
                                dataIsFormatted={false}
                                hideHeader={true}
                            ></AuditingTable>
                        }
                    />

                    <ShowHide
                        evaluator={selectedTab === AssetActivitiesKey}
                        show={
                            <AuditingTable
                                data={auditTrailTableData}
                                totalCount={auditTrailTotalCount}
                                onChange={onTableChange}
                                noDataMessage={"No asset activity history for the selected date period"}
                                loading={loading}
                                tableHeaders={["Date Affected", "Change Type", "Edited By", "Edited On"]}
                                dataIsFormatted={true}
                                hideHeader={true}
                            ></AuditingTable>
                        }
                    />

                    <ShowHide
                        evaluator={selectedTab === VehicleOffRoadKey}
                        show={
                            <AuditingTable
                                data={vorTableData}
                                totalCount={vorTotalCount}
                                onChange={onTableChange}
                                noDataMessage={"No vehicle off road history for the selected date period"}
                                loading={loading}
                                tableHeaders={["Start Date", "End Date", "Reason"]}
                                dataIsFormatted={true}
                                hideHeader={true}
                            ></AuditingTable>
                        }
                    />

                    <ShowHide
                        evaluator={selectedTab === UploadsKey}
                        show={
                            <AuditingTable
                                data={uploadsTableData}
                                totalCount={uploadsTotalCount}
                                onChange={onTableChange}
                                noDataMessage={"No uploads for the selected date period"}
                                loading={loading}
                                tableHeaders={["Time of upload", "Uploaded by", "Uploaded Source", "Download Date", "Last activity", "Download file"]}
                                dataIsFormatted={true}
                                hideHeader={true}
                            ></AuditingTable>
                        }
                    />                    
                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );

}

AssetProfileHistory.defaultProps = {
};

AssetProfileHistory.propTypes = {
    assetId: PropTypes.string.isRequired
};