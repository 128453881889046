import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { UserHasAccess } from "components/user-access";
import { OperatingLicenceModal } from "pages/operating-licences/modals/shared";
import { OperatingLicenceService } from "services";
import { NotificationToaster } from "components/notifications";
import { Intent } from "@blueprintjs/core";

export function UpdateOperatingLicenceModal(
    { 
        showModal,
        operatingLicenceId,
        onSaveModal, 
        onCloseModal,
        shouldForceClose
    }
) {
    const requiredActions = ["OperatingLicence:Manage"];
    const canAccessPage = UserHasAccess(requiredActions);

    const operatingLicenceDetailsModel = {
        licenceArea: null,
        licenceType: null,
        licenceNumber: '',
        licenceRenewal: null,
        earnedRecognitionOperator: null,
        ocrs: {
            roadScore: null,
            trafficScore: null,
            combinedScore: null,
            earnedRecognitionNumber: '',
        },
        vehicleInspectionFrequency: null,
        trailerInspectionFrequency: null
    };

    const [fetchingOperatingLicence, setFetchingOperatingLicence] = useState(false);
    const [operatingLicenceDetails, setOperatingLicenceDetails] = useState(operatingLicenceDetailsModel);

    useEffect(onOperatingLicenceIdChange, [operatingLicenceId, showModal]);

    function onOperatingLicenceIdChange() {
        if (!canAccessPage || !showModal || fetchingOperatingLicence || operatingLicenceId == null) {
            return;
        }

        setFetchingOperatingLicence(true);

        OperatingLicenceService.getLicence(operatingLicenceId)
            .then((operatingLicenceResponse) => {
                operatingLicenceResponse.licenceArea = operatingLicenceResponse.licenceArea.id;
                operatingLicenceResponse.licenceType = operatingLicenceResponse.licenceType.id;

                setOperatingLicenceDetails(operatingLicenceResponse);
            })
            .catch(() => { 
                NotificationToaster.show(Intent.DANGER, "Failed to fetch operating licence");
                onCloseModal(shouldForceClose);
            })
            .finally(() => {
                setFetchingOperatingLicence(false);
            });
    }


    function onSave(operatingLicence) {
        return OperatingLicenceService.updateOperatingLicence(operatingLicenceId, operatingLicence)
            .then(
                ({ data }) => {
                    NotificationToaster.show(Intent.SUCCESS, "Operating Licence updated successfully.");
                    onSaveModal(data);
                }
            )
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    return (
        <>
            <OperatingLicenceModal
                showModal={showModal}
                modalTitle="Edit Operating Licence"
                showFinishOnAllSteps={true}
                requiredActions={requiredActions}
                onSave={onSave}
                onCloseModal={onCloseModal}
                licenceDetails={operatingLicenceDetails}
                loading={fetchingOperatingLicence}
            >
            </OperatingLicenceModal>
        </>
    );
}

UpdateOperatingLicenceModal.defaultProps = {
    shouldForceClose: false
};

UpdateOperatingLicenceModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    operatingLicenceId: PropTypes.number,
    onSaveModal: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    shouldForceClose: PropTypes.bool
};